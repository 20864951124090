import React, { useMemo, useState } from 'react';

import { Form, useForm, FormValidationRules, SelectField, RichTextField } from '@kit/components/Form';
import { useCompanyEmailAccounts } from '@hooks/useCompanyEmailAccounts';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useTokens } from '@hooks/useTokens';
import { useEmails } from '@hooks/useEmails';
import { clearQuillValue, convertQuillFormatToText, isQuilValueEmpty } from '@utils/quill';
import { RecordType } from '@types';
import { splitByComma } from '@utils/strings';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { Body, CcAndBccButtons, Footer } from './styled';

interface Props {
  recordIds: number[];
  onClose: () => void;
}

const TO_OPTIONS = [
  { id: 'all', title: 'All contacts' },
  { id: 'primary', title: 'Primary contact' }
];

interface FormValues {
  account: { id: number; title: string };
  to: { id: string; title: string };
  cc: string;
  bcc: string;
  subject: string;
  body: string;
}

export const Email = ({ recordIds, onClose }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const [isCcFieldShown, setIsCcFieldShown] = useState(false);
  const [isBccFieldShown, setIsBccFieldShown] = useState(false);

  const { data: companyEmails } = useCompanyEmailAccounts(companyId as number);

  const { sendBulkEmail } = useEmails();

  const { getAllTokens, getTokensFieldTo } = useTokens(RecordType.PROJECT, true);

  const fromFieldOptions =
    companyEmails?.map(({ id, emailAddress }) => ({
      id,
      title: emailAddress
    })) || [];

  const postForm = async (values: FormValues) => {
    await sendBulkEmail.mutateAsync({
      targetProjectIds: recordIds,
      // to: clearQuillValue(convertQuillFormatToText(values.to)),
      cc: splitByComma(clearQuillValue(convertQuillFormatToText(values.cc))),
      bcc: splitByComma(clearQuillValue(convertQuillFormatToText(values.bcc))),
      subject: clearQuillValue(convertQuillFormatToText(values.subject ?? '')),
      body: convertQuillFormatToText(values.body),
      accountId: values.account.id,
      toPrimaryContactEmailOnly: values.to.id === 'primary'
    });

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      account: fromFieldOptions[0],
      to: TO_OPTIONS[0]
    }
  });

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      account: { isRequired: true },
      to: {
        isRequired: true
      },
      subject: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      },
      body: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      }
    }),
    []
  );

  const {
    formState: { isSubmitting },
    control
  } = form;

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <SelectField
          name="account"
          label="From"
          options={fromFieldOptions}
          control={control}
          getOptionLabel={(option) => option.title}
        />

        <SelectField
          name="to"
          label="To"
          options={TO_OPTIONS}
          isClearable={false}
          control={control}
          getOptionLabel={(option) => option.title}
        />

        {(!isCcFieldShown || !isBccFieldShown) && (
          <CcAndBccButtons>
            {!isCcFieldShown && (
              <Button
                isUpperCase={false}
                variant={ButtonVariant.Flat}
                size={ButtonSize.Small}
                onClick={() => setIsCcFieldShown(true)}
              >
                Add CC
              </Button>
            )}
            {!isBccFieldShown && (
              <Button
                isUpperCase={false}
                variant={ButtonVariant.Flat}
                size={ButtonSize.Small}
                onClick={() => setIsBccFieldShown(true)}
              >
                Add BCC
              </Button>
            )}
          </CcAndBccButtons>
        )}

        {isCcFieldShown && (
          <RichTextField
            name="cc"
            getTokens={getTokensFieldTo}
            isToolbarShown={false}
            isToolbarHiddenCompletely
            isOneLine
            control={control}
            label="CC"
            placeholder=""
          />
        )}

        {isBccFieldShown && (
          <RichTextField
            name="bcc"
            getTokens={getTokensFieldTo}
            isToolbarShown={false}
            isToolbarHiddenCompletely
            isOneLine
            control={control}
            label="BCC"
            placeholder=""
          />
        )}

        <RichTextField
          name="subject"
          getTokens={getAllTokens}
          isToolbarShown={false}
          isToolbarHiddenCompletely
          isOneLine
          control={control}
          label="Subject"
          placeholder=""
        />

        <RichTextField name="body" getTokens={getAllTokens} control={control} placeholder="Type here..." />
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Send
        </Button>
      </Footer>
    </Form>
  );
};
