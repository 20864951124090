import React, { useState } from 'react';
import { Play, Archive, Edit, XSquare, Trash2, Bookmark } from 'react-feather';

import { BulkModalTask } from '@components/Calendar/BulkModalTask';
import { BulkModal as BulkModalProject } from '@components/Project/ProjectView/BulkModal';
import { useBulkState } from '@hooks/useProjectBulk';
import { confirmationModal } from '@common';

import { hasAccess } from '@utils';
import { useAppSelector, useCompanyProperties, usePlayMode, useRoutes } from '@hooks';
import { RecordIdsGroup } from '@hooks/useRecords';
import { selectCurrentUserId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { PropertyType, RecordType } from '@types';
import { find } from 'lodash/fp';
import { Count, Item, Wrapper } from './styled';

export type BulkPanelProps<T = { fieldId: number; value: any }> = {
  onSubmit?: ((values: { fieldId: number; value: any }) => Promise<void>) | ((id: number) => Promise<void>);
  onArchive?: (isArchive: boolean) => Promise<void>;
  onRemove?: () => Promise<void>;
  onCancel: () => void;
  countBulk: number;
  isTask?: boolean;
  isAllSelectionArchived?: boolean;
  recordGroups?: RecordIdsGroup[];
  companyId?: number;
} & (
  | {
      isInbox: true;
      onRead: (read: boolean) => Promise<void>;
    }
  | {
      isInbox?: never;
      onRead?: never;
    }
);

export const BulkPanel: React.FC<BulkPanelProps> = (props) => {
  const {
    onSubmit,
    onArchive,
    onRemove,
    onRead,
    countBulk,
    onCancel,
    isTask,
    isAllSelectionArchived,
    isInbox,
    recordGroups,
    companyId: providedCompanyId
  } = props;

  const { companyId, recordType } = useRoutes();
  const userId = useAppSelector(selectCurrentUserId);
  const { data: access } = useUserRoleSettings(companyId, userId);
  const { allProperties } = useCompanyProperties();
  const { create: startPlayMode } = usePlayMode();

  const { countSelected, selected } = useBulkState();
  const [showBulkModal, setShowBulkModal] = useState(false);

  const showModal = () => {
    setShowBulkModal(true);
  };

  const handleBulkSubmit: BulkPanelProps['onSubmit'] = async (payload) => {
    if ('fieldId' in payload && onSubmit) {
      const property = find({ id: payload.fieldId }, allProperties);
      await onSubmit(
        property?.type === PropertyType.Person && property.isAdditional
          ? {
              ...payload,
              value: payload.value ? { id: payload.value } : null
            }
          : payload
      );
    } else {
      await onSubmit?.(payload);
    }

    setShowBulkModal(false);
  };

  const handleBulkArchive = async (isArchive: boolean) => {
    const answer = await confirmationModal.create()?.show();
    if (answer) {
      await onArchive!(isArchive);
    }
  };

  const handleBulkRemove = async () => {
    const answer = await confirmationModal.create()?.show();
    if (answer) {
      await onRemove!();
    }
  };

  const ModalComponent = isTask ? BulkModalTask : BulkModalProject;
  const selectedCount = countSelected();

  return (
    <>
      <Wrapper>
        <Item>
          <Count>{countBulk}</Count>
          {isTask && 'Tasks'}
          {isInbox && 'Selected'}
          {!isInbox && !isTask && 'Projects'}
        </Item>
        {!isTask && !isInbox && hasAccess(access!, 'project', 'edit') && (
          <Item onClick={showModal}>
            <Edit size={24} />
            Edit
          </Item>
        )}
        {isTask && (
          <>
            <Item onClick={showModal}>
              <Edit size={24} />
              Edit
            </Item>
          </>
        )}
        {!isTask && !isInbox && !isAllSelectionArchived && hasAccess(access!, 'project', 'delete') && (
          <Item onClick={() => handleBulkArchive(true)}>
            <Archive size={24} />
            Archive
          </Item>
        )}
        {!isTask && !isInbox && isAllSelectionArchived && hasAccess(access!, 'project', 'delete') && (
          <>
            <Item onClick={() => handleBulkArchive(false)}>
              <Archive size={24} />
              Unarchive
            </Item>
            <Item onClick={handleBulkRemove}>
              <Trash2 size={24} />
              Delete
            </Item>
          </>
        )}
        {isInbox && (
          <>
            <Item onClick={() => onRead?.(true)}>
              <Bookmark size={24} />
              Read
            </Item>
            <Item onClick={() => onRead?.(false)}>
              <Bookmark size={24} />
              Unread
            </Item>
          </>
        )}
        <Item onClick={onCancel}>
          <XSquare size={24} />
          Cancel
        </Item>
        {/* TODO
        <Item onClick={handleBulkFavorite}>
          <Star size={24} />
          Favorite
        </Item>
        */}
      </Wrapper>

      {showBulkModal && (
        <ModalComponent
          open={showBulkModal}
          onClose={() => setShowBulkModal(false)}
          onSubmit={handleBulkSubmit}
          countBulk={countBulk}
          companyId={providedCompanyId}
        />
      )}
    </>
  );
};
