import styled from 'styled-components';
import { scroll } from '@styles';

export const PageHeader = styled.div`
  padding-top: 16px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const Content = styled.div<{ headerHeight: number }>`
  min-height: available;
  padding-left: 24px;

  ${({ theme }) => theme.devices.sm`
    height: calc(100vh - 48px - 16px - 48px);
    overflow: auto;
    
    ${scroll};
  `};

  .MuiTableCell-root {
    border-color: ${({ theme }) => theme.colors.borderDark};
  }

  .MuiTableCell-body.MuiTableCell-root {
    color: ${({ theme }) => theme.colors.gray};
    font: ${({ theme }) => theme.typefaces.subtitleLight};
  }
`;

export const Sidebar = styled.div`
  height: calc(100vh - 48px - 16px);
  overflow: auto;
  ${scroll};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const SidebarCategoryTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font: ${({ theme }) => theme.typefaces.textLight};
  border-top: 1px solid #eee;
`;

export const SidebarCategoryItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: ${({ theme, isActive }) => isActive && theme.colors.green};
  color: ${({ theme, isActive }) => isActive && theme.colors.white};
  font: ${({ theme }) => theme.typefaces.text};
  text-transform: uppercase;
  cursor: pointer;
`;

export const SidebarCategoryItemSpan = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SidebarCategoryItemCount = styled.span<{ isActive?: boolean }>`
  margin-left: auto;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.gray2)};
  padding: 0 4px;
  border-radius: 4px;
  font: ${({ theme }) => theme.typefaces.counter};
  border: 1px solid ${({ theme }) => theme.colors.gray3};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  flex-wrap: wrap;
  min-height: 48px;
  padding: 16px;

  ${({ theme }) => theme.devices.sm`
    padding: 0 0 0 24px;
  `}
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
`;

export const FiltersItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: 5px 7px;
  margin-right: 3px;
  background-color: ${({ theme, active }) => (active ? theme.colors.green : 'none')};
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.black)};
  border-radius: 4px;
  cursor: pointer;
  font: 12px/1.25 ${({ theme }) => theme.fonts.sans};

  &:last-child {
    margin-right: 0;
  }

  svg {
    display: block;
    ${({ theme }) => theme.devices.xs`
      display: none;
    `}
    width: 16px;
    height: 16px;
  }

  span {
    display: none;
    ${({ theme }) => theme.devices.xs`
      display: block;
    `}
  }
`;
