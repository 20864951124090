import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import styled from 'styled-components';
import { connect, ConnectedProps } from 'react-redux';
import querystring from 'querystring';
import { useQueryClient } from 'react-query';
import { InputField, PrimaryButton, RememberMe, Separator } from '../UI';
import Props from './types';
import Loader from '../../common/Loader';
import { redirectLink, fonts, colors } from '../../../styles';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 360px;

  input,
  button[type='submit'] {
    height: 40px;
  }
`;

const FormTitle = styled.h1`
  font-size: 30px;
  font-weight: normal;
  color: #252631;
  margin-bottom: 26px;
`;

const Register = styled.div`
  margin-top: 28px;
  font: normal 14px/1.25 ${fonts.sans};
  text-align: center;
  color: #778ca2;
`;

const StyledLink = styled(Link)`
  ${redirectLink}
  margin-left: 10px;
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.red};
  text-align: right;
  margin-bottom: 8px;
`;

const Signin: React.FC<IProps> = (props: IProps) => {
  const queryClient = useQueryClient();

  const { submit, googleSubmit, loading, isInvitePage, checkValidity, errorMessage } = props;

  const getInviteInformation = () => {
    if (typeof window !== 'undefined') {
      const query = querystring.parse(window.location?.search?.substring(1));

      return {
        inviteEmail: !query?.email || query.email === 'undefined' ? '' : (query.email as string),
        inviteToken: !query?.token || query.token === 'undefined' ? '' : (query.token as string),
        inviteCompanyId: !query?.companyId || query.companyId === 'undefined' ? 0 : +query.companyId
      };
    }

    return {
      inviteEmail: '',
      inviteToken: '',
      inviteCompanyId: 0
    };
  };

  const { inviteEmail, inviteToken, inviteCompanyId } = getInviteInformation();

  const [email, setEmail] = useState(isInvitePage ? inviteEmail : '');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isInvitePage) {
      checkValidity(inviteToken, inviteEmail, inviteCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvitePage]);

  useEffect(() => {
    queryClient.resetQueries();
  }, [queryClient]);

  const handleUpdate = (event) => {
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submit({ email: email.trim().toLowerCase(), password, inviteToken, companyId: inviteCompanyId });
  };

  const responseGoogle = (response) => {
    if (response && response.credential) {
      googleSubmit(response.credential, inviteToken);
      // const { tokenObj } = response;
      // GoogleCalendApi.setTokens({
      //   access_token: tokenObj.access_token
      // });
    }
  };

  const title = isInvitePage ? 'Create your account' : 'Sign in';
  const jumpToSignIn = isInvitePage && (
    <Register>
      Already have an account?
      <StyledLink to="/">Sign in</StyledLink>
    </Register>
  );

  const buttonText = isInvitePage ? 'Register' : 'Sign in';

  const renderItem = loading ? (
    <Loader size={60} bgcolor="#f8fafb" />
  ) : (
    <>
      <Form
        method="post"
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <FormTitle>{title}</FormTitle>
        <InputField
          label="email"
          text="email"
          fn={handleUpdate}
          error={false}
          errorMessage=""
          value={email}
          disabled={isInvitePage}
        />
        <InputField label="password" text="password" fn={handleUpdate} error={false} errorMessage="" value={password} />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <RememberMe isInvitePage={isInvitePage} />
        <PrimaryButton value={buttonText} />
        <Separator label="or" />
        <GoogleOAuthProvider clientId="682093883171-qgog77j76294ajs09cd0nusichha84m5.apps.googleusercontent.com">
          <GoogleLogin logo_alignment="center" width={360} onSuccess={responseGoogle} onError={responseGoogle} />
        </GoogleOAuthProvider>
      </Form>

      {jumpToSignIn}
      {/* <div className={scss.registerAccount}>
        Don’t have an account? <Link to="/register">Register</Link>
      </div> */}
    </>
  );

  return <>{renderItem}</>;
};

const mapStateToProps = (state) => {
  const { errorMessage } = state.authentication;

  return {
    errorMessage
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type IProps = PropsFromRedux & Props;

export default connector(Signin);
