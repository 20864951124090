import React from 'react';
import { useAppSelector, useRoutes } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { IntegrationProvider } from '@generated/types/graphql';
import { Link } from 'gatsby';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { useIntegration } from '@hooks/integrations';
import { LunarEdgeLogo, SolarEdgeLogo } from '@assets/svg';
import { Breadcrumbs, Container, Header, HeaderLeft, Logo, Subtitle, Title } from './styled';
import { Form } from './Form';
import { IntegrationSettings } from '../components/Settings';

export const SolarEdge = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  const { location } = useRoutes();
  const isMock = new URLSearchParams(location.search).has('mock');

  const { data: integration } = useIntegration({
    companyId,
    provider: isMock ? IntegrationProvider.LunarEdge : IntegrationProvider.SolarEdge
  });

  return (
    <Container>
      <Breadcrumbs>
        <Link to="../">System Fleet</Link>
        <ChevronRightIcon size="16px" color="#828D9A" />
        <div>SolarEdge Integration</div>
      </Breadcrumbs>

      <Header>
        <HeaderLeft>
          <Title>SolarEdge Integration</Title>
          <Subtitle>
            Get all data for solar micro-inverters, battery energy storage, and EV charging stations for your customers
          </Subtitle>
        </HeaderLeft>
        <Logo>
          {isMock ? <img src={LunarEdgeLogo} alt="LunarEdge" /> : <img src={SolarEdgeLogo} alt="SolarEdge" />}
        </Logo>
      </Header>

      {integration ? <IntegrationSettings integration={integration} /> : <Form mock={!!isMock} />}
    </Container>
  );
};
