import { ReactQueryKey } from '@enums';
import { PrivilegedTaskFilter, ReminderFilter, Reminder } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { gql } from 'graphql-request';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DeepPartial } from 'redux';
import { RecordType } from '@types';
import {
  ClientFilterState,
  useClientFilterState,
  mapClientFiltersToServerFilters,
  mapClientSortToServerSort
} from '../useClientFilterState';
import { usePaginatedTasks } from '../useTasks';

const fetchReminders = async ({
  clientFilters,
  filters
}: {
  clientFilters: ClientFilterState;
  filters: DeepPartial<ReminderFilter>;
}) => {
  let resultFilter: DeepPartial<ReminderFilter> = {
    isCompleted: { equalTo: false }
  };

  if (
    clientFilters.clientStatuses.length ||
    clientFilters.projectStatuses.length ||
    clientFilters.requestStatuses.length
  ) {
    resultFilter.project = {
      or: [
        {
          type: { equalTo: RecordType.PROJECT },
          status: { in: clientFilters.projectStatuses }
        },
        {
          type: { equalTo: RecordType.DEAL },
          requestStatus: { in: clientFilters.requestStatuses }
        },
        {
          type: { equalTo: RecordType.ACCOUNT },
          accountStatus: { in: clientFilters.clientStatuses }
        }
      ]
    };
  }

  if (clientFilters.assignees.length) {
    if (clientFilters.assignees.includes('unassigned')) {
      if (clientFilters.assignees.length === 1) {
        resultFilter.assigneeExists = false;
      } else {
        resultFilter.or = [
          { assigneeId: { in: clientFilters.assignees.filter((id) => id !== 'unassigned') } },
          { assigneeExists: false }
        ];
      }
    } else {
      resultFilter.assigneeId = { in: clientFilters.assignees };
    }
  }

  const reminderTypeFilter = clientFilters.reminderTypes.filter((type) => type !== 'appointment');
  const hasAppointmentFilter = clientFilters.reminderTypes.includes('appointment');

  if (reminderTypeFilter.length === 0 && hasAppointmentFilter) {
    resultFilter.project = {
      type: { equalTo: RecordType.DEAL }
    };
  } else if (reminderTypeFilter.length > 0 && hasAppointmentFilter) {
    resultFilter.or = [
      {
        isAppointment: { equalTo: true }
      },
      {
        isAppointment: { equalTo: false },
        type: { in: reminderTypeFilter }
      }
    ];
  } else if (reminderTypeFilter.length > 0 && !hasAppointmentFilter) {
    resultFilter.type = { in: reminderTypeFilter };
    resultFilter.isAppointment = { equalTo: false };
  }

  const search = clientFilters.query.trim();

  if (search) {
    if (resultFilter.or) {
      resultFilter = {
        ...resultFilter,
        or: undefined,
        and: [
          { or: resultFilter.or },
          { or: [{ title: { includesInsensitive: search } }, { project: { title: { includesInsensitive: search } } }] }
        ]
      };
    } else {
      resultFilter.or = [
        {
          title: { includesInsensitive: search }
        },
        {
          project: {
            title: { includesInsensitive: search }
          }
        }
      ];
    }
  }

  resultFilter = {
    ...resultFilter,
    ...filters
  };

  const { reminders } = await postGraphql<{ reminders: Reminder[] }>(
    gql`
      query ($filter: ReminderFilter) {
        reminders(filter: $filter) {
          id
          title
          dueDate
          isCompleted
          companyId
          createdAt
          type
          description
          privilege
          isAppointment
          project {
            id
            type
            companyId
            title
            type
          }
          createdByUser {
            id
            firstName
            lastName
            avatarUrl
          }
          assignee {
            id
            firstName
            lastName
            avatarUrl
          }
        }
      }
    `,
    { filter: resultFilter }
  );

  return reminders;
};

const mergeFilters = (clientFilters: ClientFilterState, otherFilters: DeepPartial<PrivilegedTaskFilter>) => {
  const mappedClientFilters = mapClientFiltersToServerFilters(clientFilters);

  if (mappedClientFilters.or && otherFilters.or) {
    return {
      ...mappedClientFilters,
      ...otherFilters,
      or: undefined,
      and: [{ or: mappedClientFilters.or }, { or: otherFilters.or }]
    };
  }

  return { ...mappedClientFilters, ...otherFilters };
};

export const useListViewTasks = ({
  isEnabled,
  filters,
  remindersFilters
}: {
  filters?: DeepPartial<PrivilegedTaskFilter>;
  remindersFilters?: DeepPartial<PrivilegedTaskFilter>;
  isEnabled: boolean;
}) => {
  const [clientFilters] = useClientFilterState();
  const companyId = useAppSelector(selectWorkspaceId);

  const remindersFiltersWithCompanyId = useMemo<DeepPartial<ReminderFilter>>(() => {
    if (!remindersFilters) {
      return undefined;
    }

    return {
      ...remindersFilters,
      companyId: { equalTo: companyId }
    };
  }, [companyId, remindersFilters]);

  const {
    tasks,
    total: tasksTotal,
    fetchNextPage
  } = usePaginatedTasks({
    queryKey: ReactQueryKey.TasksList,
    filters: mergeFilters(clientFilters, filters),
    orderBy: mapClientSortToServerSort(clientFilters.sortBy),
    isEnabled: isEnabled && (clientFilters.templates.length > 0 || clientFilters.reminderTypes.length === 0)
  });

  const { data: reminders = [] } = useQuery<Reminder[]>(
    [ReactQueryKey.ListViewReminders, clientFilters, remindersFiltersWithCompanyId],
    () => fetchReminders({ clientFilters, filters: remindersFiltersWithCompanyId }),
    {
      enabled:
        Boolean(remindersFilters) &&
        (clientFilters.templates.length === 0 || clientFilters.reminderTypes.length > 0) &&
        clientFilters.labels.length === 0 &&
        clientFilters.priorities.length === 0 &&
        clientFilters.statuses.length === 0
    }
  );

  const flatten = useMemo(() => {
    const onlyReminders = clientFilters.templates.length === 0 && clientFilters.reminderTypes.length > 0;
    const onlyTasks = clientFilters.templates.length > 0 && clientFilters.reminderTypes.length === 0;

    if (onlyTasks) {
      return {
        tasks,
        total: tasksTotal,
        fetchNextPage
      };
    }

    if (onlyReminders) {
      return {
        tasks: reminders,
        total: reminders.length,
        fetchNextPage
      };
    }

    return {
      tasks: [...reminders, ...tasks],
      total: tasksTotal + reminders.length,
      fetchNextPage
    };
  }, [tasks, tasksTotal, fetchNextPage, reminders, clientFilters]);

  return flatten;
};
