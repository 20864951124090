import { AutomationField, PropertyType } from '@types';
import React, { useCallback, useMemo } from 'react';
import { Control } from 'react-hook-form';
import { getFullName } from '@utils/utils';
import { DropDownItem } from '@common/Selector/UserSelector/DropDownItem';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { useTeams } from '@hooks/useTeams';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useControllerWithValidation } from '@kit/components/Form/useControllerWithValidation';
import { FormField } from '@kit/components/Form/FormField';
import { Select } from '@kit/ui/Select';
import { isPlainObject, uniqBy } from 'lodash';

interface Props {
  field: AutomationField;
  isDisabled?: boolean;
  control: Control<any>;
  isMulti?: boolean;
}

export const UserField = ({ isMulti, field, isDisabled, control }: Props) => {
  const { data: companyUsers, isLoading: isUsersLoading } = useAllCompaniesUsers();
  const companyId = useAppSelector(selectWorkspaceId);
  const {
    allProperties = [],
    fetchQuery: { isLoading: isPropertiesLoading }
  } = useCompanyProperties();

  const { data: teams, isLoading: isTeamsLoading } = useTeams(companyId);

  const userProperties = useMemo(
    () =>
      allProperties.filter(
        (property) =>
          (property.isAdditional && property.type === PropertyType.Person && !property.virtual) ||
          [-31, -40, -41].includes(property.id)
      ),
    [allProperties]
  );

  const options = useMemo(() => {
    if (isUsersLoading || isPropertiesLoading || isTeamsLoading || companyUsers.length === 0) {
      return [];
    }

    return [
      ...companyUsers.map((user) => ({
        id: user.id,
        avatarUrl: user.avatarUrl,
        name: getFullName(user),
        type: 'user'
      })),
      ...teams
        .filter((team) => team.teamUsers.length > 0)
        .map((team) => ({
          id: team.id,
          name: team.name,
          type: 'team',
          teamUsers: team.teamUsers.map((teamUser) => ({
            id: teamUser.id,
            avatarUrl: teamUser.avatarUrl,
            name: getFullName(teamUser),
            type: 'user'
          }))
        })),
      ...userProperties.map((property) => ({
        id: property.id,
        name: property.name,
        type: 'property'
      }))
    ];
  }, [companyUsers, teams, userProperties, isUsersLoading, isPropertiesLoading, isTeamsLoading]);

  const groupBy = (option: { type: string }) => {
    if (option.type === 'user') {
      return 'Workspace members';
    }

    if (option.type === 'team') {
      return 'Teams';
    }

    return 'Project members';
  };

  const {
    field: { value, onChange, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(field.key, control, field.label);

  const valueForSelect = useMemo(() => {
    const getOptionByValue = (optionValue: any) => {
      if (isPlainObject(optionValue)) {
        return optionValue;
      }

      return options.find((option) => option.id === optionValue);
    };

    if (isMulti) {
      const valueOrEmptyArray = value ?? [];

      return (Array.isArray(valueOrEmptyArray) ? valueOrEmptyArray : [value]).map(getOptionByValue).filter(Boolean);
    }

    return getOptionByValue(value) ?? null;
  }, [isMulti, value, options]);

  const handleChange = useCallback(
    (_: any, value: unknown) => {
      if (isMulti) {
        if (value) {
          const flattenUsers = uniqBy(
            (value as any[])
              .map((user) => {
                if (user.type === 'team') {
                  return user.teamUsers;
                }

                return user;
              })
              .flat(),
            (user) => user.id
          );

          onChange(flattenUsers);
        } else {
          onChange([]);
        }
      } else {
        onChange(value);
      }
    },
    [onChange, isMulti]
  );

  return (
    <FormField name={field.key} label={field.label} error={error?.message}>
      <Select
        isMulti={isMulti}
        name={field.key}
        value={valueForSelect}
        disabled={isDisabled}
        options={options}
        groupBy={groupBy}
        getOptionLabel={(option) => option.name}
        noOptionsText="User not found"
        data-test-id={`field-${field.key}`}
        onChange={handleChange}
        {...controlProps}
        renderOption={(option: any) => <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={option.name} />}
      />
    </FormField>
  );
};
