import React, { useMemo } from 'react';
import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';
import { SolarEdgeIntegrationDTO } from '@types';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { useAppSelector, useAuth } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { IntegrationProvider } from '@generated/types/graphql';
import { useCreateIntegration } from '@hooks/integrations';
import { Body, Container, Footer } from './styled';

type Props = {
  mock: boolean;
};

export const SolarEdgeForm = ({ mock }: Props) => {
  const {
    user: { userId }
  } = useAuth();
  const companyId = useAppSelector(selectWorkspaceId);

  const { mutateAsync: create } = useCreateIntegration(userId, companyId);

  const {
    form: {
      control,
      formState: { isSubmitting, isValid }
    },
    handleSubmit
  } = useForm({
    defaultValues: {
      provider: mock ? IntegrationProvider.LunarEdge : IntegrationProvider.SolarEdge
    },
    onSubmit: async (values: SolarEdgeIntegrationDTO) => {
      await create(values);
    }
  });

  const rules = useMemo<FormValidationRules<SolarEdgeIntegrationDTO>>(
    () => ({
      provider: {
        isRequired: true
      },
      apiKey: {
        isRequired: true
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Container>
        <Body>
          <InputField control={control} name="apiKey" label="SolarEdge API key" type="password" />
        </Body>

        <Footer>
          <Button
            disabled={isSubmitting || !isValid}
            variant={ButtonVariant.Primary}
            size={ButtonSize.Large}
            type="submit"
          >
            Connect
          </Button>
        </Footer>
      </Container>
    </Form>
  );
};
