import { Badge } from '@kit/ui/Badge';
import { Tooltip } from '@material-ui/core';
import { NylasAccountStatus } from '@types';
import React from 'react';

const ERROR_STATUS = {
  name: 'Connection failed',
  color: '#D54855',
  tooltip: 'Authentication with your email provider is no longer valid. Please re-authenticate your email account.'
};

export const ERRORS_STATUSES = [NylasAccountStatus.stopped, NylasAccountStatus.invalid, NylasAccountStatus.sync_error];

const STATUS_CONFIG_MAP: Record<NylasAccountStatus, { name: string; color: string; tooltip?: string }> = {
  [NylasAccountStatus.stopped]: ERROR_STATUS,
  [NylasAccountStatus.invalid]: ERROR_STATUS,
  [NylasAccountStatus.sync_error]: ERROR_STATUS,
  [NylasAccountStatus.connected]: {
    name: 'Connected',
    color: '#009688'
  },
  [NylasAccountStatus.running]: {
    name: 'Syncing',
    color: '#4D7CFE'
  }
};

export const Status = ({ status }: { status: NylasAccountStatus }) => {
  const { name, color, tooltip } = STATUS_CONFIG_MAP[status];

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="top">
        <Badge color={color}>{name}</Badge>
      </Tooltip>
    );
  }

  return <Badge color={color}>{name}</Badge>;
};
