import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import { FieldJson, RecordType, StepFromApi, TriggerStep, WorkspaceStageFromAPI } from '@types';
import React, { useEffect, useMemo } from 'react';
import { useAutomationTriggersAndActions } from '@hooks/automations/useAutomationTriggersAndActions';
import { isPlainObject } from 'lodash';
import { AutomationStepType, InputType, StepFieldKeys, Trigger } from '@enums';
import { useProjectStagesRest } from '@hooks/useProjectStages';
import { useCreateAutomationStep } from '@hooks/automations/useCreateAutomationStep';
import { useUpdateAutomationStep } from '@hooks/automations/useUpdateAutomationStep';
import { UseFormReturn } from 'react-hook-form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useUpdateEffect } from '@react-hookz/web';
import { useCompanyProperties } from '@hooks';
import { useRecordType } from '@features/Platform/Automations/Form/useRecordType';
import { useWorkOrderTemplateList } from '@hooks/templates/workOrders/useWorkOrderTemplateList';
import { Field } from '../Field';
import { Panel } from '../Panel';
import { FieldsGrid } from './styled';
import { ANY_OPTION } from '../Field/constants';

export type TriggerFormValues = {
  trigger: TriggerStep;
} & {
  [fieldKey in StepFieldKeys]?: FieldJson['value'];
} & {
  [StepFieldKeys.COLUMN_ID]?: number;
  [StepFieldKeys.RECORD_TYPE]?: RecordType;
};

interface Props {
  automationId: number;
  step?: StepFromApi;
  context?: 'workOrderTemplate' | 'projectStage';
  onTriggerChange: (trigegr: TriggerStep) => void;
}

const getInitialValues = ({ triggers, step }: { triggers: TriggerStep[]; step?: StepFromApi }) => {
  if (!step) {
    return {
      trigger: triggers[0]
    };
  }

  const trigger = triggers.find((tr) => tr.key === step.key);

  if (!trigger) {
    return undefined;
  }

  const initialValues: TriggerFormValues = { trigger };

  step?.fields.forEach((field) => {
    initialValues[field.key] = field.value;

    const foundTriggerField = trigger.fields.find((triggerField) => triggerField.key === field.key);

    if (foundTriggerField && !foundTriggerField.required && field.value === null) {
      initialValues[field.key] = ANY_OPTION;
    }
  });

  return initialValues;
};

export const TriggerForm = ({ context, automationId, step, onTriggerChange }: Props) => {
  const { data: { triggers } = { triggers: [] as TriggerStep[] } } = useAutomationTriggersAndActions();
  const { data: workOrderTemplates = [] } = useWorkOrderTemplateList();

  const {
    fetchAll: { data: workspaceStages }
  } = useProjectStagesRest();
  const { mutateAsync: createAutomationStep } = useCreateAutomationStep();
  const { mutateAsync: updateAutomationStep } = useUpdateAutomationStep();

  const { scopeToEditableColumns } = useCompanyProperties();

  const filteredTriggers = useMemo(() => {
    if (!context) {
      return triggers;
    }

    return triggers.filter((trigger) => {
      if (context === 'workOrderTemplate') {
        return trigger.key.startsWith('TASK_');
      }

      if (context === 'projectStage') {
        return trigger.key.startsWith('PROJECT_');
      }

      return false;
    });
  }, [context, triggers]);

  const postForm = async (values: TriggerFormValues, { reset }: UseFormReturn<TriggerFormValues>) => {
    const payload = {
      automationId,
      stepId: step?.id ?? undefined,
      req: {
        type: AutomationStepType.TRIGGER,
        key: values?.trigger?.key,
        fields: values?.trigger?.fields.map((field) => {
          let value = values[field.key];

          if (value?.id === ANY_OPTION?.id) {
            value = null;
          }

          if (isPlainObject(value)) {
            value = value.id ?? value.key;
          }

          return {
            key: field.key,
            value
          };
        })
      }
    };

    await (step?.id ? updateAutomationStep(payload) : createAutomationStep(payload));

    reset(undefined, {
      keepDirty: false,
      keepValues: true,
      keepDirtyValues: true,
      keepIsValid: true
    });
  };

  const { handleSubmit, form } = useForm<TriggerFormValues>({
    onSubmit: postForm,
    defaultValues: getInitialValues({ triggers, step })
  });

  const {
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { isDirty }
  } = form;

  const trigger: TriggerStep = watch('trigger');
  const stageId = watch(StepFieldKeys.STAGE_ID);

  const selectedWorkOrderTemplateValue = watch(StepFieldKeys.TASK_ID);

  const selectedWorkOrderTemplate = useMemo(
    () =>
      workOrderTemplates.find((workOrderTemplate) =>
        isPlainObject(selectedWorkOrderTemplateValue)
          ? selectedWorkOrderTemplateValue
          : workOrderTemplate.id === selectedWorkOrderTemplateValue
      ) ?? null,
    [workOrderTemplates, selectedWorkOrderTemplateValue]
  );

  useEffect(() => {
    if (!trigger) {
      return;
    }

    if ([Trigger.PROJECT_SLA_VIOLATION, Trigger.DEAL_SLA_VIOLATION].includes(trigger.key)) {
      const slaType = getValues(StepFieldKeys.SLA_TYPE);
      const stage: WorkspaceStageFromAPI = isPlainObject(stageId)
        ? stageId
        : workspaceStages?.find((workspaceStage) => workspaceStage.id === stageId);

      clearErrors(StepFieldKeys.SLA_TYPE);

      if (!stage || (!stage.redSla && !stage.yellowSla)) {
        setValue(StepFieldKeys.SLA_TYPE, null);
        onTriggerChange(trigger);

        return;
      }

      if (stage.redSla && !stage.yellowSla) {
        setValue(StepFieldKeys.SLA_TYPE, 'red');
      } else if (!stage.redSla && stage.yellowSla) {
        setValue(StepFieldKeys.SLA_TYPE, 'yellow');
      } else if (!slaType) {
        setValue(StepFieldKeys.SLA_TYPE, 'red');
      }
    }

    onTriggerChange(trigger);
  }, [stageId, workspaceStages, trigger, getValues, clearErrors, setValue, onTriggerChange]);

  const [recordType, setRecordType] = useRecordType();

  const recordTypeField = watch(StepFieldKeys.RECORD_TYPE) as RecordType;

  useEffect(() => {
    if (!trigger) {
      setRecordType(null);

      return;
    }

    switch (trigger.key) {
      case Trigger.PROJECT_MOVEMENT:
      case Trigger.PROJECT_SLA_VIOLATION:
      case Trigger.PROJECT_PROPERTY_UPDATED:
      case Trigger.PROJECT_STATUS_MOVEMENT:
        setRecordType(RecordType.PROJECT);
        break;

      case Trigger.DEAL_MOVEMENT:
      case Trigger.DEAL_SLA_VIOLATION:
      case Trigger.APPOINTMENT_CREATED:
      case Trigger.APPOINTMENT_RESCHEDULED:
      case Trigger.DEAL_PROPERTY_UPDATED:
      case Trigger.DEAL_STATUS_MOVEMENT:
        setRecordType(RecordType.DEAL);
        break;

      case Trigger.TASK_COMPLETED:
      case Trigger.TASK_STATUS_MOVEMENT:
      case Trigger.CALL_UPSERTED:
        setRecordType(!recordTypeField || recordTypeField?.id === ANY_OPTION.id ? null : recordTypeField);
        break;

      default:
        throw new Error(trigger.key satisfies never);
    }
  }, [trigger, recordTypeField, setRecordType]);

  const rules = useMemo<FormValidationRules<TriggerFormValues>>(
    () => ({
      trigger: {
        isRequired: true
      },
      ...trigger?.fields.reduce((acc, field) => {
        if (field.required) {
          acc[field.key] = {
            isRequired: true
          };
        }

        return acc;
      }, {} as FormValidationRules<TriggerFormValues>)
    }),
    [trigger]
  );

  const triggerColumns = useMemo(
    () => (scopeToEditableColumns[recordType] || []).filter((column) => column.isAdditional),
    [scopeToEditableColumns, recordType]
  );

  const selectedPropertyId = watch(StepFieldKeys.COLUMN_ID);

  const selectedProperty = useMemo(
    () => triggerColumns.find((column) => column.id === selectedPropertyId),
    [triggerColumns, selectedPropertyId]
  );

  useUpdateEffect(() => {
    // it could work without this effect, just based on field unmount, but for some reason it is not clearing the value
    setValue(StepFieldKeys.COLUMN_VALUE, null);
  }, [selectedPropertyId]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Panel title="Trigger" description="Choose the event that will trigger this automation.">
        <FieldsGrid fieldsCount={(trigger?.fields.length ?? 0) + 1}>
          <SelectField
            name="trigger"
            label="Trigger"
            options={filteredTriggers}
            control={control}
            isClearable={false}
            getOptionLabel={(option: TriggerStep) => option.name}
            getOptionSelected={(option: TriggerStep, value: TriggerStep) => option.key === value.key}
          />

          {trigger?.fields.map((field) => (
            <Field
              key={field.key}
              selectedStage={stageId}
              control={control}
              field={field}
              typeTweaks={{
                [InputType.PROPERTY_SELECTOR]: { columns: triggerColumns },
                [InputType.PROPERTY_INPUT]: { column: selectedProperty },
                [InputType.OPTIONS_DYNAMIC]: {
                  selectedWorkOrderTemplate
                }
              }}
              recordType={recordType}
              area="trigger"
            />
          ))}
        </FieldsGrid>

        <Button disabled={!isDirty} type="submit" variant={ButtonVariant.Primary}>
          Save
        </Button>
      </Panel>
    </Form>
  );
};
