import React, { useState } from 'react';
import { useInsertManualGeo } from '@hooks/geolocation';
import { LngLat } from 'react-map-gl';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { DateTime } from 'luxon';
import { UserSelector } from '@common/Selector/UserSelector';
import { DispatcherTask, useDispatherTasks } from '@components/Scheduler/Dispatcher/useTasks';
import { Select } from '@kit/ui/Select';
import { Checkbox, FormControlLabel } from '@common/ui';
import { useAppSelector } from '@hooks';
import { selectCompanySettings } from '@state/selectors';
import { Buttons, Container, DebugFrame, DebugLabel, Title } from './styled';

type Props = {
  point: LngLat;
  handleClose: () => void;
};

export const ManualPointPopup = ({ point, handleClose }: Props) => {
  const settings = useAppSelector(selectCompanySettings);
  const isFakeRoutesEnabled = !!settings?.features?.geolocationFake;

  const { mutateAsync } = useInsertManualGeo();

  const [selectedUser, setSelectedUser] = useState();

  const [selectedTask, setSelectedTask] = useState<DispatcherTask | null>(null);

  const [buildRouteTo, setBuildRouteTo] = useState(false);
  const [runDummyFrom, setRunDummyFrom] = useState(false);
  const [runDummyInstant, setRunDummyInstant] = useState(true);

  const onSave = async () => {
    await mutateAsync({
      userId: selectedUser.id,
      taskId: selectedTask.id,
      deviceId: 'web',
      latitude: point.lat,
      longitude: point.lng,
      timestamp: DateTime.now().toISO(),
      buildRouteTo,
      runDummyFrom,
      runDummyInstant
    });

    handleClose();
  };

  const { allWorkOrders } = useDispatherTasks();

  return (
    <Container>
      <Title>Set location manually</Title>

      <UserSelector
        isMultiple={false}
        selectedUsers={[selectedUser].filter(Boolean)}
        setSelectedUsers={(users) => setSelectedUser(users[0])}
      />

      <Select
        options={allWorkOrders}
        value={selectedTask}
        onChange={(_, value) => setSelectedTask(value)}
        renderOption={(task) => `#${task.uid} · ${task.title}`}
        getOptionLabel={(task) => task.title}
        isMulti={false}
        placeholder="Search WO..."
      />

      {isFakeRoutesEnabled && (
        <DebugFrame>
          <DebugLabel>Debug</DebugLabel>

          <FormControlLabel
            control={<Checkbox checked={buildRouteTo} onChange={(event) => setBuildRouteTo(event.target.checked)} />}
            label={
              <>
                Also imitate route <b>to</b> new point from the latest known position
              </>
            }
          />

          <FormControlLabel
            control={<Checkbox checked={runDummyFrom} onChange={(event) => setRunDummyFrom(event.target.checked)} />}
            label={
              <>
                Also imitate route <b>from</b> new point to WO site
              </>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={runDummyInstant}
                onChange={(event) => setRunDummyInstant(event.target.checked)}
                disabled={!runDummyFrom}
              />
            }
            label={<>... instantly</>}
            style={{ paddingLeft: 32 }}
          />
        </DebugFrame>
      )}

      <Buttons>
        <Button variant={ButtonVariant.Secondary} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={onSave} disabled={!selectedUser || !selectedTask}>
          Save
        </Button>
      </Buttons>
    </Container>
  );
};
