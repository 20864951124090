import styled from 'styled-components';
import { Button } from '@common/ui';
import { fonts } from '@styles';

export const Container = styled.div`
  padding: 7px 0;
  height: 90px;
  background-color: #fff;
  border-bottom: 1px solid #dfdfe8;
`;

export const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
`;

export const ChipsContainer = styled.div`
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 5px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: 33px;
  overflow: hidden;
`;

export const Chip = styled.div`
  padding: 2px 8px;
  background-color: #e8ecef;
  border-radius: 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: 500;
  height: 18px;
`;

export const ChipItem = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  white-space: nowrap;
`;

export const ViewsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ClearAll = styled(Button)`
  white-space: nowrap;
  &&& {
    text-transform: capitalize;
    min-height: 18px;
  }
`;

export const Total = styled.div`
  white-space: nowrap;
  line-height: 24px;
`;

export const ChipsInPopover = styled.div`
  font-family: ${fonts.sans};
  font-size: 14px;
  font-weight: 400;
`;
