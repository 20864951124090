import { SearchBar } from '@common/SearchBar';
import { usePropertyGroups } from '@hooks/usePropertyGroup';
import { RecordType } from '@types';
import React, { useCallback, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { PlusSquare } from 'react-feather';
import { colors } from '@styles';
import { useModal } from '@common/PromiseModal';
import { PropertyForm } from '@features/Platform/Properties/List/PropertyForm';
import {
  FORM_FIELD_TYPE_CONFIGS,
  FormBuilderElement,
  FormBuilderFieldElement,
  FormFieldType,
  mapProjectPropertyToFormFieldType
} from './helpers';
import {
  ProjectPropertiesGroupTitle,
  ProjectPropertiesScrollable,
  ProjectPropertiesSectionTitle,
  SidebarItem
} from './styled';

interface Props {
  configsById: { [key: string]: FormBuilderElement };
}

export const ProjectProperties = ({ configsById }: Props) => {
  const [search, setSearch] = useState('');
  const { openModal } = useModal();
  const { data: groups } = usePropertyGroups({ scope: RecordType.PROJECT, fullAccess: true });

  const usedProjectPropertiesByPropertyId = useMemo(() => {
    return Object.values(configsById)
      .filter((config) => config.type === FormFieldType.Project)
      .reduce((acc, config) => {
        const element = config as FormBuilderFieldElement;
        acc[element.config.columnId] = element;

        return acc;
      }, {} as { [key: string]: FormBuilderElement });
  }, [configsById]);

  const filteredGroups = useMemo(() => {
    if (!groups) {
      return [];
    }

    let propertyIndex = 0;

    return groups
      .map((group) => ({
        ...group,
        properties: group.properties
          .filter(
            (property) =>
              !usedProjectPropertiesByPropertyId[property.id] &&
              property?.name?.toLowerCase()?.includes(search.toLowerCase())
          )
          .map((property) => ({
            ...property,
            // eslint-disable-next-line no-plusplus
            index: propertyIndex++
          }))
      }))
      .filter((group) => group.properties.length > 0);
  }, [groups, search, usedProjectPropertiesByPropertyId]);

  const handleAddNewProperty = useCallback(() => {
    openModal<void>(({ onClose }) => <PropertyForm groups={groups} scope={RecordType.PROJECT} onClose={onClose} />, {
      title: 'Create new property'
    });
  }, [openModal, groups]);

  return (
    <>
      <ProjectPropertiesSectionTitle>
        Project properties
        <PlusSquare onClick={handleAddNewProperty} size="20px" color={colors.green} />
      </ProjectPropertiesSectionTitle>

      <SearchBar value={search} onValueChange={setSearch} />

      <ProjectPropertiesScrollable>
        {filteredGroups.map((group) => (
          <div key={group.id}>
            <ProjectPropertiesGroupTitle>{group.name}</ProjectPropertiesGroupTitle>
            {group.properties.map((item) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={item.index}>
                {(provided, snapshot) => (
                  <>
                    <SidebarItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                      style={provided.draggableProps.style}
                    >
                      {FORM_FIELD_TYPE_CONFIGS[mapProjectPropertyToFormFieldType(item)].icon}
                      {item.name}
                    </SidebarItem>
                  </>
                )}
              </Draggable>
            ))}
          </div>
        ))}
      </ProjectPropertiesScrollable>
    </>
  );
};
