import React, { useCallback } from 'react';
import { Checkbox } from '@kit/ui/Checkbox';
import { useRoutes } from '@hooks/useRoutes';
import { System } from '@generated/types/graphql';
import { useSelectionWithGroups } from '@features/SystemPortfolio/List/Table/useSelection';
import { Tooltip } from '@material-ui/core';
import { getStatusDesc } from '@features/SystemPortfolio/constants';
import { SYSTEM_STATUS_COLORS } from '@styles';
import { AlertTriangle, EyeOff } from 'react-feather';
import { NotMatchtedLabel, StatusMark } from '@features/SystemPortfolio/components';
import { useModal } from '@common/PromiseModal';
import { MatchToClient } from '@features/SystemPortfolio/components/MatchToClient';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import {
  Container,
  FirstLine,
  ImageAndCheckboContainer,
  Right,
  SecondLine,
  StatusLine,
  SystemName,
  SystemNumber,
  Td,
  Title
} from './styled';

interface Props {
  system: System;
  groupLabel: string | null;
}

export const TitleCell = ({ system, groupLabel, ...rest }: Props) => {
  const { companyId } = useRoutes();

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  const { isItemSelected, toggleItem } = useSelectionWithGroups();

  const { color: statusColor } = SYSTEM_STATUS_COLORS[system.status];
  const { openModal } = useModal();

  const handleLink = useCallback(() => {
    openModal<void>(({ onClose }) => <MatchToClient system={system} onClose={onClose} />, {
      title: `Link #${system.number}: ${system.name} to Client`
    });
  }, [openModal, system]);

  return (
    <Td isSelected={isItemSelected(groupLabel, system)} {...rest}>
      <Container>
        <ImageAndCheckboContainer>
          <Checkbox isChecked={isItemSelected(groupLabel, system)} onChange={() => toggleItem(groupLabel, system)} />
        </ImageAndCheckboContainer>
        <Right>
          <FirstLine>
            <Title onClick={handleClick} href={`/${companyId}/systems/${system.id}`}>
              <Tooltip
                title={
                  <div>
                    <StatusLine>
                      <StatusMark color={statusColor} />
                      <div>{system.status.toUpperCase()}</div>
                    </StatusLine>
                    <div>{getStatusDesc(system)}</div>
                  </div>
                }
              >
                <StatusLine>
                  <StatusMark color={statusColor} />
                  <SystemNumber>#{system.number}:</SystemNumber>
                  <SystemName>{system.name}</SystemName>
                  {!system.monitored && (
                    <Tooltip title="System is not monitored" placement="right">
                      <EyeOff color="#9C9CAA" size="16px" />
                    </Tooltip>
                  )}
                </StatusLine>
              </Tooltip>
            </Title>
          </FirstLine>

          {!system.project && (
            <SecondLine>
              <NotMatchtedLabel>
                <AlertTriangle size={16} />
                Not linked to Client
              </NotMatchtedLabel>
              <Button onClick={handleLink} size={ButtonSize.Small} variant={ButtonVariant.Flat}>
                Link
              </Button>
            </SecondLine>
          )}
        </Right>
      </Container>
    </Td>
  );
};
