import { useQuery } from 'react-query';
import { useAppSelector } from '@hooks';
import { selectCurrentUserId } from '@state/selectors';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { UserGeolocationSetting } from '@generated/types/graphql';

export const useUserGeoSettings = () => {
  const userId = useAppSelector(selectCurrentUserId);

  return useQuery<UserGeolocationSetting[]>(
    [ReactQueryKey.GeolocationSettings, userId, 'useUserGeoSettings'],
    async () => {
      try {
        return (
          await postGraphql<{ userGeolocationSettings: UserGeolocationSetting[] }>(
            gql`
              query ($userId: Int!) {
                userGeolocationSettings(condition: { userId: $userId }) {
                  id
                  enabled
                }
              }
            `,
            {
              userId
            }
          )
        ).userGeolocationSettings;
      } catch (e) {
        throw apiErrorHandler('Error fetching user geo settings', e);
      }
    },
    {
      initialData: [],
      keepPreviousData: true
    }
  );
};
