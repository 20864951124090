import React, { useCallback } from 'react';
import { Switch } from '@kit/ui/Switch';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Bell } from 'react-feather';
import { useModal } from '@common/PromiseModal';
import { useUpdateCompanyNotification } from '@hooks/workspace/notifications/useUpdateCompanyNofification';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useUpdateEffect } from '@react-hookz/web';
import {
  PortalNoficationSettingsTreeItem,
  PortalNotificationEvent
} from '@hooks/workspace/notifications/usePortalNotifications';
import { getDeliveryMethodsString } from './helpers';
import {
  ErrorText,
  Event,
  EventContent,
  EventHeader,
  EventTitle,
  EventToggle,
  ListItem,
  ListItemTitle,
  SecondaryText,
  TextBlock
} from './styled';
import { EventForm } from './EventForm';

const EntityEvent = ({ event }: { event: PortalNotificationEvent }) => {
  const { openModal } = useModal();

  const [isEnabled, debouncedIsEnabled, setIsEnabled] = useDebouncedState(event.settings.enabled, 300);

  const { mutateAsync: update } = useUpdateCompanyNotification();

  const handleEdit = useCallback(() => {
    openModal<void>(({ onClose }) => <EventForm event={event} onClose={onClose} />, {
      title: `Edit "${event.eventSchema.title}"`
    });
  }, [event, openModal]);

  const { entityType, eventType } = event.settings;

  useUpdateEffect(() => {
    update({
      entityType,
      eventType,
      enabled: debouncedIsEnabled
    });
  }, [debouncedIsEnabled, update, entityType, eventType]);

  const handleToggle = useCallback(() => {
    setIsEnabled((prev) => !prev);
  }, [setIsEnabled]);

  const deliveryMethodString = getDeliveryMethodsString(event.settings);

  return (
    <Event>
      <EventToggle>
        {event.eventSchema.always ? (
          <Bell size="24px" color="#9C9CAA" />
        ) : (
          <Switch isActive={isEnabled} onChange={handleToggle} />
        )}
      </EventToggle>
      <EventContent>
        <EventHeader>
          <div>
            <EventTitle isDisabled={!isEnabled}>{event.eventSchema.title}</EventTitle>
            <SecondaryText>{event.eventSchema.description}</SecondaryText>
          </div>

          <Button disabled={!isEnabled} onClick={handleEdit} variant={ButtonVariant.Flat}>
            Edit
          </Button>
        </EventHeader>

        {isEnabled && (
          <TextBlock>
            <SecondaryText>Delivery methods:</SecondaryText>
            <div>
              {deliveryMethodString}
              {deliveryMethodString.length === 0 && <ErrorText>No delivery methods selected.</ErrorText>}
            </div>
          </TextBlock>
        )}
      </EventContent>
    </Event>
  );
};

interface Props {
  entity: PortalNoficationSettingsTreeItem;
}

export const Entity = ({ entity }: Props) => {
  return (
    <ListItem>
      <ListItemTitle>{entity.title}</ListItemTitle>

      {entity.events.map((event) => (
        <EntityEvent key={event.eventSchema.eventType} event={event} />
      ))}
    </ListItem>
  );
};
