import React from 'react';
import { navigate } from '@reach/router';
import { EnfakeLogo, EnphaseLogo, LunarEdgeLogo, SolarEdgeLogo } from '@assets/svg';
import { ButtonVariant } from '@kit/ui/Button';
import { useIntegrations } from '@hooks/integrations/useIntegrations';
import { useAppSelector } from '@hooks';
import { selectCompanySettings, selectWorkspaceId } from '@state/selectors';
import { IntegrationProvider } from '@generated/types/graphql';
import { Check } from 'react-feather';
import { BadgeSize } from '@kit/ui/Badge/types';
import { Badge } from '@kit/ui/Badge';
import { Box } from '@material-ui/core';
import { Grid, Header, ProviderBadge, ProviderButton, ProviderCard, ProviderLogo, ProviderText, Title } from './styled';

export const List = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const settings = useAppSelector(selectCompanySettings);

  const { data: integrations } = useIntegrations({ companyId });

  return (
    <>
      <Header>
        <Title>
          <Box display="flex" alignItems="center" gridGap={8}>
            System Fleet
            <Badge bgColor="#DBE5FF" color="#4D7CFE">
              Beta
            </Badge>
          </Box>
        </Title>
      </Header>

      <Grid>
        <ProviderCard onClick={() => navigate('fleet/enphase')}>
          <ProviderLogo>
            <img src={EnphaseLogo} alt="Enphase" />
          </ProviderLogo>

          <ProviderText>
            Get all data for solar micro-inverters, battery energy storage, and EV charging stations for your customers
          </ProviderText>

          {(integrations.find((integraion) => integraion.provider === IntegrationProvider.Enphase) && (
            <ProviderBadge size={BadgeSize.Large}>
              <Check /> Installed
            </ProviderBadge>
          )) || <ProviderButton variant={ButtonVariant.Primary}>Install</ProviderButton>}
        </ProviderCard>

        {settings?.features?.fleetMock && (
          <ProviderCard onClick={() => navigate('fleet/enphase?mock')}>
            <ProviderLogo>
              <img src={EnfakeLogo} alt="Enfake" />
            </ProviderLogo>

            <ProviderText>Enphase API Mock</ProviderText>

            {(integrations.find((integraion) => integraion.provider === IntegrationProvider.Enfake) && (
              <ProviderBadge size={BadgeSize.Large}>
                <Check /> Installed
              </ProviderBadge>
            )) || <ProviderButton variant={ButtonVariant.Primary}>Install</ProviderButton>}
          </ProviderCard>
        )}

        <ProviderCard onClick={() => navigate('fleet/solar-edge')}>
          <ProviderLogo>
            <img src={SolarEdgeLogo} alt="SolarEdge" />
          </ProviderLogo>

          <ProviderText>
            Get all data for solar micro-inverters, battery energy storage, and EV charging stations for your customers
          </ProviderText>

          {(integrations.find((integraion) => integraion.provider === IntegrationProvider.SolarEdge) && (
            <ProviderBadge size={BadgeSize.Large}>
              <Check /> Installed
            </ProviderBadge>
          )) || <ProviderButton variant={ButtonVariant.Primary}>Install</ProviderButton>}
        </ProviderCard>

        {settings?.features?.fleetMock && (
          <ProviderCard onClick={() => navigate('fleet/solar-edge?mock')}>
            <ProviderLogo>
              <img src={LunarEdgeLogo} alt="LunarEdge" />
            </ProviderLogo>

            <ProviderText>SolarEdge API Mock</ProviderText>

            {(integrations.find((integraion) => integraion.provider === IntegrationProvider.LunarEdge) && (
              <ProviderBadge size={BadgeSize.Large}>
                <Check /> Installed
              </ProviderBadge>
            )) || <ProviderButton variant={ButtonVariant.Primary}>Install</ProviderButton>}
          </ProviderCard>
        )}
      </Grid>
    </>
  );
};
