import React, { useState } from 'react';
import { Privilege, RoleDTO, Scope, Subject } from '@types';
import { useRoles } from '@hooks/useRoles';
import { isFixedRole, PRIVILEGES } from '@utils';
import { Tooltip } from '@material-ui/core';
import { HelpCircle } from 'react-feather';
import {
  Category,
  PrivilegesHeader,
  Item,
  Items,
  PrivilegeWrapper,
  Subtitle,
  CategoryTitle,
  Value,
  Values,
  ValueWrapper,
  Wrapper,
  TooltipProperty
} from './styled';

const settingsConfig = [
  {
    type: 'project',
    label: 'Records',
    tooltip: 'Clients, Requests, Projects',
    scopes: [
      { type: 'own', label: 'My records', tooltip: 'Records that I created or am a member of.' },
      {
        type: 'team',
        label: 'Team records',
        not: ['create'],
        tooltip: 'Records that any of my teammates created or are members of. Team records include "my records".'
      },
      { type: 'all', label: 'All records', not: ['create'] }
    ]
  },
  {
    type: 'task',
    label: 'Work orders & reminders',
    scopes: [
      {
        type: 'own',
        label: 'My work orders & reminders',
        tooltip: 'Work orders & reminders that I created or am an assignee of.'
      },
      {
        type: 'team',
        label: 'Team work orders & reminders',
        not: ['create'],
        tooltip:
          'Work orders & reminders that any of my teammates created or are assignees of. Team work orders & reminders include "my work orders & reminders".'
      },
      { type: 'all', label: 'All work orders & reminders', not: ['create'] }
    ]
  },
  {
    type: 'form',
    label: 'Forms',
    scopes: [
      { type: 'own', label: 'My forms', tooltip: 'Forms that I created or am an assignee of.' },
      {
        type: 'team',
        label: 'Team forms',
        not: ['create'],
        tooltip: 'Forms that any of my teammates created or are assignees of. Team forms include "my forms".'
      },
      { type: 'all', label: 'All forms', not: ['create'] }
    ]
  },
  {
    type: 'file',
    label: 'Docs',
    scopes: [
      { type: 'own', label: 'My files', not: ['edit'], tooltip: 'Files that I uploaded.' },
      {
        type: 'team',
        label: 'Team files',
        not: ['edit', 'create'],
        tooltip: 'Files that any of my teammates uploaded. Team files include "my files".'
      },
      { type: 'all', label: 'All files', not: ['edit', 'create'] }
    ]
  },
  {
    type: 'team',
    label: 'Teams',
    scopes: [
      { type: 'own', label: 'Own teams' },
      { type: 'all', label: 'All teams', not: ['create'] }
    ]
  },
  {
    type: 'workspace',
    label: 'Workspace',
    scopes: [
      { type: 'settings', label: 'Workspace settings', not: ['view', 'create', 'delete'] },
      { type: 'analytics', label: 'Analytics' },
      { type: 'invite', label: 'Invite members', not: ['view', 'create', 'delete'] },
      { type: 'import', label: 'Import projects', not: ['view', 'create', 'delete'] },
      { type: 'smartview', label: 'Smart views', not: ['none'] },
      { type: 'smartviewFolder', label: 'Hubs ', not: ['none'] }
    ]
  },
  {
    type: 'contract',
    label: 'Financials',
    scopes: [{ type: 'contract', label: 'Financials', not: ['create', 'delete'] }]
  }
];

interface RoleProps {
  role: RoleDTO;
}

export const Role: React.FC<RoleProps> = (props) => {
  const { role } = props;

  const canUpdate = !isFixedRole(role);

  const { updateRole } = useRoles();

  const [hoveredSubject, setHoveredSubject] = useState<Subject | null>(null);
  const [hoveredScope, setHoveredScope] = useState<Scope | null>(null);
  const [hoveredPrivilege, setHoveredPrivilege] = useState<Privilege | null>(null);

  const handleHover = (subject: Subject, scope: Scope, privilege: Privilege) => {
    setHoveredSubject(subject);
    setHoveredScope(scope);
    setHoveredPrivilege(privilege);
  };

  const handleBlur = () => {
    setHoveredSubject(null);
    setHoveredScope(null);
    setHoveredPrivilege(null);
  };

  const handleTogglePrivilege = async (subject: Subject, scope: Scope, privilege: Privilege) => {
    if (!canUpdate) {
      return;
    }

    const current = role.settings[subject]?.[scope];
    if (current === privilege) {
      return;
    }

    if (!role.settings[subject]) {
      role.settings[subject] = {};
    }
    role.settings[subject][scope] = privilege;

    await updateRole.mutateAsync({ roleId: role.id, dto: { settings: role.settings } });
  };

  return (
    <Wrapper>
      <PrivilegesHeader>
        {PRIVILEGES.map((p) => (
          <PrivilegeWrapper key={p}>{p}</PrivilegeWrapper>
        ))}
      </PrivilegesHeader>
      {settingsConfig.map((subjectConfig) => (
        <Category key={subjectConfig.type}>
          <CategoryTitle>
            {subjectConfig.label}
            {subjectConfig.tooltip && (
              <Tooltip
                id={subjectConfig.type}
                title={<TooltipProperty>{subjectConfig.tooltip}</TooltipProperty>}
                placement="top"
                arrow
              >
                <HelpCircle size={16} style={{ marginLeft: 10 }} />
              </Tooltip>
            )}
          </CategoryTitle>
          <Items>
            {subjectConfig.scopes.map((scopeConfig) => {
              const subject = subjectConfig.type as Subject;
              const scope = scopeConfig.type as Scope;

              const availablePrivileges = PRIVILEGES.filter((p) => !(scopeConfig.not || []).includes(p));
              const lastRenderedPrivilege = availablePrivileges.reduce(
                (acc, p) => Math.max(acc, PRIVILEGES.indexOf(p)),
                0
              );

              const grantedLevel = PRIVILEGES.indexOf(role.settings[subject]?.[scope]) || 0;

              return (
                <Item key={scopeConfig.type}>
                  <Subtitle>
                    {scopeConfig.label}
                    {scopeConfig.tooltip && (
                      <Tooltip
                        id={subjectConfig.type}
                        title={<TooltipProperty>{scopeConfig.tooltip}</TooltipProperty>}
                        placement="top"
                        arrow
                      >
                        <HelpCircle />
                      </Tooltip>
                    )}
                  </Subtitle>
                  <Values>
                    {PRIVILEGES.slice(0, lastRenderedPrivilege + 1).map((privilege, priviledgeIndex) => {
                      const isGranted = priviledgeIndex <= grantedLevel;
                      const isGrantedLast = priviledgeIndex === grantedLevel;

                      const isAvailable = availablePrivileges.includes(privilege);

                      const isHoveredActive =
                        canUpdate &&
                        hoveredSubject === subject &&
                        hoveredScope === scope &&
                        !!hoveredPrivilege &&
                        PRIVILEGES.indexOf(hoveredPrivilege) >= PRIVILEGES.indexOf(privilege);
                      const isHoveredInactive =
                        canUpdate &&
                        hoveredSubject === subject &&
                        hoveredScope === scope &&
                        !!hoveredPrivilege &&
                        PRIVILEGES.indexOf(hoveredPrivilege) < PRIVILEGES.indexOf(privilege);

                      const isLastActive =
                        (isHoveredActive && hoveredPrivilege === privilege) || (!isHoveredActive && isGrantedLast);

                      return (
                        <ValueWrapper
                          key={privilege}
                          active={isGranted}
                          available={isAvailable}
                          activeLast={isLastActive}
                          activeHover={isHoveredActive}
                          inactiveHover={isHoveredInactive}
                        >
                          <Value
                            active={isGranted}
                            available={isAvailable}
                            activeHover={isHoveredActive}
                            inactiveHover={isHoveredInactive}
                            onMouseEnter={() => handleHover(subject, scope, privilege)}
                            onMouseLeave={() => handleBlur()}
                            onClick={() => handleTogglePrivilege(subject, scope, privilege)}
                          />
                        </ValueWrapper>
                      );
                    })}
                  </Values>
                </Item>
              );
            })}
          </Items>
        </Category>
      ))}
    </Wrapper>
  );
};
