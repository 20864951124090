import styled from 'styled-components';
import { fonts, colors, dropdown, selectMui, inputMui, device } from '@styles';
import { makeStyles, MenuItem as MuiMenuItem } from '@material-ui/core';

const stylesSelect = selectMui({});
export const useStylesSelect = makeStyles(stylesSelect);

const stylesInput = inputMui({});
export const useStylesInput = makeStyles(stylesInput);

export const Text = styled.p`
  font: 14px/1.5 ${fonts.sans};
  color: ${colors.gray2};
  margin-bottom: 25px;
`;

export const Label = styled.label`
  font: 14px/1.5 ${fonts.sans};
  margin-bottom: 5px;
  display: block;
`;

export const Fields = styled.div`
  display: grid;
  grid-gap: 30px;
  
  ${device.sm`
    grid-template-columns: 1fr 1fr;
  `};
`;

export const Item = styled.div`
  ${device.sm`
    margin-bottom: 30px;
  `}
`;

export const MenuItem = styled(MuiMenuItem)`
  && {
    ${dropdown.item};
    min-width: auto;
    width: calc(100% - 20px);
    margin-left: 10px;
  }
`;

export const Dot = styled.div<{ color?: string }>`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background-color: ${props => props.color};
  margin-right: 8px;
`;


