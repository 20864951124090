import { ReactQueryKey } from '@enums';
import { ImportHistoryStatus, ImportRowStatus } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery, useQueryClient } from 'react-query';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '..';
import { ImportHistoryListItem } from './useImportHistoryList';

export type ImportHistoryListItemStatus = {
  id: number;
  status: ImportHistoryStatus;
  importRowsConnection: {
    importStatusCount: {
      status: ImportRowStatus[];
      count: {
        id: string;
      };
    }[];
  };
};

export const useImportHisotryItemStatus = (
  id: number,
  { isEnabled }: { isEnabled?: boolean } = { isEnabled: true }
) => {
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery(
    [ReactQueryKey.ImportHistoryItem, id],
    async () => {
      try {
        const data = await postGraphql<{ importHistory: ImportHistoryListItemStatus }>(
          gql`
            query ($id: Int!) {
              importHistory(id: $id) {
                id
                status
                importRowsConnection(orderBy: [IMPORT_ROW_NUMBER_ASC]) {
                  importStatusCount: groupedAggregates(groupBy: [STATUS]) {
                    status: keys
                    count: distinctCount {
                      id
                    }
                  }
                }
              }
            }
          `,

          { id }
        );

        return data.importHistory;
      } catch (e) {
        throw apiErrorHandler('Error fetching import history item', e);
      }
    },
    {
      enabled: isEnabled,
      refetchInterval: 10 * 1000,
      onSuccess: (data) => {
        queryClient.setQueriesData<ImportHistoryListItem[]>([ReactQueryKey.ImportHistory, companyId], (oldData) => {
          if (!oldData) {
            return oldData;
          }

          const index = oldData.findIndex((item: any) => item.id === id);

          if (index > -1) {
            const updatedNode = {
              ...oldData[index],
              status: data.status,
              importRowsConnection: {
                ...oldData[index].importRowsConnection,
                importStatusCount: data.importRowsConnection.importStatusCount
              }
            };

            return [...oldData.slice(0, index), updatedNode, ...oldData.slice(index + 1)];
          }

          return oldData;
        });
      }
    }
  );
};
