import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { CancelButton, Modal, SubmitButton } from '@common/ModalMui';
import { TextFieldFormik } from '@components/common/ui';
import { RoleDTO } from '@types';
import { isInvalidString } from '@utils';
import { ModalFields, ModalItem } from '@components/templates/Workspace/Roles/styled';

const validationSchema = () =>
  Yup.object({
    name: Yup.string()
      .required('Role name is required!')
      .test('empty-check', "Role name can't be blank", (value) => !isInvalidString(value))
  });

interface RoleModalProps {
  open: boolean;
  onClose: () => unknown;
  onSubmit: (values: Pick<RoleDTO, 'name'>) => Promise<void>;
  role?: RoleDTO;
  variant?: 'copy';
}

export const RoleModal = (props: RoleModalProps) => {
  const { open, onClose, onSubmit, role, variant } = props;

  const initialValues = {
    name: role?.name || ''
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        await onSubmit(values);
        resetForm({});
      }}
    >
      {({ submitForm }) => {
        return (
          <Modal
            open={open}
            onClose={onClose}
            title={(variant === 'copy' && 'Copy Role') || (role && 'Edit Role') || 'Create Role'}
            footerButtons={[
              <CancelButton key="cancelButton" onClick={onClose} />,
              <SubmitButton key="submitButton" onClick={submitForm} text={role ? 'Save' : 'Create'} />
            ]}
          >
            <Form>
              <ModalFields>
                <ModalItem>
                  <TextFieldFormik label="Name" name="name" placeholder="Name" required />
                </ModalItem>
              </ModalFields>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};
