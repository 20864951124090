import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils';
import { SystemsConnection } from '@generated/types/graphql';

export const useSystemsUuids = (ids: number[]) => useQuery<string[]>(
  [ReactQueryKey.System, 'useSystemsUuids', ids],
  async () => {
    try {
      return (await postGraphql<{ systemsConnection: SystemsConnection }>(gql`
        query ($ids: [Int!]!) {
          systemsConnection(filter: {id: {in: $ids}}) {
            nodes {
              uuid
            }
          }
        }
      `, { ids })).systemsConnection.nodes.map(({ uuid }) => uuid);
    } catch (e) {
      throw apiErrorHandler('Error fetching systems', e);
    }
  },
  {
    keepPreviousData: true,
    initialData: []
  }
);