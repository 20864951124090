import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { checkboxMui } from '@styles';

export const useStyles = makeStyles(checkboxMui({}));

export const PageHeader = styled.div`
  padding: 24px 0;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font: 500 14px/2.1 ${({ theme }) => theme.fonts.sans};
  color: ${({ theme }) => theme.colors.black};

  span {
    text-transform: uppercase;
    font: 500 10px/1.25 ${({ theme }) => theme.fonts.sans};
    color: ${({ theme }) => theme.colors.yellow};
    margin: -5px 0 0 5px;
  }
`;

export const Rules = styled.div`
  max-width: 554px;
  margin-top: 30px;
`;

export const RulesItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
`;

export const RulesTitle = styled.div`
  left: 30px;
  font: 14px/1.25 ${({ theme }) => theme.fonts.sans};
  color: ${({ theme }) => theme.colors.gray2};
`;

export const Row = styled.div`
  ${({ theme }) => theme.devices.md`
    display: grid;
    gap: 50px;
    grid-template-columns: 1fr 1fr;
  `}
`;

export const Col = styled.div``;
