import React, { useEffect } from 'react';
import { Empty, Router } from '@common';
import Authentication from '@components/Authentication/Authentication.new';
import { Dispatcher, ListView, Scheduler } from '@components/Scheduler';
import { Settings } from '@components/templates/Settings';
import { UserList } from '@features/Platform/Users/List';
import Labels from '@components/templates/Workspace/Labels/Labels';
import { Catalog } from '@components/templates/Workspace/Catalog';
import { WorkflowPage } from '@components/templates/Workspace/Workflows';
import { Roles } from '@components/templates/Workspace/Roles';
import { ProjectList } from '@features/ProjectPortfolio/List';
import { navigate, Redirect } from '@reach/router';
import { FormBuilderPage } from '@features/Platform/Templates/FormBuilder';
import { WorkspaceSettingsLayout } from '@layouts/WorkspaceSettingsLayout';
import { TemplateList } from '@features/Platform/Templates/List';
import { PropertyList } from '@features/Platform/Properties/List';
import { AutomationList } from '@features/Platform/Automations/List';
import { AutomationPage } from '@features/Platform/Automations/Form';
import { WorkflowList } from '@features/Platform/Workflows/List';
import { Inbox } from '@features/Platform/Inbox';
import { ClientList } from '@features/ClientPortfolio/List';
import { RequestList } from '@features/RequestPortfolio/List';
import { TeamList } from '@features/Platform/Teams/List';
import { PortalSettings } from '@features/Platform/PortalSettings';
import { ContactList } from '@features/Contacts/List';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { Import } from '@features/Platform/Import';
import { EmailsAndPhones } from '@features/Platform/EmailsAndPhones';
import { Notifications } from '@features/Platform/Notifications';
import { EnphaseIntegraion, FleetIntegrationsList, SolarEdgeIntegraion } from '@features/Platform/Fleet';
import { SystemList } from '@features/SystemPortfolio/List';
import { StageList } from '@features/Platform/Stages/List';
import { GeolocationSettings } from '@features/Platform/GeolocationSettings';
import { GeneralSettings } from '@features/Platform/GeneralSettings';
import { Analytics } from '@features/Analytics';

// LuxonSettings.defaultLocale = 'en-US';

const PATH_MAP = {
  '/calendar': '/scheduler',
  '/dispatcher': '/dispatcher-new',
  '/tasks': '/list'
};

const RedirectOldCalendarToWorkspaceScope = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  useEffect(() => {
    const path = window.location.pathname;
    const newPath = PATH_MAP[path];
    if (newPath) {
      navigate(`/${companyId}${newPath}`);
    }
  }, [companyId]);

  return null;
};

const RoutesEntry = () => (
  // <Layout>
  <Router basepath="/">
    <Redirect from="/:companyId/deals/pipeline" to="/:companyId/requests" noThrow />
    <Redirect from="/:companyId/deals/:dealId" to="/:companyId/requests/:dealId" noThrow />
    <Redirect from="/:companyId/accounts/:accountId" to="/:companyId/clients/:accountId" noThrow />

    <Redirect from="/:companyId/workspace/settings" to="/:companyId/workspace/properties/project" noThrow />
    <Redirect from="/:companyId/accounts/:projectID/kanban" to="/:companyId/accounts/:projectID/work" noThrow />

    <Empty path=":companyId">
      {/* IMPORTANT! When you add new subroot, also support it in selectIsInsideCompanyPage, WORKSPACE_IN_URL_PATTERN
       and related code */}

      <ClientList path="clients/*" />
      <RequestList path="requests/*" />
      <ProjectList path="projects/*" />
      <Analytics path="/analytics" />

      <ContactList path="/contacts" />
      <SystemList path="systems/*" />

      <Inbox path="inbox/*" />

      <Import path="import" />

      <WorkflowPage path="/workspace/workflows/:workflowId" />
      <FormBuilderPage path="/workspace/templates/forms/:formId" />

      <WorkspaceSettingsLayout path="/workspace">
        <GeneralSettings path="/general" />
        <Catalog path="/catalog" />
        <Labels path="/labels" />
        <WorkflowList path="/workflows" />
        <UserList path="/users" />
        <TeamList path="/teams" />
        <PropertyList path="/properties/:recordType" />

        <StageList path="/stages" />

        <AutomationList path="/automations" />
        <AutomationPage path="/automations/:automationId" />

        <TemplateList path="/templates/:entity/*" />

        <Empty path="/roles">
          <Roles path="/" />
          <Roles path="/:roleId" />
        </Empty>
        <Redirect from="integrations" to="../emails-and-phones" noThrow />
        <EmailsAndPhones path="/emails-and-phones" />
        <Notifications path="/notifications" />
        <PortalSettings path="/portal" />

        <Empty path="/fleet">
          <FleetIntegrationsList path="/" />
          <EnphaseIntegraion path="/enphase" />
          <SolarEdgeIntegraion path="/solar-edge" />
        </Empty>

        <GeolocationSettings path="/geolocation" />
      </WorkspaceSettingsLayout>
      <Scheduler path="/scheduler/*" />
      <Dispatcher path="/dispatcher-new/*" />
      <ListView path="/list/*" />
    </Empty>

    <RedirectOldCalendarToWorkspaceScope path="/calendar" />
    <RedirectOldCalendarToWorkspaceScope path="/dispatcher" />
    <RedirectOldCalendarToWorkspaceScope path="/tasks" />

    <Settings path="/settings/:tab" />
    <Authentication path="/authentication" />
    <Authentication path="/forgot-password" isForgotPassword />
    <Authentication path="/invite" isInvitePage />
  </Router>
  // </Layout>
);

// noinspection JSUnusedGlobalSymbols used by Gatsby
export default RoutesEntry;
