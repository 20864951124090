import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks';
import { Integration } from '@generated/types/graphql';
import { errorHandler } from '@services/api/helpers';
import { IntegrationDTO } from '@types';
import integrationsAPI from '@services/api/integrationsAPI';
import { ReactQueryKey } from '@enums';
import { capitalizeFirstLetter } from '@utils';

export const useCreateIntegration = (userId: number, companyId: number) => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<Integration, Error, IntegrationDTO>(async (dto) => {
    try {
      return (await integrationsAPI.createIntegration(userId, companyId, dto)).data;
    } catch (error) {
      throw errorHandler(error);
    }
  }, {
    onSuccess: (integraion: Integration) => {
      showSuccess(`Integration with ${capitalizeFirstLetter(integraion.provider.toLocaleLowerCase())} created successfully`);
      queryClient.invalidateQueries([ReactQueryKey.Integration]);
    }
  });
}