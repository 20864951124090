import React from 'react';
import { AutomationField, Property, PropertyType, RecordType } from '@types';
import { DynamicEntity, InputType, StepFieldKeys } from '@enums';
import { Control } from 'react-hook-form';
import { CheckboxField, InputField, RadioGroupField, RichTextField, SelectField } from '@kit/components/Form';
import { useTokens } from '@hooks/useTokens';
import { PropertyValueField } from '@features/Platform/Properties/PropertyValueField';
import { WorkOrderTemplateListItem } from '@hooks/templates/workOrders/useWorkOrderTemplateList';
import { SlaRadioField } from './SlaRadioField';
import { RecordStagesField } from './RecordStagesField';
import { DynamicSelectField } from './DynamicSelectField';
import { UserField } from './UserField';
import { ReminderDueDateField } from './ReminderDueDateField';
import { WorkOrderTemplateField } from './WorkOrderTemplateField';
import { WorkOrderStatusField } from './WorkOrderStatusField';

interface Props {
  field: AutomationField;
  isDisabled?: boolean;
  control: Control<any>;
  selectedStage?: any;
  recordType?: RecordType;
  typeTweaks: TypeTweaks;
  area: 'trigger' | 'action';
}

export type TypeTweaks = {
  [InputType.PROPERTY_SELECTOR]?: {
    columns: Property[];
  };
  [InputType.PROPERTY_INPUT]?: {
    column: Property;
  };
  [InputType.OPTIONS_DYNAMIC]?: {
    selectedWorkOrderTemplate: WorkOrderTemplateListItem | null;
  };
};

export const Field = ({ field, control, isDisabled, selectedStage, recordType, typeTweaks, area }: Props) => {
  const { type, allowMultiple } = field;

  const { getAllTokens, getTokensFieldTo, getTokensFieldPhone } = useTokens(recordType, true, false);

  if ([StepFieldKeys.DUE_DATE_NUMBER, StepFieldKeys.DUE_DATE_UNIT].includes(field.key)) {
    if (field.key === StepFieldKeys.DUE_DATE_UNIT) {
      return null;
    }

    return <ReminderDueDateField isDisabled={isDisabled} control={control} />;
  }

  if (type === InputType.OPTIONS_DYNAMIC) {
    const tweaks = typeTweaks[type];

    if (field.dynamic?.entity === DynamicEntity.TASK_STATUS) {
      const selectedTemplate = tweaks?.selectedWorkOrderTemplate;

      return (
        <WorkOrderStatusField
          isFieldOnly={selectedTemplate?.isField === true}
          isOfficeOnly={selectedTemplate?.isField === false}
          field={field}
          isDisabled={isDisabled}
          control={control}
        />
      );
    }

    if (field.key === StepFieldKeys.TASK_ID || field.key === StepFieldKeys.TEMPLATE_TASK_ID) {
      return <WorkOrderTemplateField field={field} isDisabled={isDisabled} control={control} />;
    }

    return <DynamicSelectField control={control} field={field} isDisabled={isDisabled} />;
  }

  if (field.type === InputType.CUSTOM && field.dynamic?.entity === DynamicEntity.SLA_TYPE) {
    return <SlaRadioField label={field.label} control={control} name={field.key} stageId={selectedStage} />;
  }

  if (field.dynamic?.entity === DynamicEntity.PROJECT_STAGE) {
    return (
      <RecordStagesField control={control} field={field} isDisabled={isDisabled} recordType={RecordType.PROJECT} />
    );
  }

  if (field.dynamic?.entity === DynamicEntity.DEAL_STAGE) {
    return <RecordStagesField control={control} field={field} isDisabled={isDisabled} recordType={RecordType.DEAL} />;
  }

  if (type === InputType.EMAIL) {
    return (
      <RichTextField
        getTokens={getTokensFieldTo}
        isOneLine
        isToolbarHiddenCompletely
        placeholder="customer@example.com"
        label={field.label}
        name={field.key}
        control={control}
        isDisabled={isDisabled}
      />
    );
  }

  if (field.key === StepFieldKeys.SUBJECT) {
    return (
      <RichTextField
        getTokens={getAllTokens}
        isOneLine
        isToolbarHiddenCompletely
        placeholder=""
        label={field.label}
        name={field.key}
        control={control}
        isDisabled={isDisabled}
      />
    );
  }

  if (field.key === StepFieldKeys.PHONE) {
    return (
      <RichTextField
        getTokens={getTokensFieldPhone}
        isOneLine
        isToolbarHiddenCompletely
        placeholder="/Customer phone"
        label={field.label}
        name={field.key}
        control={control}
        isDisabled={isDisabled}
      />
    );
  }

  if ([StepFieldKeys.BODY, StepFieldKeys.MESSAGE, StepFieldKeys.DESCRIPTION].includes(field.key)) {
    return (
      <RichTextField
        getTokens={getAllTokens}
        isToolbarHiddenCompletely={field.key === StepFieldKeys.MESSAGE}
        placeholder="Dear /Project name"
        label={field.label}
        name={field.key}
        control={control}
        isDisabled={isDisabled}
      />
    );
  }

  if (type === InputType.CHECKBOX) {
    return <CheckboxField control={control} name={field.key} label={field.label} />;
  }

  if (type === InputType.PROPERTY_SELECTOR) {
    const tweaks = typeTweaks[type];
    if (!tweaks || !tweaks.columns) {
      return null;
    }

    return (
      <SelectField
        control={control}
        label={field.label}
        name={field.key}
        options={tweaks.columns}
        getOptionLabel={(option: any) => option?.name}
        getOptionValue={(option: any) => option?.id}
        fieldValueIsOptionValue
      />
    );
  }

  if (type === InputType.PROPERTY_INPUT) {
    const tweaks = typeTweaks[type];
    if (!tweaks || !tweaks.column) {
      return null;
    }

    // selecting specific file for file trigger is meaningless, disable it
    if (area === 'trigger' && tweaks.column.type === PropertyType.File) {
      return null;
    }

    return (
      <PropertyValueField
        clearOnUnmount
        label={field.label}
        name={field.key}
        property={tweaks.column}
        control={control}
      />
    );
  }

  if (type === InputType.USER_SELECTOR) {
    return <UserField isMulti={allowMultiple} field={field} isDisabled={isDisabled} control={control} />;
  }

  if (type === InputType.TEXT) {
    return <InputField label={field.label} name={field.key} control={control} />;
  }

  if (type === InputType.CHOICE_STATIC) {
    return (
      <RadioGroupField
        label={field.label}
        options={field.options.map(({ key, value }) => ({ value: '' + key, label: value }))}
        name={field.key}
        layout="row"
        control={control}
      />
    );
  }

  if (type === InputType.OPTIONS_STATIC) {
    return (
      <SelectField
        label={field.label}
        name={field.key}
        options={field.options}
        getOptionValue={(option) => option?.key}
        getOptionLabel={(option) => option?.value}
        control={control}
        fieldValueIsOptionValue
      />
    );
  }

  return null;
};
