import React, { ReactNode, useState } from 'react';
import { makeStyles, Popover } from '@material-ui/core';
import { ChevronDown, Plus, Trash2 } from 'react-feather';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { colors } from '@styles';
import { TextField } from '@common/ui';
import { autocomplete } from '@common/ui/Autocomplete/styled';
import { AutocompleteFooter, NewWorkOrderTemplateButton, Button as Wrapper } from './styled';

interface ButtonProps<Item> {
  onAdd?: (item: Item) => void;
  onRemove?: () => void;
  icon?: JSX.Element;
  options?: Item[];
  renderOption?: (option: Item) => ReactNode;
  renderLabel?: (option: Item) => string;
  first?: boolean;
  placeholder?: boolean;
  isWorkOrderSelector?: boolean;
}

export const Button = <Item,>(props: ButtonProps<Item>) => {
  const {
    onAdd,
    onRemove,
    icon,
    first = false,
    options,
    renderOption,
    renderLabel,
    placeholder = false,
    isWorkOrderSelector
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popupId = open ? 'menu-popup-id' : undefined;

  const handleRemove = (e: any) => {
    e.stopPropagation();

    if (onRemove) {
      onRemove();
    }
  };

  const handleAdd = (item: Item) => {
    if (onAdd) {
      onAdd(item);
      setAnchorEl(null);
    }
  };

  const handleOpen = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const classes = makeStyles({
    ...autocomplete({}),
    popperDisablePortal: { position: 'relative' },
    paper: {
      boxShadow: 'none'
    }
  })();

  const useStyles = makeStyles({
    paper: {
      minWidth: '184px'
    }
  });
  const classesPopover = useStyles();

  if (onAdd && (!options || !renderOption || !renderLabel)) {
    throw Error('Using onAdd requires options and renderOptions and renderLabel for autocomplete!');
  }

  return (
    <>
      {onAdd && (
        <Wrapper onClick={(e) => !open && handleOpen(e)} variant="contained" first={first} placeholder={placeholder}>
          {icon || <Plus />}
          <Popover
            id={popupId}
            open={open}
            anchorEl={anchorEl}
            onClose={(e) => handleClose(e)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            classes={{
              paper: classesPopover.paper
            }}
          >
            <div>
              <Autocomplete<Item>
                key="blueprints_add"
                classes={classes}
                options={options!}
                getOptionLabel={(option) => renderLabel!(option)}
                onChange={(_, value) => handleAdd(value!)}
                renderOption={(option) => renderOption!(option)}
                renderInput={(params) => <TextField {...params} autoFocus placeholder="Search..." />}
                popupIcon={<ChevronDown />}
                open
                disablePortal
                openOnFocus
              />
              {isWorkOrderSelector && (
                <AutocompleteFooter>
                  <NewWorkOrderTemplateButton
                    onClick={() => {
                      onAdd();
                      setAnchorEl(null);
                    }}
                  >
                    + Work order template
                  </NewWorkOrderTemplateButton>
                </AutocompleteFooter>
              )}
            </div>
          </Popover>
        </Wrapper>
      )}
      {onRemove && (
        <Wrapper onClick={(e) => handleRemove(e)} variant="contained">
          {icon || <Trash2 color={colors.red} />}
        </Wrapper>
      )}
    </>
  );
};
