import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import integrationsAPI from '@services/api/integrationsAPI';
import { IntegrationProgress } from '@types';
import { UseQueryOptions } from 'react-query/types/react/types';

export const useIntegrationProgress = (integrationId: number, opts?: UseQueryOptions<IntegrationProgress>) =>
  useQuery<IntegrationProgress>(
    [ReactQueryKey.Integration, 'useIntegrationProgress', integrationId],
    async () => {
      try {
        return (await integrationsAPI.getInitiateProgress(integrationId)).data;
      } catch (e) {
        throw apiErrorHandler('Error fetching integration progress', e);
      }
    },
    {
      initialData: null,
      ...opts
    }
  );
