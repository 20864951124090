import React, { useCallback, useEffect, useState } from 'react';
import { Input } from '@kit/ui/Input';
import { useAppSelector } from '@hooks/store';
import { selectCompanyById, selectWorkspaceId } from '@state/selectors';
import { useUpdateCompany } from '@hooks/useUpdateCompany';
import { CompanyLogo } from './CompanyLogo';
import { Sections, Description, H2, Header, Title, FieldLabel, Container } from './styled';

export const GeneralSettings = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));

  const [name, setName] = useState(company?.name || '');

  const { mutateAsync: updateCompany } = useUpdateCompany();

  useEffect(() => {
    setName((prev) => (!prev ? company?.name || '' : prev));
  }, [company?.name]);

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleNameBlur = useCallback(async () => {
    if (name && name.trim() && name !== company?.name) {
      await updateCompany({ name: name.trim() });
    }

    if (!name) {
      setName(company?.name || '');
    }
  }, [name, updateCompany, company?.name]);

  return (
    <Container>
      <Header>
        <Title>General settings</Title>
        <Description>Configure your company general settings</Description>
      </Header>

      <Sections>
        <div>
          <H2>Company name</H2>

          <div>
            <FieldLabel>Your company name</FieldLabel>
            <Input value={name} onChange={handleNameChange} onBlur={handleNameBlur} />
          </div>
        </div>
        <CompanyLogo />
      </Sections>
    </Container>
  );
};
