import styled from 'styled-components';
import { fonts, colors } from '@styles';

export const Wrapper = styled.div`
  background-color: #E8ECEF;
  border-radius: 4px;
  padding: 17px 20px;
  margin: 20px 0;
  
  &:first-child {
    margin-top: 0;
  }
`;

export const TooltipWrapper = styled.div`
  font: 12px/1.25 ${fonts.sans};

  p {
    &:empty {
      display: none;
    }

    margin-top: 10px;
  }
`;

export const Title = styled.div`
  font: 500 18px/1.25 ${fonts.sans};
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  
  svg {
    margin-left: 5px;
  }
`;

export const Text = styled.div`
  font: 14px/1.25 ${fonts.sans};
  color: ${colors.gray};
  strong {
    font-weight: 500;
  }
`;
