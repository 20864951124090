import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { ChartPeriod, chartPointsBrackets, chartTotalBrackets } from '@hooks/systems/constants';
import { DateTime } from 'luxon';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { EnergyProductionChartsConnection } from '@generated/types/graphql';
import { EnergyPoint } from '@hooks/systems/types';
import { UseQueryOptions } from 'react-query/types/react/types';

export type ProductionChart = { total: number | null; points: EnergyPoint[] };

export const useSystemsProductionChart = (
  uuids: string[],
  period: ChartPeriod,
  startAt: DateTime,
  endBefore: DateTime,
  opts?: UseQueryOptions<ProductionChart>
) =>
  useQuery<ProductionChart>(
    [ReactQueryKey.System, 'useSystemsProductionChart', uuids, { period, startAt, endBefore }],
    async () => {
      try {
        const { seriesPeriod: totalSeriesPeriod, targetPeriod: totalTargetPeriod } = chartTotalBrackets[period];
        const { seriesPeriod: pointsSeriesPeriod, targetPeriod: pointsTargetPeriod } = chartPointsBrackets[period];

        const variables = {
          uuids,
          startAt: startAt?.toISO(),
          endBefore: endBefore?.toISO(),
          totalSeriesPeriod,
          totalTargetPeriod,
          pointsSeriesPeriod,
          pointsTargetPeriod
        };

        const { total, points } = await postGraphql<{
          total: EnergyProductionChartsConnection;
          points: EnergyProductionChartsConnection;
        }>(
          gql`
            query (
              $uuids: [UUID!]!
              $startAt: Datetime
              $endBefore: Datetime
              $totalSeriesPeriod: IntervalInput!
              $totalTargetPeriod: IntervalInput!
              $pointsSeriesPeriod: IntervalInput!
              $pointsTargetPeriod: IntervalInput!
            ) {
              total: energyProductionChartsConnection(
                condition: { seriesPeriod: $totalSeriesPeriod, targetPeriod: $totalTargetPeriod }
                filter: { system: { in: $uuids }, time: { greaterThanOrEqualTo: $startAt, lessThan: $endBefore } }
              ) {
                aggregates {
                  sum {
                    value
                  }
                }
              }

              points: energyProductionChartsConnection(
                condition: { seriesPeriod: $pointsSeriesPeriod, targetPeriod: $pointsTargetPeriod }
                filter: { system: { in: $uuids }, time: { greaterThanOrEqualTo: $startAt, lessThan: $endBefore } }
              ) {
                groupedAggregates(groupBy: [TIME]) {
                  keys
                  sum {
                    value
                  }
                }
              }
            }
          `,
          variables
        );

        return {
          total: total.aggregates.sum.value,
          points: points.groupedAggregates.map((agg) => ({
            time: DateTime.fromISO(agg.keys[0]).toLocal(),
            production: agg.sum.value
          }))
        };
      } catch (e) {
        throw apiErrorHandler('Error fetching systems production', e);
      }
    },
    {
      keepPreviousData: true,
      initialData: { total: null, points: [] },
      ...opts
    }
  );