import { DescriptionField } from '@common/index';
import { useFormikContext } from 'formik';
import React from 'react';
import { Plus } from 'react-feather';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { useClickOutside } from '@react-hookz/web';
import { EditableFieldContainer } from '../styled';
import { useInlineUpdateField } from '../useInlineUpdateField';
import { CommonFieldProps } from '../types';

const EMPTY_TEXT = '<p><span class="ql-cursor">﻿</span></p>';

export const Description = ({ onUpdated }: CommonFieldProps) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineUpdateField(onUpdated);
  const context = useFormikContext();

  useClickOutside(nodeRef, () => {
    if (isEdit) {
      setIsFocused(false);
    }
  });

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <DescriptionField
          name="description"
          // label="Description"
          data-testid="taskEditorFieldDescription"
          onFocus={() => {
            setIsFocused(true);
          }}
        />
      </div>
    );
  }

  if (
    !context.values.description ||
    context.values.description.length === 0 ||
    context.values.description === EMPTY_TEXT
  ) {
    return (
      <Button variant={ButtonVariant.Flat} size={ButtonSize.Small} isUpperCase={false} onClick={() => setIsEdit(true)}>
        <Plus size="16px" />
        Add a description
      </Button>
    );
  }

  return (
    <EditableFieldContainer onClick={() => setIsEdit(true)}>
      <div className="ql-snow">
        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: context.values.description }} />
      </div>
    </EditableFieldContainer>
  );
};
