import React, { useMemo, useState } from 'react';
import { BlueprintDTO, RoleDTO, Routable } from '@types';
import { useAppSelector } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { useRoles } from '@hooks/useRoles';
import { Section } from '@components/templates/common';
import { Switcher } from '@common/ui';
import { TBody, TCell, TContainer, ThCell, THead, TRow, User } from '@components/templates/Workspace/common/styled';
import { Grid, Table } from '@material-ui/core';
import { Actions } from '@components/templates/Workspace/common/Actions';
import { RoleModal } from '@components/templates/Workspace/Roles/RoleModal';
import { Role } from '@components/templates/Workspace/Roles/Role';
import { Back, Breadcrumbs, BreadcrumbTitle } from '@common/Styled/Breadcrumb';
import { AlertTriangle, ChevronRight, Plus } from 'react-feather';
import { EMPTY_ROLE_SETTINGS, isFixedRole } from '@utils';
import { normalizeRoleName } from '@utils/roles';
import { SearchBar } from '@common/SearchBar';
import { CancelButton, Modal, SubmitButton } from '@common/ModalMui';
import { colors } from '@styles';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { UserAvatar } from '@kit/components/UserAvatar';
import { Header, Title } from './styled';

interface RolesProps extends Routable {
  roleId: string;
}

export const Roles: React.FC<RolesProps> = (props) => {
  const { navigate, roleId } = props;

  const companyId = useAppSelector(selectWorkspaceId);

  const {
    rolesQuery: { data: roles },
    createRole,
    deleteRole,
    updateRole
  } = useRoles();

  const [searchValue, setSearchValue] = useState('');
  const handleSearchValueChanged = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const filteredRoles = useMemo(() => {
    if (!roles) {
      return [];
    }

    return roles.filter((role) => role.name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [roles, searchValue]);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RoleDTO | undefined>();
  const [copiedRole, setCopiedRole] = useState<RoleDTO | undefined>();

  if (!companyId) {
    return null;
  }

  const handleRoleOpen = (role: RoleDTO) => navigate?.(`/${companyId}/workspace/roles/${role.id}`);

  const handleRoleCreate = () => {
    setSelectedRole(undefined);
    setEditModalOpen(true);
  };

  const handleRoleUpdate = (role: RoleDTO) => {
    setSelectedRole(role);
    setEditModalOpen(true);
  };

  const handleRoleRemove = (role: RoleDTO) => {
    setSelectedRole(role);
    setDeleteModalOpen(true);
  };

  const handleConfirmRemove = async () => {
    await deleteRole.mutateAsync(selectedRole!.id);

    setSelectedRole(undefined);
    setDeleteModalOpen(false);
  };

  const handleEditRoleSubmit = async (dto: Pick<BlueprintDTO, 'name'>) => {
    if (selectedRole) {
      await updateRole.mutateAsync({ roleId: selectedRole.id, dto: { name: dto.name } });
    } else {
      await createRole.mutateAsync({ ...dto, settings: EMPTY_ROLE_SETTINGS });
    }

    setEditModalOpen(false);
    setSelectedRole(undefined);
  };

  const handleCopyRoleSubmit = async (dto: Pick<BlueprintDTO, 'name'>) => {
    if (copiedRole) {
      await createRole.mutateAsync({ ...dto, settings: copiedRole.settings });
    }

    setCopyModalOpen(false);
    setCopiedRole(undefined);
  };

  const handleToggleActive = async (role: RoleDTO) => {
    await updateRole.mutateAsync({ roleId: role.id, dto: { active: !role.active } });
  };

  const handleRoleCopy = (role: RoleDTO) => {
    setCopiedRole({ ...role, name: `${role.name} (copy)` });
    setCopyModalOpen(true);
  };

  const role = roleId ? roles!.find(({ id }) => id === +roleId) : undefined;

  return (
    <div>
      {role ? (
        <>
          <Breadcrumbs>
            <Back onClick={() => navigate?.(`/${companyId}/workspace/roles`)}>Roles</Back>
            <BreadcrumbTitle>
              <ChevronRight size={20} />
              {normalizeRoleName(role.name)}
            </BreadcrumbTitle>
          </Breadcrumbs>
          <Role role={role} />
        </>
      ) : (
        <>
          <Header>
            <Title>Roles</Title>
          </Header>
          <Section>
            <Button variant={ButtonVariant.Primary} onClick={handleRoleCreate} style={{ marginRight: '16px' }}>
              <Plus size="16px" />
              Role
            </Button>
            <SearchBar value={searchValue} onChange={handleSearchValueChanged} placeholder="Search for role ..." />
          </Section>
          <TContainer>
            <Table
              stickyHeader={
                typeof window !== 'undefined' && window.innerWidth > 768 // gatsby
              }
              aria-label="sticky table"
            >
              <THead>
                <TRow>
                  <TCell>
                    <ThCell key="Name">Role name</ThCell>
                  </TCell>
                  <TCell>
                    <ThCell key="Active">Active</ThCell>
                  </TCell>
                  <TCell>
                    <ThCell key="CreatedBy">Created by</ThCell>
                  </TCell>
                  <TCell>
                    <ThCell key="MembersCount">Members with role</ThCell>
                  </TCell>
                  <TCell />
                </TRow>
              </THead>
              <TBody>
                {filteredRoles!.map((role) => (
                  <TRow key={role.id} onClick={() => handleRoleOpen(role)}>
                    <TCell>{normalizeRoleName(role.name)}</TCell>
                    <TCell>
                      <Switcher
                        onClick={(e) => e.stopPropagation()}
                        checked={role.active}
                        onChange={() => handleToggleActive(role)}
                        disabled={isFixedRole(role)}
                      />
                    </TCell>
                    <TCell>
                      <User>
                        {role.createdBy && (
                          <>
                            <UserAvatar user={role.createdBy} />
                            <span>{`${role.createdBy.firstName} ${role.createdBy.lastName}`}</span>
                          </>
                        )}
                      </User>
                    </TCell>
                    <TCell>{role.companyUsers.length}</TCell>
                    <TCell>
                      <Actions
                        name="role"
                        onEdit={
                          isFixedRole(role)
                            ? undefined
                            : (e) => {
                                e.stopPropagation();
                                handleRoleUpdate(role);
                              }
                        }
                        onRemove={
                          isFixedRole(role)
                            ? undefined
                            : (e) => {
                                e.stopPropagation();
                                handleRoleRemove(role);
                              }
                        }
                        onCopy={(e) => {
                          e.stopPropagation();
                          handleRoleCopy(role);
                        }}
                      />
                    </TCell>
                  </TRow>
                ))}
              </TBody>
            </Table>
          </TContainer>

          <RoleModal
            open={editModalOpen}
            onClose={() => {
              setEditModalOpen(false);
              setSelectedRole(undefined);
            }}
            onSubmit={handleEditRoleSubmit}
            role={selectedRole}
          />
          <RoleModal
            open={copyModalOpen}
            onClose={() => {
              setCopyModalOpen(false);
              setCopiedRole(undefined);
            }}
            onSubmit={handleCopyRoleSubmit}
            role={copiedRole}
            variant="copy"
          />
          {selectedRole && (
            <Modal
              open={deleteModalOpen}
              onClose={() => {
                setDeleteModalOpen(false);
                setSelectedRole(undefined);
              }}
              title="Delete Role"
              footerButtons={[
                <CancelButton key="cancel" onClick={() => setDeleteModalOpen(false)} />,
                <SubmitButton
                  key="delete"
                  onClick={handleConfirmRemove}
                  text="Delete"
                  disabled={selectedRole.companyUsers.length > 0}
                />
              ]}
            >
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <AlertTriangle size={60} color={colors.green} />
                {selectedRole.companyUsers.length > 0
                  ? `There are still ${selectedRole.companyUsers.length} workspace members with the role
                     ${selectedRole.name}. In order to delete this role, give them a different role first.`
                  : `Are you sure you want to delete role ${selectedRole.name}? This action cannot be undone`}
              </Grid>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};
