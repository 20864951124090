import React, { useState, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { useEffectOnce } from 'react-use';
import { connect } from 'react-redux';
import {
  fetchWorkspaceLabels,
  createWorkspaceLabel,
  updateWorkspaceLabel,
  removeWorkspaceLabel
} from '@state/actions/settingsAction/labelSettingAction';
import ConfirmationService from '@common/ConfirmationService/ConfirmationService';
import { SearchBar } from '@common/SearchBar';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Plus } from 'react-feather';
import { Section, Body } from '../../common';
import { LabelsList } from './LabelsList';
import { Row, Col, PageHeader, Title } from './styled';
import { Label as LabelType, LabelApi } from './LabelsList/types';
import { LabelModal } from './LabelModal';

const Labels = (props): JSX.Element => {
  const { onFetchLabels, companyId, labelList, onCreateLabel, onUpdateLabel, onRemoveLabel } = props;

  const userCompanyId = +companyId;

  const [filterInputValue, setFilterInputValue] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [typeLabelModal, setTypeLabelModal] = useState('');

  useEffectOnce(() => {
    onFetchLabels('', userCompanyId);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchFilterSearch = useCallback(
    debounce((value: string) => onFetchLabels(value, userCompanyId), 700),
    []
  );

  const onFilterValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilterInputValue(value);
    fetchFilterSearch(value.trim());
  };

  const confirmService = useRef();

  const [selectedLabel, setSelectedLabel] = useState({
    id: null,
    name: '',
    color: '',
    type: ''
  });

  const handleCloseLabelModal = () => {
    setOpenModal(false);
    setSelectedLabel({});
  };

  const onSubmit = (label: LabelType) => {
    const payload = { ...label, label: label.name };

    if (typeLabelModal === 'create') {
      onCreateLabel(payload, companyId);
    }
    if (typeLabelModal === 'edit') {
      onUpdateLabel(payload);
    }
    setOpenModal(false);
  };

  const checkIsUnique = (value: string) => labelList.filter((item) => item.name === value).length === 0;

  const handleCreateNewLabel = () => {
    setTypeLabelModal('create');
    setOpenModal(true);
  };

  const handleEditLabel = (id: number) => {
    setSelectedLabel(labelList.find((item) => item.id === id));
    setTypeLabelModal('edit');
    setOpenModal(true);
  };

  const handleRemoveLabel = async (id: number) => {
    confirmService.current.show().then((res) => {
      if (res) {
        onRemoveLabel(id);
      }
    });
  };

  return (
    <>
      <div>
        <PageHeader>
          <Title>Labels</Title>
        </PageHeader>
        <Body>
          <Section>
            <Button variant={ButtonVariant.Primary} onClick={handleCreateNewLabel} style={{ marginRight: '16px' }}>
              <Plus size="16px" />
              Label
            </Button>
            <SearchBar value={filterInputValue} onChange={onFilterValueChanged} placeholder="Search for label..." />
          </Section>
          <Row>
            <Col>
              <LabelsList
                data={labelList}
                companyId={userCompanyId}
                handleEditLabel={handleEditLabel}
                handleRemoveLabel={handleRemoveLabel}
              />
            </Col>
          </Row>
        </Body>
      </div>
      <LabelModal
        open={openModal}
        onClose={handleCloseLabelModal}
        type={typeLabelModal}
        selectedLabel={selectedLabel}
        onSubmit={onSubmit}
        checkIsUnique={checkIsUnique}
      />
      <ConfirmationService
        confirmMessege="Yes"
        cancelMessege="No"
        messege="Are you sure you want to delete this label?"
        ref={confirmService}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { workspaceSettings } = state;

  return {
    labelList: workspaceSettings?.label?.list ?? [],
    isFetching: workspaceSettings?.label?.isFetching ?? false
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchLabels: (searchValue: string, companyId: number) => {
      dispatch(fetchWorkspaceLabels({ skip: 0, limit: 100, name: searchValue } as any, companyId));
    },
    onCreateLabel: (label: LabelApi, companyId: number) => {
      dispatch(createWorkspaceLabel(label, { companyId }));
    },
    onUpdateLabel: (label: LabelApi) => {
      dispatch(updateWorkspaceLabel(label.id, label));
    },
    onRemoveLabel: (id: number) => {
      dispatch(removeWorkspaceLabel(id));
    }
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Labels);
