import styled from 'styled-components';
import { LinearProgressWithLabel } from '@common';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Loader = styled(LinearProgressWithLabel)`
  && {
    height: 8px;
    border-radius: 4px;
    background-color: #DFDFE8;

    .MuiLinearProgress-barColorPrimary {
      background: linear-gradient(to right, #009688, #19C9B9);
    }
  }
`;

export const LoaderLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828D9A;
`;

export const BetaAlert = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #FCF2DB;
  border-radius: 6px;
`;

export const BetaHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BetaTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const BetaSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MonitorPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: #FFFFFF;
  border: 1px #DFDFE8 solid;
  border-radius: 8px;
  padding: 16px;
`;

export const MonitorHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const MonitorTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #828D9A;
`;

export const MonitorBody = styled.div`
  font-size: 32px;
  font-weight: 400;
`;