import React, { useCallback, useEffect, useMemo } from 'react';
import { MapView } from '@components/Scheduler/components/MapView';
import { geolocationRefetchPeriod, useActualRoutes, useETA } from '@hooks/geolocation';
import { parseLocation, parseUtcDate } from '@utils';
import { useDispatherTasks } from '@components/Scheduler/Dispatcher/useTasks';
import { useClientFilterState } from '@components/Scheduler/useClientFilterState';
import { useSelectedWorker } from '@components/Scheduler/Dispatcher/useSelectedWorker';
import { useSelectedDate } from '@components/Scheduler/Dispatcher/useSelectedDate';
import { groupBy, uniqBy } from 'lodash';
import { QueryParamsEnum, useAppSelector, useQueryParam, useToast } from '@hooks';
import { DateTime } from 'luxon';
import { usePlannedRoutes } from '@hooks/geolocation/usePlannedRoutes';
import { Geolocation, TaskStatus } from '@generated/types/graphql';
import { selectCompanySettings } from '@state/selectors';
import { defaultGeofencing } from '@features/Platform/GeolocationSettings';
import distance from '@turf/distance';
import { LngLatBounds } from 'react-map-gl';
import { DayPlans } from '@components/Scheduler/Dispatcher/Map/types';
import { ManualPointLayer } from '@components/Scheduler/Dispatcher/Map/ManualPointLayer';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { WorkersLayer } from './WorkersLayer';
import { Route, RoutesLayer } from './RoutesLayer';
import { TasksLayer } from './TasksLayer';
import { LayersButton } from './LayersButton';
import { MAX_VISIBLE_UNDISPATCHED_WO_ON_MAP, useUndispatchedTasks } from './useUndispatchedTasks';

export const Map = () => {
  const settings = useAppSelector(selectCompanySettings);
  const isGeoEnabled = !!settings?.features?.geolocation && !!settings?.geolocation?.enabled;
  const geofencingRadius = settings?.geolocation?.geofencing?.radius || defaultGeofencing.radius;
  const [, debouncedBounds, setBounds] = useDebouncedState<LngLatBounds | null>(null, 500);
  const [selectedRawDate] = useSelectedDate();
  const selectedDate = DateTime.fromJSDate(selectedRawDate);
  const isSelectedDateInFuture = selectedDate > DateTime.now() && !selectedDate.hasSame(DateTime.now(), 'day');
  const isSelectedDateInPast = selectedDate < DateTime.now() && !selectedDate.hasSame(DateTime.now(), 'day');
  const { showWarning } = useToast();

  const [currentView] = useQueryParam(QueryParamsEnum.CalendarViewType);

  const isLive = useMemo(
    () => isGeoEnabled && currentView === 'day' && DateTime.now().hasSame(selectedDate, 'day'),
    [currentView, selectedDate, isGeoEnabled]
  );

  const [selectedWorker] = useSelectedWorker();
  const [clientFilters, setClientFilters] = useClientFilterState();

  const {
    assignees = [],
    map: { showAllRoutes, showUndispatchedWorkOrders }
  } = clientFilters;

  const { filteredWorkOrders: tasks } = useDispatherTasks();
  const {
    tasks: undispatchedTasks,
    total: totalUndispatchedTasks,
    isFetching,
    isLoading
  } = useUndispatchedTasks({
    bounds: debouncedBounds,
    isEnabled: showUndispatchedWorkOrders
  });

  useEffect(() => {
    if (showUndispatchedWorkOrders && totalUndispatchedTasks > MAX_VISIBLE_UNDISPATCHED_WO_ON_MAP) {
      showWarning(
        `Only ${MAX_VISIBLE_UNDISPATCHED_WO_ON_MAP} out of ${totalUndispatchedTasks} undispatched Work Orders are displayed on the map. Use filters or zoom in to see others.`
      );
    }
  }, [showUndispatchedWorkOrders, totalUndispatchedTasks, showWarning]);

  const handleChangeShowAllRoutes = useCallback(
    (value: boolean) => {
      setClientFilters((prev) => ({ ...prev, map: { ...prev.map, showAllRoutes: value } }));
    },
    [setClientFilters]
  );

  const handleChangeShowScheduledWorkOrders = useCallback(
    (value: boolean) => {
      setClientFilters((prev) => ({ ...prev, map: { ...prev.map, showUndispatchedWorkOrders: value } }));
    },
    [setClientFilters]
  );

  const dispatchedTasksOnMap = useMemo(
    () =>
      tasks
        .map((task) => {
          const position = parseLocation(task.project.geoLocation);

          if (!position) {
            return null;
          }

          const selectedDateVisit = task.taskVisitsByTaskIdConnection.nodes.find((visit) =>
            DateTime.fromJSDate(parseUtcDate(visit.startDate)).hasSame(selectedDate, 'day')
          );

          if (assignees.length !== 0 && !assignees.some((id) => task.assignee?.id === id)) {
            return null;
          }

          return {
            ...task,
            startDate: selectedDateVisit ? parseUtcDate(selectedDateVisit.startDate) : task.startDate,
            endDate: selectedDateVisit ? parseUtcDate(selectedDateVisit.endDate) : task.endDate
          };
        })
        .filter(Boolean)
        .filter((task) => task.startDate || task.endDate),
    [tasks, assignees, selectedDate]
  );

  const taskAssignees = useMemo(
    () =>
      uniqBy(
        dispatchedTasksOnMap.map((task) => task.assignee),
        'id'
      ),
    [dispatchedTasksOnMap]
  );

  const { data: actualRoutes } = useActualRoutes(
    {
      userIds: taskAssignees.map(({ id }) => id),
      day: selectedDate
    },
    {
      enabled: isGeoEnabled,
      ...(isLive
        ? {
            refetchInterval: geolocationRefetchPeriod,
            refetchIntervalInBackground: true
          }
        : {})
    }
  );

  const lastLocations = useMemo<{ [userId: number]: Geolocation }>(
    () =>
      Object.entries(actualRoutes).reduce(
        (acc, [userId, route]) => ({ ...acc, [userId]: route.findLast(Boolean) }),
        {}
      ),
    [actualRoutes]
  );

  const firstLocations = useMemo<{ [userId: number]: Geolocation }>(
    () => Object.entries(actualRoutes).reduce((acc, [userId, route]) => ({ ...acc, [userId]: route[0] }), {}),
    [actualRoutes]
  );

  const shownActualRoutes = useMemo<Route[]>(
    () =>
      Object.entries(actualRoutes).map(([userId, geo]) => ({
        geometry: {
          type: 'LineString',
          coordinates: geo.map((location) => [location.longitude, location.latitude])
        },
        type: 'actual',
        selected: selectedWorker ? selectedWorker.id === +userId : null
      })),
    [actualRoutes, selectedWorker]
  );

  const { data: liveEstimate } = useETA(
    {
      userId: selectedWorker?.id
    },
    {
      enabled: isGeoEnabled && isLive && !!selectedWorker
    }
  );

  const dayPlans = useMemo(() => {
    const plans: DayPlans = groupBy(dispatchedTasksOnMap, (task) => task.assignee?.id);

    Object.keys(plans).forEach((userId) =>
      plans[+userId].sort(
        (t1, t2) => ((t1.startDate ?? t1.endDate) as Date).getTime() - ((t2.startDate ?? t2.endDate) as Date).getTime()
      )
    );

    return plans;
  }, [dispatchedTasksOnMap]);

  const { data: plannedRoutes } = usePlannedRoutes(
    {
      points: taskAssignees.reduce(
        (acc, { id }) => ({
          ...acc,
          [id]: [firstLocations[id] || null]
            .filter(Boolean)
            .map((geo) => ({ lat: geo.latitude, lng: geo.longitude }))
            .concat((dayPlans[id] || []).map((task) => parseLocation(task.project.geoLocation)).filter(Boolean))
        }),
        {}
      )
    },
    {
      enabled: isGeoEnabled && (isLive || isSelectedDateInFuture)
    }
  );

  const shownPlannedRoutes = useMemo<Route[]>(
    () =>
      Object.entries(plannedRoutes).map(([userId, route]) => ({
        geometry: route.geometry,
        type: 'planned',
        selected: selectedWorker ? selectedWorker.id === +userId : null
      })),
    [selectedWorker, plannedRoutes]
  );

  const tasksWithWorkersOnSite = useMemo(
    () =>
      dispatchedTasksOnMap.filter((task) => {
        if (!task.assignee) {
          return false;
        }

        if (isSelectedDateInFuture) {
          return task.id === dayPlans[task.assignee.id]?.[0]?.id;
        } else if (
          (isSelectedDateInPast && task.id === dayPlans[task.assignee.id]?.findLast(Boolean)?.id) ||
          [TaskStatus.ClockedIn, TaskStatus.ClockedOut].includes(task.taskStatus.id)
        ) {
          const lastPosition = lastLocations[task.assignee.id];
          const sitePosition = parseLocation(task.project.geoLocation);

          return (
            !!lastPosition &&
            !!sitePosition &&
            distance([lastPosition.longitude, lastPosition.latitude], [sitePosition.lng, sitePosition.lat], {
              units: 'feet'
            }) <= geofencingRadius
          );
        } else {
          return false;
        }
      }),
    [dispatchedTasksOnMap, lastLocations, geofencingRadius, dayPlans, isSelectedDateInFuture, isSelectedDateInPast]
  );

  const lastWorkersLocations = useMemo(
    () =>
      Object.entries(lastLocations)
        .filter(([userId]) => !tasksWithWorkersOnSite.find((task) => task.assignee.id === +userId))
        .map(([_, location]) => location),
    [lastLocations, tasksWithWorkersOnSite]
  );

  const shownRoutes = useMemo(() => {
    const all = [...shownActualRoutes, ...shownPlannedRoutes];

    if (showAllRoutes) {
      return all;
    }

    return all.filter((route) => route.selected);
  }, [shownActualRoutes, shownPlannedRoutes, showAllRoutes]);

  const shownTasks = useMemo(() => {
    if (showUndispatchedWorkOrders) {
      return [...dispatchedTasksOnMap, ...undispatchedTasks];
    }

    return dispatchedTasksOnMap;
  }, [dispatchedTasksOnMap, undispatchedTasks, showUndispatchedWorkOrders]);

  return (
    <MapView isLoading={isFetching || isLoading} live={isLive} onBoundsChange={setBounds}>
      <LayersButton
        showAllRoutes={showAllRoutes}
        onShowAllRoutesChange={handleChangeShowAllRoutes}
        showUndispatchedWorkOrders={showUndispatchedWorkOrders}
        onShowUndispatchedWorkOrdersChange={handleChangeShowScheduledWorkOrders}
      />
      <ManualPointLayer />

      <RoutesLayer routes={shownRoutes} />

      <TasksLayer
        tasks={shownTasks}
        estimate={liveEstimate}
        withWorkersOnSite={tasksWithWorkersOnSite}
        dayPlans={dayPlans}
        actualRoutes={actualRoutes}
        plannedRoutes={plannedRoutes}
      />

      <WorkersLayer
        workers={taskAssignees}
        locations={lastWorkersLocations}
        dayPlans={dayPlans}
        actualRoutes={actualRoutes}
        plannedRoutes={plannedRoutes}
      />
    </MapView>
  );
};
