import React, { useMemo, useState } from 'react';
import { ZapIcon } from '@kit/ui/icons/Zap';
import { Legend, PowerIcon, SelectWithArrow, Title } from './styled';
import { Widget, WidgetMetric, WidgetMetricLabel, WidgetRight } from '../styled';
import {
  ChartMilestone,
  chartMilestoneNames,
  chartMilestoneToChartPeriod,
  milestoneObservedInterval
} from '@hooks/systems';
import { colors } from '@styles';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ContextMenu } from '@kit/components/ContextMenu';
import { useSystemsProductionChart } from '@hooks/systems/useSystemsProductionChart';
import { formatEnergy, gapfillPoints } from '@features/SystemPortfolio/utils';
import { useSystemsUuids } from '@hooks/systems/useSystemsUuids';
import { EnergyChart } from '@features/SystemPortfolio/components/EnergyChart';

type Props = {
  allIds: number[];
};

export const ProductionWidget = ({ allIds }: Props) => {
  const [statsPeriod, setStatsPeriod] = useState<ChartMilestone>(ChartMilestone.yesterday);

  const dataPeriod = chartMilestoneToChartPeriod[statsPeriod];

  const { data: uuids } = useSystemsUuids(allIds);

  const statsInterval = milestoneObservedInterval(statsPeriod);

  const {
    data: { total, points }
  } = useSystemsProductionChart(uuids, dataPeriod, statsInterval?.start, statsInterval?.end);

  const gapfilledPoints = useMemo(
    () => gapfillPoints(points, dataPeriod, statsInterval),
    [points, dataPeriod, statsInterval]
  );

  return (
    <Widget>
      <Legend>
        <Title>
          <PowerIcon>
            <ZapIcon size="16px" color="#FFFFFF" />
          </PowerIcon>
          Performance
        </Title>

        <div>
          <WidgetMetric>{formatEnergy(total)}</WidgetMetric>
          <WidgetMetricLabel>energy produced</WidgetMetricLabel>
        </div>

        <ContextMenu
          items={Object.values(ChartMilestone).map((period) => ({
            title: chartMilestoneNames[period],
            onClick: () => setStatsPeriod(period)
          }))}
        >
          <SelectWithArrow>
            <div>{chartMilestoneNames[statsPeriod]}</div>
            <ChevronDownIcon size={16} color={colors.green} />
          </SelectWithArrow>
        </ContextMenu>
      </Legend>
      <WidgetRight>
        <EnergyChart points={gapfilledPoints} period={dataPeriod} enabled={{ production: true }} />
      </WidgetRight>
    </Widget>
  );
};
