import { useQuery } from 'react-query';
import {
  CompanyNotificationSetting,
  NotificationEntityDecisionTree,
  NotificationEventDecisionTree,
  Role,
  SingleDestination,
  Team
} from '@generated/types/graphql';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks/store';

export type NotificationEventSettings = Pick<
  CompanyNotificationSetting,
  'eventType' | 'entityType' | 'smsEnabled' | 'emailEnabled' | 'pushEnabled' | 'inboxEnabled' | 'enabled'
> & {
  singleReceivers: SingleDestination[];
  roleReceivers: Role[];
  teamReceivers: Team[];
};

export type CompanyNotificationEventSettings = {
  eventSchema: NotificationEventDecisionTree;
  settings: NotificationEventSettings;
};

export type CompanyNoficationSettingsTreeItem = Omit<NotificationEntityDecisionTree, 'events'> & {
  events: CompanyNotificationEventSettings[];
};

export const useCompanyNotifications = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery(
    [ReactQueryKey.WorkspaceNotificationsConfig, companyId],
    async () => {
      try {
        const { notificationDecisionTree, companyNotificationSettings } = await postGraphql<{
          notificationDecisionTree: NotificationEntityDecisionTree[];
          companyNotificationSettings: CompanyNotificationSetting[];
        }>(
          gql`
            query ($companyId: Int!) {
              notificationDecisionTree {
                entityType
                title
                description
                events {
                  eventType
                  always
                  title
                  description
                  receiver {
                    mention
                    owner
                    manager
                    salesRep
                    team
                    assignee
                    collaborator
                    role
                  }
                }
              }

              companyNotificationSettings(filter: { companyId: { equalTo: $companyId } }) {
                entityType
                eventType
                smsEnabled
                emailEnabled
                pushEnabled
                inboxEnabled
                enabled
                companyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyId {
                  target
                }
                companyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyId {
                  role {
                    id
                    name
                  }
                }
                companyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyId {
                  team {
                    id
                    name
                  }
                }
              }
            }
          `,
          { companyId }
        );

        const result: CompanyNoficationSettingsTreeItem[] = notificationDecisionTree
          .map((entity) => {
            const events = companyNotificationSettings
              .filter((setting) => setting.entityType === entity.entityType)
              .map((setting) => {
                const eventSchema = entity.events.find((event) => event.eventType === setting.eventType);

                if (!eventSchema) {
                  return null;
                }

                const settings: NotificationEventSettings = {
                  eventType: setting.eventType,
                  entityType: setting.entityType,
                  smsEnabled: setting.smsEnabled,
                  emailEnabled: setting.emailEnabled,
                  pushEnabled: setting.pushEnabled,
                  inboxEnabled: setting.inboxEnabled,
                  enabled: setting.enabled,
                  singleReceivers:
                    setting.companyNotificationSettingsSingleDestinationsByEntityTypeAndEventTypeAndCompanyId.map(
                      (single) => single.target
                    ),
                  roleReceivers:
                    setting.companyNotificationSettingsRoleDestinationsByEntityTypeAndEventTypeAndCompanyId.map(
                      (role) => role.role
                    ),
                  teamReceivers:
                    setting.companyNotificationSettingsTeamDestinationsByEntityTypeAndEventTypeAndCompanyId.map(
                      (team) => team.team
                    )
                };

                return {
                  eventSchema,
                  settings
                };
              })
              .filter(Boolean);

            return {
              ...entity,
              events
            };
          })
          .filter((entity) => entity.events.length > 0);

        return result;
      } catch (e) {
        throw apiErrorHandler('Error fetching notificationDecisionTree', e);
      }
    },
    {
      initialData: []
    }
  );
};
