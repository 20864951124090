import { Button, List, ListItemText, Checkbox as MuiCheckbox, ListItemIcon } from '@material-ui/core';
import styled from 'styled-components';
import { colors, scroll, tag } from '@styles';
import { X } from 'react-feather';

export const FilterButton = styled(Button)`
  && {
    display: block;
    content: 'Record';
    font:
      500 12px/14px Rubik,
      sans;
    white-space: nowrap;
    color: #828d9a;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #e8ecef;
    text-transform: capitalize;
    height: 34px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    margin: 4px;
    min-width: 108px;
  }
`;

export const SelectedCount = styled.span`
  font-weight: 500;
  color: #1d1d35;
`;

export const Menu = styled.div`
  && {
    .MuiTypography-body2 {
      font:
        500 12px/14px Rubik,
        sans;
    }

    padding: 8px;
    width: 280px;

    .MuiListItemIcon-root {
      min-width: 15px;
    }
  }
`;

export const GroupItemText = styled(ListItemText)`
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 8px;
`;

export const ItemIcon = styled(ListItemIcon)`
  margin-right: 8px;
`;

export const Checkbox = styled(MuiCheckbox)`
  && {
    margin-left: -8px;
    margin-top: -8px;
  }
`;

export const GroupContainer = styled.div`
  border-bottom: 1px solid #e4e8ec;
  padding-bottom: 16px;
  padding-top: 8px;

  .MuiListItemSecondaryAction-root {
    right: 0;
  }
`;

export const GroupItemsList = styled(List)`
  max-height: 160px;
  overflow: auto;
  ${scroll};

  .MuiListItem-dense {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
export const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-top: 5px;
`;

export const GroupSelectedCount = styled.div`
  padding: 2px 4px 2px 8px;
  border-radius: 20px;
  background-color: ${colors.greenBg};
  color: ${colors.green};
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const Remove = styled(X)`
  ${tag.remove};
`;
