import React, { useCallback, useMemo, useState } from 'react';
import { SearchBar } from '@common/SearchBar';
import { CompanyUser } from '@generated/types/graphql';
import { normalizeRoleName } from '@utils/roles';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Edit2, Plus } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { useUserList } from '@hooks/users/useUserList';
import { useDeleteUser } from '@hooks/users/useDeleteUser';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Avatar } from '@kit/ui/Avatar';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import {
  Container,
  Header,
  Title,
  Description,
  HeaderLeft,
  NameAndAvatar,
  PageHeader,
  Table,
  TableContainer
} from './styled';
import { UserForm } from '../Form';

interface RowProps {
  companyUser: CompanyUser;
}

export const TableRow = ({ companyUser }: RowProps) => {
  const { openModal } = useModal();
  const { mutateAsync: remove } = useDeleteUser();
  const confirmDelete = useConfirmDeleteModal();

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);

  const handleEdit = useCallback(() => {
    openModal<void>(({ onClose }) => <UserForm initialValues={companyUser} onClose={onClose} />, {
      title: 'Edit user'
    });
  }, [openModal, companyUser]);

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        title: 'Edit',
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        onClick: handleEdit
      },
      {
        title: 'Delete',
        icon: <TrashIcon size="16px" color="#D54855" />,
        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this user?')) {
            await remove(companyUser.user.id);
          }
        }
      }
    ];
  }, [confirmDelete, handleEdit, remove, companyUser]);

  const handleContextMenu = useCallback<React.MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();

    setContextMenuPosition({
      x: e.pageX,
      y: e.pageY
    });
  }, []);

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuPosition(null);
  }, []);

  return (
    <tr onClick={handleEdit} onContextMenu={handleContextMenu}>
      <td>
        <NameAndAvatar>
          <Avatar user={companyUser.user} />
          {companyUser.user.firstName} {companyUser.user.lastName}
        </NameAndAvatar>
      </td>
      <td>
        <EmailAddress value={companyUser.user.email} />
      </td>
      <td>
        <PhoneNumber value={companyUser.user.phone} />
      </td>
      <td className="no-break">{normalizeRoleName(companyUser.companyUserRoles[0]?.roles.name)}</td>
      <td className="no-break">{companyUser.isCollaborator ? 'Guest' : 'Member'}</td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu point={contextMenuPosition} items={menuItems} onClose={handleCloseContextMenu} />
      </td>
    </tr>
  );
};

export const List = () => {
  const [search, setSearch] = useState('');

  const { data: users = [] } = useUserList();

  const { openModal } = useModal();

  const handleInvite = useCallback(() => {
    openModal<void>(({ onClose }) => <UserForm onClose={onClose} />, { title: 'Invite user' });
  }, [openModal]);

  const filteredUsers = useMemo(() => {
    if (!search) {
      return users;
    }

    const normalizedSearch = search.toLowerCase();

    return users.filter((user) => {
      const fullName = `${user.user.firstName} ${user.user.lastName}`.toLowerCase();

      return (
        fullName.includes(normalizedSearch) ||
        user.user.email?.toLowerCase().includes(normalizedSearch) ||
        user.user.phone?.toLowerCase().includes(normalizedSearch)
      );
    });
  }, [search, users]);

  return (
    <Container>
      <PageHeader>
        <Title>Members</Title>
        <Description>Manage your users</Description>
      </PageHeader>

      <Header>
        <HeaderLeft>
          <Button onClick={handleInvite} variant={ButtonVariant.Primary}>
            <Plus size="16px" />
            Invite
          </Button>
          <SearchBar placeholder="Search..." onValueChange={setSearch} />
        </HeaderLeft>
      </Header>
      <TableContainer>
        <Table>
          <thead>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Role</th>
            <th>Status</th>
            <th />
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id} companyUser={user} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
