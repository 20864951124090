import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { SystemsConnection, SystemStatus } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';

export const useSystemsStatusTotal = (args: {
  ids: number[]
}) => useQuery(
  [ReactQueryKey.System, 'useSystemsStatusTotal', args],
  async () => {
    try {
      const { ids } = args;

      const data = await postGraphql<{ systemsConnection: SystemsConnection }>(
        gql`
          query ($ids: [Int!]!) {
            systemsConnection(
              filter: {
                id: {in: $ids}
              }
            ) {
              groupedAggregates(groupBy: [STATUS]) {
                keys
                distinctCount {
                  id
                }
              }
            }
          }
        `,
        { ids }
      );

      return data.systemsConnection.groupedAggregates.reduce((acc, agg) => ({
        ...acc,
        [agg.keys[0] as SystemStatus]: +agg.distinctCount.id
      }), {} as Record<SystemStatus, number>);
    } catch (e) {
      throw apiErrorHandler('Error fetching systems status', e);
    }
  },
  {
    keepPreviousData: true,
    initialData: {} as Record<SystemStatus, number>
  }
);