import React, { useCallback } from 'react';
import { FormControlLabel, Typography } from '@material-ui/core';
import { Switcher } from '@common/ui';
import { defaultTheme } from '@themes';
import { useClientFilterState } from '../useClientFilterState';

export const ArchivedToggle = () => {
  const {
    clientFilters: { isArchivedShown },
    updateFilters
  } = useClientFilterState();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateFilters({ isArchivedShown: event.target.checked });
    },
    [updateFilters]
  );

  return (
    <FormControlLabel
      className="archive-toggle-control"
      control={<Switcher checked={isArchivedShown} onChange={handleChange} />}
      label={
        <Typography
          style={{
            font: defaultTheme.typefaces.text,
            paddingRight: 7
          }}
        >
          Show archived
        </Typography>
      }
      labelPlacement="start"
    />
  );
};
