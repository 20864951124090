import React, { useEffect, useMemo, useState } from 'react';
import {
  ChartLegend,
  Container,
  Description,
  MiniPanel,
  MiniPanelBody,
  MiniPanels,
  MiniPanelTitle,
  PointTooltip,
  SelectBlock,
  SelectBlocks,
  SelectLabel,
  SelectOptions,
  Title,
  TooltipLabel,
  TooltipValue
} from './styled';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from 'recharts';
import { Integration, SyncFrequency } from '@generated/types/graphql';
import { useIntegrationStats } from '@hooks/integrations/useIntegrationStats';
import { AlertTriangle, Circle } from 'react-feather';
import { formatHits } from '@features/SystemPortfolio/utils';
import { formatMoney } from '@utils';
import { DateTime, Interval } from 'luxon';
import { capitalize, isEqual, isNumber } from 'lodash';
import { CheckboxGroupField, useForm } from '@kit/components/Form';
import { Select } from '@kit/ui/Select';
import { useController } from 'react-hook-form';
import {
  BetaAlert,
  BetaHeader,
  BetaSubtitle,
  BetaTitle
} from '@features/Platform/Fleet/Integrations/components/Settings/styled';
import { colors } from '@styles';

type Props = {
  integration: Integration;
};

export const EnphaseBudget = ({ integration }: Props) => {
  const now = DateTime.now();

  const { data: stats } = useIntegrationStats(integration.id);

  const {
    form: { control, watch, reset }
  } = useForm<SettingsForm>({
    onSubmit: async () => {},
    defaultValues: {
      ...mapSetupToSettings(Setup.optimal)
    }
  });

  const {
    field: { value: frequency, onChange: onChangeFrequency }
  } = useController<SettingsForm, 'frequency'>({ name: 'frequency', control });

  const [setup, setSetup] = useState(Setup.optimal);

  useEffect(() => {
    if (setup !== Setup.custom) {
      reset({
        ...mapSetupToSettings(setup)
      });
    }
  }, [setup, reset]);

  useEffect(() => {
    const subscription = watch((values) => {
      if (setup !== Setup.custom && !isEqual(values, mapSetupToSettings(setup))) {
        setSetup(Setup.custom);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setup]);

  const [hitsPerSystem, setHitsPerSystem] = useState({ history: 9, telemetry: 6 });
  watch((values) =>
    setHitsPerSystem({
      history: values.metrics?.length || 0,
      telemetry: (values.metrics?.length || 0) * (values.granularity?.length || 0)
    })
  );

  const [selectedMonth, setSelectedMonth] = useState(now);

  const dailyUsage = useMemo(
    () => makeDailyUsage(selectedMonth, +stats.total, hitsPerSystem),
    [selectedMonth, stats, hitsPerSystem]
  );

  const monthlyUsage = useMemo<MonthlyPoint[]>(() => {
    return Interval.fromDateTimes(now.startOf('month'), now.plus({ months: 12 }).startOf('month'))
      .splitBy({ month: 1 })
      .reduce((acc, interval, index) => {
        const month = interval.start;
        const prevTotalSystems = index > 0 ? acc[index - 1].totalSystems : +stats.total;

        const point: MonthlyPoint = {
          month,
          totalSystems: prevTotalSystems,
          totalHits: 0,
          [MonthlyBars.monitoredSystemsTotal]: null,
          [MonthlyBars.newSystemsTotal]: null
        };

        point.totalHits += point[MonthlyBars.monitoredSystemsTotal] = makeDailyUsage(
          month,
          prevTotalSystems,
          hitsPerSystem
        ).findLast(Boolean).totalHits;

        if (index > 0) {
          const newSystems = index > 0 ? +stats.medianMonthlyNew : 0;

          point.totalSystems += newSystems;
          point.totalHits += point[MonthlyBars.newSystemsTotal] = makeDailyUsage(
            month,
            newSystems,
            hitsPerSystem
          ).findLast(Boolean).totalHits;
        }

        acc.push(point);

        return acc;
      }, []);
  }, [stats, now, hitsPerSystem]);

  const selectedMonthHits = useMemo(() => dailyUsage.findLast(Boolean).totalHits, [dailyUsage]);

  const selectedMonthCost = useMemo(() => hitsCost(selectedMonthHits), [selectedMonthHits]);

  return (
    <Container>
      <Title>Budget & Usage</Title>

      <BetaAlert>
        <BetaHeader>
          <AlertTriangle size={24} color={colors.yellow} />
          <BetaTitle>Settings below are not applicable yet.</BetaTitle>
        </BetaHeader>
        <BetaSubtitle>
          This is still a work in progress. But it represents how we think about setting up Enphase monitoring according
          to Enpahse costs, and making an informed decision. As usual, early feedback is welcome!
        </BetaSubtitle>
      </BetaAlert>

      <Description>
        Below is projection of Coperniq usage of Enphase API hits assuming all your {stats.total} systems start to be
        monitored right now. Use it to select desired frequency and granularity, and satisfy Enphase limits and cost.
      </Description>

      <MiniPanels>
        <MiniPanel>
          <MiniPanelTitle>Total hits per month / Enphase monthly limit</MiniPanelTitle>
          <MiniPanelBody>
            {formatHits(selectedMonthHits)} / {formatHits(1_500_000)}
          </MiniPanelBody>
        </MiniPanel>
        <MiniPanel>
          <MiniPanelTitle>Expected bill from Enphase</MiniPanelTitle>
          <MiniPanelBody>{formatMoney(selectedMonthCost)}</MiniPanelBody>
        </MiniPanel>
        <MiniPanel>
          <MiniPanelTitle>Median new installations</MiniPanelTitle>
          <MiniPanelBody>{+stats.medianMonthlyNew} each month</MiniPanelBody>
        </MiniPanel>
      </MiniPanels>

      <SelectBlocks>
        <SelectBlock>
          <SelectLabel>Setup</SelectLabel>
          <Select
            options={[Setup.minimal, Setup.optimal, Setup.maximum]}
            value={setup}
            onChange={(_, value) => setSetup(value)}
            getOptionLabel={capitalize}
            isClearable={false}
          />
        </SelectBlock>

        <SelectBlock>
          <SelectLabel>Frequency</SelectLabel>
          <Select
            options={[SyncFrequency.Day]}
            value={frequency}
            onChange={onChangeFrequency}
            getOptionLabel={capitalize}
            isClearable={false}
          />
        </SelectBlock>

        <SelectBlock>
          <SelectLabel>Granularity</SelectLabel>
          <SelectOptions>
            <CheckboxGroupField options={granularityOptions} name="granularity" control={control} />
          </SelectOptions>
        </SelectBlock>

        <SelectBlock>
          <SelectLabel>Metrics</SelectLabel>
          <SelectOptions>
            <CheckboxGroupField options={metricsOptions} name="metrics" control={control} />
          </SelectOptions>
        </SelectBlock>
      </SelectBlocks>

      <ResponsiveContainer width="100%" height="100%" minWidth="200px" minHeight="200px">
        <BarChart data={dailyUsage}>
          <CartesianGrid vertical={false} stroke="#DFDFE8" strokeDasharray="2 2" />

          <XAxis
            type="category"
            dataKey={({ day }: DailyPoint) => day.toSeconds()}
            name="Day"
            tickLine={false}
            tickFormatter={(seconds: number) =>
              isNumber(seconds) && DateTime.fromSeconds(seconds).toLocaleString({ day: 'numeric', month: 'short' })
            }
            interval="preserveStartEnd"
            minTickGap={8}
            tick={{ fill: '#828D9A', fontSize: 10, fontWeight: 400 }}
            tickMargin={4}
            axisLine={{ stroke: '#DFDFE8' }}
          />

          <Tooltip<number, DailyBars>
            cursor={{ stroke: '#DFDFE8', fill: 'transparent' }}
            wrapperStyle={{ maxWidth: 400 }}
            // eslint-disable-next-line
            content={({ payload, label: seconds }) => (
              <PointTooltip>
                <TooltipLabel>
                  {isNumber(seconds) && DateTime.fromSeconds(seconds).toLocaleString(DateTime.DATE_MED)}
                </TooltipLabel>

                {payload.map((item) => (
                  <TooltipValue key={item.name}>
                    <Circle size={8} color={item.color} fill={item.fill} />
                    {dailyBarsConfig[item.name].desc}: {item.value} hits
                  </TooltipValue>
                ))}

                <TooltipValue>Total {formatHits(payload.reduce((acc, { value }) => acc + value, 0))} hits</TooltipValue>
              </PointTooltip>
            )}
          />

          {Object.values(DailyBars).map((bar) => (
            <Bar
              key={bar}
              dataKey={(point: DailyPoint) => point[bar]}
              stackId="main"
              name={bar}
              fill={dailyBarsConfig[bar].color}
              fillOpacity={1}
              isAnimationActive={false}
              barSize={24}
            />
          ))}

          <ReferenceLine y={1_500_000} isFront label="Enphase monthly limit" stroke="red" />

          <Legend align="left" verticalAlign="top" content={() => <ChartLegend>Daily breakdown</ChartLegend>} />
        </BarChart>
      </ResponsiveContainer>

      <ResponsiveContainer width="100%" height="100%" minWidth="200px" minHeight="200px">
        <BarChart data={monthlyUsage}>
          <CartesianGrid vertical={false} stroke="#DFDFE8" strokeDasharray="2 2" />

          <XAxis
            type="category"
            dataKey={({ month }: MonthlyPoint) => month.toSeconds()}
            name="Month"
            tickLine={false}
            tickFormatter={(seconds: number) =>
              isNumber(seconds) && DateTime.fromSeconds(seconds).toLocaleString({ month: 'short' })
            }
            interval="preserveStartEnd"
            minTickGap={8}
            tick={{ fill: '#828D9A', fontSize: 10, fontWeight: 400 }}
            tickMargin={4}
            axisLine={{ stroke: '#DFDFE8' }}
          />

          <Tooltip<number, MonthlyBars>
            cursor={{ stroke: '#DFDFE8', fill: 'transparent' }}
            wrapperStyle={{ maxWidth: 400 }}
            // eslint-disable-next-line
            content={({ payload, label: seconds }) => (
              <PointTooltip>
                <TooltipLabel>
                  {isNumber(seconds) &&
                    DateTime.fromSeconds(seconds).toLocaleString({ month: 'long', year: 'numeric' })}
                </TooltipLabel>

                {payload.map((item) => (
                  <TooltipValue key={item.name}>
                    <Circle
                      size={8}
                      color={monthlyBarsConfig[item.name].color}
                      fill={monthlyBarsConfig[item.name].color}
                    />
                    {monthlyBarsConfig[item.name].desc}: {item.value} hits
                  </TooltipValue>
                ))}

                <TooltipValue>
                  Projected cost {formatMoney(hitsCost(payload.reduce((acc, { value }) => acc + value, 0)))}
                </TooltipValue>
              </PointTooltip>
            )}
          />

          {Object.values(MonthlyBars).map((bar, index, bars) => (
            <Bar
              key={bar}
              dataKey={(point: MonthlyPoint) => point[bar]}
              stackId="main"
              name={bar}
              fillOpacity={1}
              isAnimationActive={false}
              barSize={24}
            >
              {index === bars.length - 1 && (
                <LabelList
                  dataKey={(point: MonthlyPoint) => formatMoney(hitsCost(point.totalHits))}
                  position="top"
                  style={{ color: '#828D9A', fontSize: 10, fontWeight: 400 }}
                />
              )}
              {monthlyUsage.map((point, index) => (
                <Cell
                  key={`cell-${index}`}
                  cursor="pointer"
                  fill={monthlyBarsConfig[bar].color}
                  filter={+point.month !== +selectedMonth.startOf('month') ? 'grayscale(0.7)' : ''}
                  onClick={() => setSelectedMonth(point.month)}
                />
              ))}
            </Bar>
          ))}

          <ReferenceLine y={1_500_000} isFront label="Enphase monthly limit" stroke="red" />

          <Legend align="left" verticalAlign="top" content={() => <ChartLegend>Monthly breakdown</ChartLegend>} />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

// hits -> dollars according to current Enphase pricing https://developer-v4.enphase.com/installer-plans
const hitsCost = (hits: number) => Math.max(0, hits - 10000) * 0.005;

enum DailyBars {
  previousTotal = 'previousTotal',
  monitoredSystemsHistory = 'initialHistory',
  monitoredSystemsTelemetry = 'monitoredSystemsTelemetry'
}

const dailyBarsConfig: { [bar in DailyBars]: { desc: string; color: string } } = {
  [DailyBars.previousTotal]: {
    desc: 'Accumulated past hits',
    color: 'lightgreen'
  },
  [DailyBars.monitoredSystemsHistory]: {
    desc: 'Current systems - first-time history sync',
    color: 'dodgerblue'
  },
  [DailyBars.monitoredSystemsTelemetry]: {
    desc: 'Current systems - regular sync',
    color: 'green'
  }
};

type DailyPoint = {
  day: DateTime;
  totalHits: number;
  [DailyBars.previousTotal]: number;
  [DailyBars.monitoredSystemsHistory]: number | null;
  [DailyBars.monitoredSystemsTelemetry]: number | null;
};

enum MonthlyBars {
  monitoredSystemsTotal = 'monitoredSystemsTotal',
  newSystemsTotal = 'newSystemsTotal'
}

const monthlyBarsConfig: { [bar in MonthlyBars]: { desc: string; color: string } } = {
  [MonthlyBars.monitoredSystemsTotal]: {
    desc: 'Current systems',
    color: 'orchid'
  },
  [MonthlyBars.newSystemsTotal]: {
    desc: 'Newly added systems',
    color: 'darkviolet'
  }
};

type MonthlyPoint = {
  month: DateTime;
  totalSystems: number;
  totalHits: number;
  [MonthlyBars.monitoredSystemsTotal]: number;
  [MonthlyBars.newSystemsTotal]: number | null;
};

const makeDailyUsage = (month: DateTime, systems: number, hitsPerSystem: { history: number; telemetry: number }) => {
  return Interval.fromDateTimes(month.startOf('month'), month.endOf('month'))
    .splitBy({ day: 1 })
    .reduce((acc, interval, index) => {
      const day = interval.start;

      const isToday = +day.hasSame(DateTime.now(), 'day');
      const isPast = +day < +DateTime.now() && !isToday;

      const prevTotalHits = index > 0 ? acc[index - 1].totalHits : 0;

      const point: DailyPoint = {
        day,
        totalHits: prevTotalHits,
        [DailyBars.previousTotal]: prevTotalHits || null,
        [DailyBars.monitoredSystemsHistory]: null,
        [DailyBars.monitoredSystemsTelemetry]: null
      };

      if (isPast) {
      } else {
        if (isToday) {
          point.totalHits += point[DailyBars.monitoredSystemsHistory] = systems * hitsPerSystem.history;
        } else {
          point.totalHits += point[DailyBars.monitoredSystemsTelemetry] = systems * hitsPerSystem.telemetry;
        }
      }

      acc.push(point);

      return acc;
    }, [] as DailyPoint[]);
};

enum Granularity {
  quarterMinute = 'quarterMinute',
  day = 'day'
}

const granularityOptions = [
  { value: Granularity.day, label: 'Day data' },
  { value: Granularity.quarterMinute, label: '15 minutes data' }
];

enum Metrics {
  production = 'production',
  consumption = 'consumption',
  battery = 'battery',
  export = 'export',
  import = 'import',
  power = 'power',
  devices = 'devices',
  summary = 'summary',
  activation = 'activation'
}

const metricsOptions = [
  { value: Metrics.production, label: 'Site production' },
  { value: Metrics.consumption, label: 'Site consumption' },
  { value: Metrics.battery, label: 'Battery state' },
  { value: Metrics.export, label: 'Export to the grid' },
  { value: Metrics.import, label: 'Import from the grid' },
  { value: Metrics.power, label: 'Site power' },
  { value: Metrics.devices, label: 'System devices and their statuses' },
  { value: Metrics.summary, label: 'System summary metadata' },
  { value: Metrics.activation, label: 'System activation metadata' }
];

enum Setup {
  minimal = 'minimal',
  optimal = 'optimal',
  maximum = 'maximum',
  custom = 'custom'
}

type SettingsForm = {
  frequency: SyncFrequency;
  granularity: Granularity[];
  metrics: Metrics[];
};

const mapSetupToSettings = (setup: Setup): SettingsForm => {
  switch (setup) {
    case Setup.minimal:
      return {
        frequency: SyncFrequency.Day,
        granularity: [Granularity.day],
        metrics: [Metrics.production, Metrics.consumption]
      };

    case Setup.optimal:
      return {
        frequency: SyncFrequency.Day,
        granularity: [Granularity.day],
        metrics: [
          Metrics.production,
          Metrics.consumption,
          Metrics.battery,
          Metrics.export,
          Metrics.import,
          Metrics.power
        ]
      };

    case Setup.maximum:
      return {
        frequency: SyncFrequency.Day,
        granularity: Object.values(Granularity),
        metrics: Object.values(Metrics)
      };

    case Setup.custom:
      return null;

    default:
      throw new Error(`Unrecognized setup value ${setup satisfies never}`);
  }
};
