import styled from 'styled-components';
import { Button } from '@kit/ui/Button';
import { Badge } from '@kit/ui/Badge';

export const Header = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Grid = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export const ProviderCard = styled.div`
  background-color: #ffffff;
  border: 1px #dfdfe8 solid;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  cursor: pointer;
  min-height: 216px;
  height: 216px;
  min-width: 355px;
  width: 355px;
`;

export const ProviderText = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

export const ProviderLogo = styled.div`
  display: flex;
  justify-content: center;
  max-width: 220px;
  max-height: 42px;
  width: 100%;
  height: 100%;
  
  * {
    width: 100%;
    height: 100%;
  }
`;

export const ProviderButton = styled(Button)`
  width: 100%;
`;

export const ProviderBadge = styled(Badge)`
  background-color: #cdf3df;
  color: #009a47;
`;
