import React from 'react';

import styled from 'styled-components';
import { fonts, colors } from '@styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 0 20px 10px 0;
  margin-bottom: 20px;
  
  font: 500 18px/1.25 ${fonts.sans};
  border-bottom: 1px solid ${colors.white3};
  border-radius: 4px 4px 0 0;

  background-color: ${colors.white};
`;

interface IHeader {
  title: string;
}

const Header: React.FC<IHeader> = (props: IHeader): JSX.Element => {
  const { title } = props;
  return (
    <Wrapper>
      <span>{title}</span>
    </Wrapper>
  );
};

export default Header;
