import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import querystring from 'querystring';
import { connect, ConnectedProps } from 'react-redux';
import { InputField, PrimaryButton } from '../UI';
import Props from './types';
import Loader from '../../common/Loader';
import { redirectLink, colors } from '../../../styles';
import {
  generateResetPasswordToken,
  resetPassword
} from '../../../services/AuthenticationServices/AuthenticationService';
import { resetPasswordResetState } from '../../../state/actions/authentication/authenticationAction';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 360px;
`;

const FormTitle = styled.h1`
  font-size: 30px;
  font-weight: normal;
  color: #252631;
  margin-bottom: 26px;
`;

const StyledLink = styled(Link)`
  ${redirectLink}
  float: right;
`;

const SuccessMessage = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.green};
  margin-top: 12px;
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.red};
  margin-top: 12px;
`;

const LinkWrapper = styled.div`
  width: 100;
  padding: 12px;
`;

const ForgotPassword: React.FC<IProps> = (props: IProps) => {
  const {
    loading,
    errorMessage,
    tokenExpirationTime,
    onGenerateResetPasswordToken,
    onResetPassword,
    onResetPasswordResetState
  } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [showVerifyPasswordError, setShowVerifyPasswordError] = useState(false);
  const [token, setToken] = useState('');

  const checkTokenAndEmail = () => {
    if (typeof window !== 'undefined') {
      const query = querystring.parse(window.location?.search?.substring(1));
      setEmail(!query?.email || query.email === 'undefined' ? '' : (query.email as string));
      setToken(!query?.token || query.token === 'undefined' ? '' : (query.token as string));
    }
  };

  useEffect(() => {
    checkTokenAndEmail();
  }, []);

  const onEmailChange = (event) => setEmail(event.target.value);

  const onPasswordChange = (event) => {
    const newValue = event.target.value;
    setShowVerifyPasswordError(verifyPassword.length > 0 && newValue !== verifyPassword);
    setPassword(event.target.value);
  };

  const onVerifyPasswordChange = (event) => {
    const newValue = event.target.value;
    setShowVerifyPasswordError(newValue !== password);
    setVerifyPassword(event.target.value);
  };

  const onBackToLoginClick = () => onResetPasswordResetState();

  const withToken = email !== '' && token !== '';
  let buttonText = 'Reset Password';
  if (tokenExpirationTime > 0) {
    buttonText = '✓';
  } else if (withToken) {
    buttonText = 'Change Password';
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const passwordMatch = verifyPassword === password;
    setShowVerifyPasswordError(!passwordMatch);
    if (passwordMatch) {
      // reset password
      if (withToken) {
        onResetPassword(email, token, password);

        return;
      }
      // generate reset password link
      onGenerateResetPasswordToken(email.trim().toLowerCase());
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Form method="post" onSubmit={handleSubmit}>
        <FormTitle>Password recovery</FormTitle>
        <InputField
          label="email"
          text="email"
          fn={onEmailChange}
          error={false}
          errorMessage=""
          value={email}
          disabled={withToken}
        />

        {withToken && (
          <>
            <InputField
              label="new password"
              text="new password"
              error={false}
              errorMessage=""
              fn={onPasswordChange}
              value={password}
              type="password"
            />
            <InputField
              label="verify password"
              text="verify password"
              error={showVerifyPasswordError}
              errorMessage="Password must match"
              fn={onVerifyPasswordChange}
              value={verifyPassword}
              type="password"
            />
          </>
        )}
        <PrimaryButton value={buttonText} disabled={tokenExpirationTime > 0 || showVerifyPasswordError} />

        {tokenExpirationTime > 0 && (
          <SuccessMessage>
            Thanks! You&apos;ll get an email with a link to reset your password shortly. The link is valid for{' '}
            {tokenExpirationTime} minutes.
          </SuccessMessage>
        )}

        {errorMessage && (
          <ErrorMessage
            dangerouslySetInnerHTML={{
              __html: errorMessage
            }}
          />
        )}

        <LinkWrapper>
          <StyledLink to="/authentication" onClick={onBackToLoginClick}>
            Back to login
          </StyledLink>
        </LinkWrapper>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  const { loading, error, tokenExpirationTime, errorMessage } = state.authentication;

  return {
    loading,
    error,
    tokenExpirationTime,
    errorMessage
  };
};

const mapDispatchToProps = {
  onGenerateResetPasswordToken: generateResetPasswordToken,
  onResetPassword: resetPassword,
  onResetPasswordResetState: resetPasswordResetState
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type IProps = PropsFromRedux & Props;

export default connector(ForgotPassword);
