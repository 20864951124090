import React, { Component } from 'react';
import BackDrop from '../../common/Backdrop';
import ConfirmationBody from './ConfimationBody/ConfirmationBody';
import styled from 'styled-components';
import { modal, device } from '../../../styles';

const StyledModal = styled.div`
  ${modal.modal};

  position: fixed;
  top: 0;
  left: 0;
  z-index: 130000;

  ${device.sm`
    height: 100vh;
    width: 100vw;
  `};
`;

let resolve;

interface IConfirmationService {
  cancelMessege?: string;
  confirmMessege?: string;
  messege?: string;
  isOpen?: boolean;
  style?: React.CSSProperties;
}

class ConfirmationService extends Component<IConfirmationService> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  handleCanceled = () => {
    this.setState({ isOpen: false });
    resolve(false);
  };

  handleConfirmed = () => {
    this.setState({ isOpen: false });
    resolve(true);
  };

  show = () => {
    this.setState({ isOpen: true });
    return new Promise((res) => {
      resolve = res;
    });
  };

  render() {
    return this.state.isOpen ? (
      <StyledModal>
        <BackDrop
          onClick={this.handleCanceled}
          isVisible={true}
          styles={{ zIndex: '13000' }}
        />
        <ConfirmationBody
          cancelMessege={this.props.cancelMessege}
          confirmMessege={this.props.confirmMessege}
          messege={this.props.messege}
          onCanceled={this.handleCanceled}
          onConfirmed={this.handleConfirmed}
        />
      </StyledModal>
    ) : null;
  }
}

export default ConfirmationService;
