import styled from 'styled-components';
import { colors, device, getColorWithOpacity, PROJECT_STAGE_COLORS, scroll } from '@styles';
import { ProjectStageType } from '@types';
import { Button } from '@common/ui';
import { AutomationsZap } from '@common/index';
import { MAIN_MENU_COLLAPSED_WIDTH, MAIN_MENU_WIDTH } from '@components/LayoutNew/MainMenu/constants';

export const BlueprintContainer = styled.div`
  padding: 0 0 100px 0;
  overflow: auto;
  background-color: ${colors.black};
  ${scroll};

  ${device.sm`
    height: calc(100vh - 124px);
    padding: 25px;
  `};
`;

export const BlueprintFlow = styled.div<{ vertical?: boolean }>`
  display: flex;
  padding-top: 30px;
  flex-direction: ${(props) => (props.vertical ? 'row' : 'column')};
  position: relative;
  min-height: 40px;

  > div {
    min-height: ${(props) => (props.vertical ? 'auto' : '150px')};
  }

  && {
    > button {
      top: 0;
      left: 0;
    }
  }
`;

export const BlueprintStage = styled.div`
  margin-bottom: 12px;
  display: flex;
`;

export const BlueprintStageTitleWrapper = styled.div`
  position: sticky;
  left: -25px;
  background-color: ${colors.black};
  z-index: 100;
`;

export const BlueprintStageSlicesWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

export const BlueprintStageTitle = styled.div<{ vertical: boolean }>`
  position: relative;
  font: 14px/1.25 ${({ theme }) => theme.fonts.sans};
  min-height: 100%;
  min-width: 40px;
  max-width: ${(props) => (props.vertical ? '40px' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  margin: 0 20px;

  &:before,
  &:after {
    content: '';
    width: 0;
    height: 0;
  }

  ${(props) =>
    props.vertical
      ? `
    &:before {
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 15px solid ${colors.black};
      position: absolute;
      top: 0;
    }
    &:after {
      border-left: 20px solid ${colors.black};
      border-right: 20px solid ${colors.black};
      border-top: 15px solid transparent;
      position: absolute;
      bottom: 0;
    }
  `
      : `
    &:before {
      left: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 15px solid ${colors.black};
      border-right: none;
    }
    &:after {
      right: 0;
      border-top: 20px solid ${colors.black};
      border-bottom: 20px solid ${colors.black};
      border-left: 15px solid transparent;
      border-right: none;
    }
  `}

  > span {
    white-space: nowrap;
    text-align: center;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: ${(props) => (props.vertical ? 'rotate(-90deg)' : 'none')};

    & + svg {
      position: static;
      z-index: 100;
    }
  }

  && {
    button {
      &:nth-child(1) {
        ${(props) =>
          props.vertical
            ? `
        top: 0;
      `
            : `
        left: -20px;
        top: 10px;
      `}
      }

      &:nth-child(2) {
        ${(props) =>
          props.vertical
            ? `
      
        `
            : `
          bottom: -20px;
          left: 5px;
        `}
      }

      &:nth-child(3) {
        ${(props) =>
          props.vertical
            ? `
          bottom: 0;
        `
            : `
          right: -20px;
          top: 10px;
        `}
      }
    }
  }
`;

export const BlueprintProjectStageTitle = styled(BlueprintStageTitle)<{ type: ProjectStageType; height: number }>`
  color: ${(props) => PROJECT_STAGE_COLORS[props.type]};
  background-color: ${(props) => getColorWithOpacity(PROJECT_STAGE_COLORS[props.type], 20)};

  > span {
    min-width: ${({ height }) => (height === 0 ? 100 : height * 60 + 40)}px;
  }
`;

export const BlueprintStageSlices = styled.div<{ type: ProjectStageType }>`
  display: flex;
  position: relative;
  z-index: 0;
  color: ${(props) => PROJECT_STAGE_COLORS[props.type]};
  background-color: ${(props) => getColorWithOpacity(PROJECT_STAGE_COLORS[props.type], 20)};
  border-radius: 4px;
  border: 2px solid ${(props) => PROJECT_STAGE_COLORS[props.type]};
  min-width: fit-content;
  min-height: 150px;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
      90deg,
      ${(props) => getColorWithOpacity(PROJECT_STAGE_COLORS[props.type], 20)} 0,
      ${(props) => getColorWithOpacity(PROJECT_STAGE_COLORS[props.type], 20)} 234px,
      transparent 234px,
      transparent 468px
    );
    left: 0;
    top: 0;
  }
`;

export const BlueprintSliceTasks = styled.div<{ fake?: boolean; type: ProjectStageType }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 10px 0;
`;

export const AddSlice = styled(Button)`
  && {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    position: absolute;
    bottom: 20px;
    right: -20px;
    background: none;
    border: 1px dashed #fff;
    padding: 0;
    cursor: pointer;
    z-index: 1000;

    svg {
      color: ${colors.white};
      margin: 0 -2px;
    }

    &:hover,
    &:focus {
      background: ${colors.white};
      svg {
        color: ${colors.gray};
      }
    }
  }
`;

export const Header = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Fields = styled.div`
  display: grid;
  grid-gap: 30px;

  ${device.sm`
    grid-template-columns: 1fr;
  `};
`;

export const Item = styled.div`
  ${device.sm`
    margin-bottom: 30px;
  `}
`;

export const TimesliceQuestionMark = styled.div`
  position: absolute;
  top: -30px;
  left: 25px;
`;

export const SlidePanelContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: ${colors.white};
  width: 650px;
  height: calc(100vh - 47px);
  box-shadow: -16px -16px 200px 0px #1d1d3552;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`;

export const FormSlidePanel = styled(SlidePanelContainer)<{ isMainMenuExpanded: boolean }>`
  background: #f7f7fc;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: ${({ isMainMenuExpanded }) =>
    `calc(100vw - ${isMainMenuExpanded ? MAIN_MENU_WIDTH : MAIN_MENU_COLLAPSED_WIDTH} - 133px)`};
  padding: 16px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
`;

const OuterButton = styled.div<{ isDisabled?: boolean }>`
  background-color: #f7f7fc;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const CloseButton = styled(OuterButton)`
  height: 40px;
  color: #9c9caa;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #1d1d35;
  }

  width: 40px;
`;

export const OuterButtons = styled.div`
  position: absolute;
  top: 24px;
  left: -40px;
  z-index: 5;
  gap: 8px;
  flex-direction: column;
  display: flex;
`;

export const ProjectStageAutomationsZap = styled(AutomationsZap)`
  position: absolute;
  top: 20px;
  left: 5px;
  z-index: 1001;
`;

export const AddAutomationButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 0;
  position: absolute;
  right: -23px;
  top: 50%;
  margin-top: -10px;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
`;

export const Breadcrumbs = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;
  color: ${colors.white};

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: ${colors.green};
    text-decoration: none;
  }
`;

export const AutomationFormContainer = styled.div`
  padding: 16px;
  overflow: auto;
  height: 100%;
  background-color: #f7f7fc;
  ${scroll};
`;
