import { Draggable } from 'devextreme-react/draggable';
import { slidebarArrowLeft as ArrowLeft, slidebarArrowRight as ArrowRight } from '@assets/svg';
import { useIntersectionObserver } from '@react-hookz/web/esm/useIntersectionObserver';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { capitalize, times } from 'lodash';
import { TaskSkeleton } from '@components/Calendar/CalendarView/Tray/TaskSkeleton';
import { SearchBar } from '@common/SearchBar';
import { PrivilegedTask, TaskStatus } from '@generated/types/graphql';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { AccountIcon } from '@common/icons/Account';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { BriefcaseIcon } from '@kit/ui/icons/Briefcase';
import { ClientTrayFilters } from '@components/Scheduler/useClientTrayFilterState';
import { CLIENT_STATUS_OPTIONS, PROJECT_STATUS_OPTIONS, REQUEST_STATUS_OPTIONS } from '@features/Analytics/constants';
import { SCHEDULER_DRAGGING_GROUP_ID } from '../../consts';
import { WorkOrder } from './WorkOrder';
import { Container, Header, Toggle, Filters, Title, SearchAndFilter } from './styled';
import { FilterWithGroups } from '../Filters/FilterWithGroups';
import { useFilterOptions } from '../Header/Header';
import { EventFilter } from '../Filters/EventFilter';

interface Props {
  tasks?: PrivilegedTask[];
  total?: number;
  isLoading: boolean;
  searchValue: string;
  onSearchChange: (value: string) => void;
  amountLabel: string;
  isCollapsed: boolean;
  onToggleIsCollapsed: () => void;
  onFetchNextPage: () => void;

  filters: ClientTrayFilters;
  onFiltersChange: (filters: Partial<ClientTrayFilters>) => void;
}

export const Tray = ({
  isCollapsed,
  onToggleIsCollapsed,
  amountLabel,
  tasks,
  total,
  isLoading,
  searchValue,
  onSearchChange,
  onFetchNextPage,
  filters,
  onFiltersChange
}: Props) => {
  const { data: filterOptions } = useFilterOptions();
  const { openDrawer } = useDrawersContext();

  const statuses = useMemo(() => {
    if (!filterOptions?.statuses) {
      return [];
    }

    return filterOptions.statuses
      .filter(({ id }) => ![TaskStatus.Completed, TaskStatus.Cancelled].includes(id))
      .map(({ id, label }) => ({ id, title: label }));
  }, [filterOptions?.statuses]);

  const loadingRef = useRef<HTMLDivElement>();

  const entry = useIntersectionObserver(loadingRef.current);

  useEffect(() => {
    if (entry?.isIntersecting && tasks.length < total) {
      onFetchNextPage();
    }
  }, [entry, tasks.length, total, onFetchNextPage]);

  const handleListDragStart = useCallback((e: any) => {
    e.cancel = true;
  }, []);

  const handleStatusesChange = useCallback(
    (filters: { [key: string]: TaskStatus[] }) => {
      onFiltersChange({ statuses: filters.statuses });
    },
    [onFiltersChange]
  );

  const handleTemplatesChange = useCallback(
    (filters: { [key: string]: number[] }) => {
      onFiltersChange({ templates: filters.templates });
    },
    [onFiltersChange]
  );

  const handleToggle = useCallback(() => {
    onToggleIsCollapsed();
  }, [onToggleIsCollapsed]);

  const handleTaskClick = useCallback(
    (task: PrivilegedTask) => {
      openDrawer(
        DrawerEntity.WORK_ORDER,
        task.id,
        tasks.map((task) => task.id)
      );
    },
    [openDrawer, tasks]
  );

  return (
    <Container>
      <Header>
        <div>
          <Title isRotated={isCollapsed}>
            {amountLabel}: {total}
          </Title>
        </div>
        <Toggle onClick={handleToggle} isCollapsed={isCollapsed}>
          <img src={isCollapsed ? ArrowRight : ArrowLeft} alt="" />
        </Toggle>
      </Header>

      <SearchAndFilter>
        <SearchBar value={searchValue} placeholder="Search..." onValueChange={onSearchChange} />

        <EventFilter
          label="Record"
          onSelect={onFiltersChange}
          groups={[
            {
              fieldName: 'clientStatuses',
              title: 'CLIENTS',
              options: CLIENT_STATUS_OPTIONS.map((option) => ({ ...option, title: capitalize(option.name) })) ?? [],
              icon: <AccountIcon size={16} color="#9C9CAA" />
            },
            {
              fieldName: 'requestStatuses',
              title: 'REQUESTS',
              options: REQUEST_STATUS_OPTIONS.map((option) => ({ ...option, title: capitalize(option.name) })) ?? [],
              icon: <DollarIcon size={16} color="#9C9CAA" />
            },
            {
              fieldName: 'projectStatuses',
              title: 'PROJECTS',
              options: PROJECT_STATUS_OPTIONS.map((option) => ({ ...option, title: capitalize(option.name) })) ?? [],
              icon: <BriefcaseIcon size={16} color="#9C9CAA" />
            }
          ]}
          selected={{
            clientStatuses: filters.clientStatuses,
            projectStatuses: filters.projectStatuses,
            requestStatuses: filters.requestStatuses
          }}
        />
      </SearchAndFilter>
      <Filters>
        <FilterWithGroups
          label="Status"
          fieldName="statuses"
          onSelect={handleStatusesChange}
          groups={[{ title: 'Status', options: statuses }]}
          selected={filters.statuses}
        />

        <FilterWithGroups
          label="Event"
          fieldName="templates"
          onSelect={handleTemplatesChange}
          groups={[
            {
              title: 'Office work order',
              options: filterOptions?.templates.filter((template) => !template.isField) || []
            },
            {
              title: 'Field work order',
              options: filterOptions?.templates.filter((template) => template.isField) || []
            }
          ]}
          selected={filters.templates}
        />
      </Filters>

      <Draggable id="tray" data="dropArea" group={SCHEDULER_DRAGGING_GROUP_ID} onDragStart={handleListDragStart}>
        {isLoading && times(8, (index) => <TaskSkeleton key={index} />)}

        {tasks.map((task: any) => (
          <Draggable key={task.id} group={SCHEDULER_DRAGGING_GROUP_ID} data={task} clone>
            <WorkOrder order={task} onClick={handleTaskClick} />
          </Draggable>
        ))}

        <div ref={loadingRef} />
      </Draggable>
    </Container>
  );
};
