import styled from 'styled-components';
import { colors, scroll, device } from '@styles';
import { Wrapper as ActionsWrapper } from '@components/templates/Workspace/common/Actions/styled';

export const Wrapper = styled.div`
  overflow-y: auto;
  ${scroll};
  border-top: 1px solid ${colors.gray3};
  margin-bottom: 10px;
  
  ${device.sm`
    max-height: calc(100vh - 415px);
  `}
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid ${colors.gray3};
  transition: 0.3s;
  cursor: pointer;
  
  ${ActionsWrapper} {
    opacity: 0;
  }
  
  &:hover, &:focus {
    background-color: ${colors.white2};
  }
  
  &:hover {
    ${ActionsWrapper} {
      opacity: 1;
    }  
  }
`;
