import { NoficationEventSettings } from '@hooks/workspace/notifications/useCompanyNotifications';

export const getDeliveryMethodsString = (settings: NoficationEventSettings) => {
  const methods = [];

  if (settings.inboxEnabled) {
    methods.push('Inbox');
  }

  if (settings.emailEnabled) {
    methods.push('Email');
  }

  if (settings.pushEnabled) {
    methods.push('Push');
  }

  if (settings.smsEnabled) {
    methods.push('SMS');
  }

  return methods.join(', ');
};
