import styled from 'styled-components';
import { fonts, colors, device } from '@styles';

export const Header = styled.div`
  padding: 24px 0 16px 0;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const PrivilegesHeader = styled.div`
  position: sticky;
  top: 48px;
  display: grid;
  gap: 0;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 50%;
  margin-left: auto;
  background-color: #f7f7fc;
  z-index: 10;
  padding: 4px 0;
`;

export const PrivilegeWrapper = styled.div`
  font: 14px/1.25 ${fonts.sans};
  text-align: center;
`;

export const Wrapper = styled.div`
  max-width: 700px;
  max-width: 700px;
`;

export const Category = styled.div`
  margin-bottom: 30px;
`;

export const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  font: 500 14px/1.25 ${fonts.sans};
  border-bottom: 1px solid #f2f4f6;
  height: 40px;
`;

export const Subtitle = styled.div`
  display: flex;
  align-items: center;
  font: 14px/1.25 ${fonts.sans};
  color: ${colors.gray};
  height: 40px;
  width: 50%;

  svg {
    color: ${colors.gray};
    margin-left: 10px;
    width: 16px;
    height: 16px;
    margin-top: -2px;
  }
`;

export const TooltipProperty = styled.div`
  font: 12px/1.25 ${fonts.sans};
`;

export const Items = styled.div``;

export const Item = styled.div`
  display: flex;
`;

export const Values = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  display: grid;
  gap: 0;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`;

export const Value = styled.div<{ active: boolean; available: boolean; activeHover: boolean; inactiveHover: boolean }>`
  position: relative;
  width: 100%;
  height: 20px;
  z-index: 1;

  ${(props) => !props.available && 'pointer-events: none;'}

  &:before {
    content: '';
    width: ${(props) => (props.available ? '20px' : '0')};
    height: 20px;
    border-radius: 20px;
    background-color: ${colors.gray3};
    position: absolute;
    left: calc(50% - 10px);

    cursor: ${(props) => (props.available ? 'pointer' : 'normal')};

    ${(props) => props.active && `background-color: ${colors.green}`};
    ${(props) => props.activeHover && `background-color: ${colors.green}`};
    ${(props) => props.inactiveHover && `background-color: ${colors.gray3}`};
  }
`;

export const ValueWrapper = styled.div<{
  active: boolean;
  available: boolean;
  activeLast: boolean;
  activeHover: boolean;
  inactiveHover: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;

  ${(props) => !props.available && 'pointer-events: none;'}

  &:before, &:after {
    content: '';
    position: absolute;
    width: 50%;
    height: 4px;
    background-color: ${colors.gray3};
    top: 8px;

    ${(props) => props.active && `background-color: ${colors.green};`};
    ${(props) => props.activeHover && `background-color: ${colors.green};`};
    ${(props) => props.inactiveHover && `background-color: ${colors.gray3};`};
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;

    ${(props) => props.activeLast && `background-color: ${colors.gray3};`}
  }

  &:first-child {
    &:before {
      display: none;
    }
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
`;

export const ModalFields = styled.div`
  display: grid;
  grid-gap: 30px;

  ${device.sm`
    grid-template-columns: 1fr;
  `};
`;

export const ModalItem = styled.div`
  ${device.sm`
    margin-bottom: 30px;
  `}
`;
