import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import { ReactQueryKey } from '@enums';
import systemsAPI from '@services/api/systemsAPI';

export const useMonitorAllSystems = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<void, Error, { integrationId: number; monitor: boolean }>(
    async ({ integrationId, monitor }) => {
      try {
        await systemsAPI.monitorAllSystems({ integrationId, monitor });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: async (_, { monitor }) => {
        showSuccess(`Monitoring integration ${monitor ? 'initiated' : 'cancelled'} successfully`);
        await queryClient.invalidateQueries([ReactQueryKey.System]);
        await queryClient.invalidateQueries([ReactQueryKey.Integration]); // because monitoring affects integration budget
      }
    }
  );
};
