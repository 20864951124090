import React, { useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { CheckboxGroupField, Form, FormValidationRules, useForm } from '@kit/components/Form';
import { useRoles } from '@hooks/useRoles';
import { normalizeRoleName } from '@utils/roles';
import { useTeams } from '@hooks/useTeams';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useToast } from '@hooks/useToast';
import { EventType, SingleDestination } from '@generated/types/graphql';
import { CompanyNotificationEventSettings } from '@hooks/workspace/notifications/useCompanyNotifications';
import { useUpdateCompanyNotification } from '@hooks/workspace/notifications/useUpdateCompanyNofification';
import { Asterisk, Body, ColumnTitle, Columns, Footer, Label, TextBlock } from './styled';
import { getSingleDestinationReceiversList, isEditableWoReceiversEvent, isReadOnlyReceieversEvent } from '../helpers';

interface FormValues {
  deliveryMethod: string[];
  singleDestinations: SingleDestination[];
  roles: string[];
  teams: string[];
}

const DELIVERY_METHODS = [
  { label: 'Inbox', value: 'inbox', settingsKey: 'inboxEnabled', isDisabled: true },
  { label: 'Email', value: 'email', settingsKey: 'emailEnabled' },
  { label: 'Push', value: 'push', settingsKey: 'pushEnabled' },
  { label: 'SMS', value: 'sms', settingsKey: 'smsEnabled' }
];

const RECEIVERS_FOR_WO: Record<
  EventType.AssigneeAddedOrUpdated | EventType.CollaboratorsAddedOrUpdated,
  { label: string; value: SingleDestination; isDisabled?: boolean }[]
> = {
  [EventType.AssigneeAddedOrUpdated]: [
    { label: 'Assignee', value: SingleDestination.Assignee, isDisabled: true },
    { label: 'Collaborators', value: SingleDestination.Collaborator }
  ],
  [EventType.CollaboratorsAddedOrUpdated]: [
    { label: 'Assignee', value: SingleDestination.Assignee },
    { label: 'Collaborators', value: SingleDestination.Collaborator, isDisabled: true }
  ]
};

const getDefaultValues = (event: CompanyNotificationEventSettings) => {
  return {
    deliveryMethod: DELIVERY_METHODS.filter((method) => event.settings[method.settingsKey]).map(
      (method) => method.value
    ),
    singleDestinations: event.settings.singleReceivers,
    roles: event.settings.roleReceivers.map((role) => role.id.toString()),
    teams: event.settings.teamReceivers.map((team) => team.id.toString())
  };
};
interface Props {
  event: CompanyNotificationEventSettings;
  onClose: () => void;
}

export const EventForm = ({ event, onClose }: Props) => {
  const isReadOnlyReceivers = isReadOnlyReceieversEvent(event.eventSchema.eventType);
  const isEditableWoReceivers = !isReadOnlyReceivers && isEditableWoReceiversEvent(event.eventSchema.eventType);
  const companyId = useAppSelector(selectWorkspaceId);
  const { mutateAsync: update } = useUpdateCompanyNotification();
  const {
    rolesQuery: { data: roles }
  } = useRoles();
  const { data: teams } = useTeams(companyId);

  const { showError } = useToast();

  const keyMembersOptions = useMemo(() => {
    const options = [
      {
        label: 'Owner',
        value: SingleDestination.Owner,
        isDisabled: [
          EventType.PortalMessagesReceived,
          EventType.ClientOwnerUpdated,
          EventType.ProjectOwnerUpdated,
          EventType.RequestOwnerUpdated,
          EventType.ReferralSubmitted,
          EventType.RequestCreatedInPortal
        ].includes(event.eventSchema.eventType)
      },
      {
        label: 'Project manager',
        value: SingleDestination.ProjectManager,
        isDisabled: [
          EventType.ProjectManagerUpdated,
          EventType.RequestPmUpdated,
          EventType.ReferralSubmitted,
          EventType.RequestCreatedInPortal
        ].includes(event.eventSchema.eventType)
      },
      {
        label: 'Sales rep',
        value: SingleDestination.SalesRep,
        isDisabled: [
          EventType.SalesRepAssignedOrUpdated,
          EventType.SalesRepUpdated,
          EventType.ReferralSubmitted,
          EventType.RequestCreatedInPortal
        ].includes(event.eventSchema.eventType)
      }
    ];

    return options;
  }, [event.eventSchema.eventType]);

  const rolesOptions = useMemo(() => {
    return roles?.map((role) => ({ label: normalizeRoleName(role.name), value: role.id.toString() })) || [];
  }, [roles]);

  const teamsOptions = useMemo(() => {
    return teams?.map((team) => ({ label: team.name, value: team.id.toString() })) || [];
  }, [teams]);

  const postForm = async (values: FormValues) => {
    if (!isReadOnlyReceivers && !values.roles?.length && !values.teams?.length && !values.singleDestinations?.length) {
      showError('At least one receiver should be selected');

      return;
    }

    await update({
      entityType: event.settings.entityType,
      eventType: event.settings.eventType,
      inboxEnabled: values.deliveryMethod.includes('inbox'),
      emailEnabled: values.deliveryMethod.includes('email'),
      pushEnabled: values.deliveryMethod.includes('push'),
      smsEnabled: values.deliveryMethod.includes('sms'),
      ...(isReadOnlyReceivers
        ? {}
        : {
            singleDestinations: values.singleDestinations,
            roleDestinations: values.roles.map(Number),
            teamDestinations: values.teams.map(Number)
          })
    });

    onClose();
  };

  const { form, handleSubmit } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: getDefaultValues(event)
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { control, watch } = form;

  const [selectedRoles = [], selectedTeams = []] = watch(['roles', 'teams']);

  const rules = useMemo<FormValidationRules<FormValues>>(() => {
    return {
      deliveryMethod: {
        isRequired: true
      }
    };
  }, []);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <CheckboxGroupField
          layout="row"
          name="deliveryMethod"
          label="Delivery methods"
          options={DELIVERY_METHODS}
          control={control}
        />

        {isReadOnlyReceivers && (
          <TextBlock>
            <Label>Receivers:</Label>
            <div>{getSingleDestinationReceiversList(event.settings)}</div>
          </TextBlock>
        )}

        {!isReadOnlyReceivers && isEditableWoReceivers && (
          <CheckboxGroupField
            layout="row"
            label="Receivers"
            name="singleDestinations"
            options={RECEIVERS_FOR_WO[event.eventSchema.eventType] ?? []}
            control={control}
          />
        )}

        {!isReadOnlyReceivers && !isEditableWoReceivers && (
          <>
            <TextBlock>
              <Label>
                Receivers <Asterisk>*</Asterisk>
              </Label>
            </TextBlock>

            <Columns>
              <div>
                <ColumnTitle>Key members:</ColumnTitle>

                <CheckboxGroupField label="" name="singleDestinations" options={keyMembersOptions} control={control} />
              </div>
              <div>
                <ColumnTitle>
                  Roles ({selectedRoles.length} of {roles.length} selected):
                </ColumnTitle>
                <CheckboxGroupField label="" name="roles" options={rolesOptions} control={control} />
              </div>
              <div>
                <ColumnTitle>
                  Teams ({selectedTeams.length} of {teams.length} selected):
                </ColumnTitle>
                <CheckboxGroupField label="" name="teams" options={teamsOptions} control={control} />
              </div>
            </Columns>
          </>
        )}
      </Body>
      <Footer>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button type="submit" variant={ButtonVariant.Primary}>
          Update
        </Button>
      </Footer>
    </Form>
  );
};
