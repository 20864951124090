import React from 'react';
import styled from 'styled-components';
import { fonts } from '@styles';

interface ISection {
  children?: React.ReactNode;
  title?: string;
  style?: React.CSSProperties;
}

const Wrapper = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.span`
  display: block;
  font: 14px/1.25 ${fonts.sans};
  color: #252631;
  margin-bottom: 8px;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
`;

const Section: React.FC<ISection> = ({
  title,
  children,
  style = {}
}): JSX.Element => {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <Body>{children}</Body>
    </Wrapper>
  );
};

export default Section;
