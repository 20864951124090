import React, { useCallback } from 'react';
import { useBlueprintMutations } from '@hooks';
import { Button } from '@components/templates/Workspace/Workflows/common/Button';
import { BlueprintTaskDTO, Form, TaskTemplate, AutomationFromApi } from '@types';
import { TaskAutomationsZap } from '@common';
import { Tooltip } from '@material-ui/core';
import { TooltipWrapper } from '@components/templates/common/InfoPanel/styled';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { Zap } from 'react-feather';
import { Trigger, AutomationStepType } from '@enums';
import { UserAvatar } from '@kit/components/UserAvatar';
import { AddAutomationButton, BlueprintTaskButton, Left, Right, TextWrapper, Wrapper } from './styled';
import { FormsList } from './FormsList/FormsList';

interface BlueprintTaskProps {
  task: BlueprintTaskDTO;
  fullTask: TaskTemplate;
  blueprintId: number;
  onClick: (task: BlueprintTaskDTO) => void;
  onAutomationClick: (automation: AutomationFromApi) => void;
  onFormClick: (form: Form) => void;
}

export const BlueprintTask = ({
  task,
  fullTask,
  blueprintId,
  onClick,
  onAutomationClick,
  onFormClick
}: BlueprintTaskProps) => {
  const { deleteTask } = useBlueprintMutations(blueprintId);

  const handleDeleteTask = async () => {
    await deleteTask.mutateAsync(task.id);
  };

  const handleClick = useCallback(() => {
    onClick(task);
  }, [task, onClick]);

  return (
    <Wrapper onClick={handleClick}>
      <BlueprintTaskButton
        color={task.task.isColored && task.task.labels?.length ? task.task.labels[0].color : undefined}
      >
        <Button onRemove={handleDeleteTask} />
        <Left>
          <WorkOrderTypeIcon isField={task.task.isField} />
          {task.task.title.length > 18 ? (
            <Tooltip title={<TooltipWrapper>{task.task.title}</TooltipWrapper>} placement="top" arrow>
              <TextWrapper>{task.task.title}</TextWrapper>
            </Tooltip>
          ) : (
            <TextWrapper>{task.task.title}</TextWrapper>
          )}
        </Left>
        <Right>
          {fullTask.assignee && ( // TODO check this when backend will return assignee
            <UserAvatar user={fullTask.assignee} size={16} />
          )}
          <FormsList forms={fullTask.forms} onFormClick={onFormClick} />
          <TaskAutomationsZap taskId={task.task.id} onAutomationClick={onAutomationClick} />
        </Right>

        <Tooltip title="Create automation" placement="top" arrow>
          <AddAutomationButton
            onClick={(e) => {
              e.stopPropagation();
              onAutomationClick({
                context: 'workOrderTemplate',
                steps: [
                  {
                    type: AutomationStepType.TRIGGER,
                    key: Trigger.TASK_COMPLETED,
                    fields: [{ key: 'taskId', value: task.task.id }]
                  }
                ]
              });
            }}
          >
            <Zap fill="#F7A902" color="#F7A902" size="16px" />
          </AddAutomationButton>
        </Tooltip>
      </BlueprintTaskButton>
    </Wrapper>
  );
};
