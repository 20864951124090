import React from 'react';
import { useAppSelector, useRoutes } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { IntegrationProvider } from '@generated/types/graphql';
import { Link } from 'gatsby';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { useIntegration } from '@hooks/integrations';
import { EnfakeLogo, EnphaseLogo } from '@assets/svg';
import { Breadcrumbs, Container, Header, HeaderLeft, Logo, Subtitle, Title } from './styled';
import { Form } from './Form';
import { IntegrationSettings } from '../components/Settings';
import { EnphaseBudget } from '@features/Platform/Fleet/Integrations/Enphase/Budget';

export const Enphase = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  const { location } = useRoutes();
  const isMock = new URLSearchParams(location.search).has('mock');

  const { data: integration } = useIntegration({
    companyId,
    provider: isMock ? IntegrationProvider.Enfake : IntegrationProvider.Enphase
  });

  return (
    <Container>
      <Breadcrumbs>
        <Link to="../">System Fleet</Link>
        <ChevronRightIcon size="16px" color="#828D9A" />
        <div>Enphase Integration</div>
      </Breadcrumbs>

      <Header>
        <HeaderLeft>
          <Title>Enphase Integration</Title>
          <Subtitle>
            Get all data for solar micro-inverters, battery energy storage, and EV charging stations for your customers
          </Subtitle>
        </HeaderLeft>
        <Logo>{isMock ? <img src={EnfakeLogo} alt="Enfake" /> : <img src={EnphaseLogo} alt="Enphase" />}</Logo>
      </Header>

      {integration ? (
        <IntegrationSettings integration={integration}>
          <EnphaseBudget integration={integration} />
        </IntegrationSettings>
      ) : (
        <Form mock={!!isMock} />
      )}
    </Container>
  );
};
