import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import integrationsAPI from '@services/api/integrationsAPI';
import { ReactQueryKey } from '@enums';

export const useDeleteIntegration = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<void, Error, number>(async (id: number) => {
    try {
      await integrationsAPI.deleteIntegration(id);
    } catch (error) {
      throw errorHandler(error);
    }
  }, {
    onSuccess: () => {
      showSuccess(`Integration deleted successfully`);
      queryClient.invalidateQueries([ReactQueryKey.Integration]);
    }
  });
}