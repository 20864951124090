import styled from 'styled-components';
import { fonts, colors } from '@styles';

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  font: 18px/21px ${fonts.sans};
  margin-bottom: 20px;

  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #f7f7fc;
  padding: 16px 0;
  width: 100%;
`;
export const Back = styled.div`
  color: ${colors.black};
  cursor: pointer;
  font-weight: 500;
`;
export const BreadcrumbTitle = styled.div`
  color: ${colors.gray};
  font-weight: 400;
  display: flex;
  align-items: center;

  svg {
    margin: 0 5px;
  }
`;
