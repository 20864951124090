import { fonts, dropdown } from '@styles';
import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

export const TooltipWrapper = styled.div`
  font-family: ${fonts.sans};
  padding: 12px 0px;
`;

export const TooltipAutomation = styled(MenuItem)`
  display: flex;
  align-content: space-between;
  && {
    ${dropdown.item};
    text-transform: none;
  }
`;

export const TooltipHeader = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 13px;
  padding-left: 7px;
`;

export const IconAndCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
`;
