import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { useInlineUpdateField } from '../useInlineUpdateField';
import { Container } from './styled';
import { CommonFieldProps } from '../types';
import TypeSelect from './TypeSelect';

export const TypeField = ({ onUpdated }: CommonFieldProps) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineUpdateField(onUpdated);
  const [isOpen, setIsOpen] = useState(true);
  const context = useFormikContext();

  useEffect(() => {
    setIsFocused(isEdit);
    if (isEdit) {
      setIsOpen(true);
    }
  }, [isEdit, setIsFocused]);

  const isField = context.values.type === 'field';

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <TypeSelect
          name="type"
          open={isOpen}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
            setIsEdit(false);
          }}
        />
      </div>
    );
  }

  return (
    <Container onClick={() => setIsEdit(true)}>
      <WorkOrderTypeIcon size="16px" isField={isField} />
      <div>{isField ? 'Field work order' : 'Office work order'}</div>
    </Container>
  );
};
