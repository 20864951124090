import { useQuery } from 'react-query';
import { useAppSelector } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { Role } from '@generated/types/graphql';

export const useRolesWithGeoSettings = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<Role[]>(
    [ReactQueryKey.WorkspaceRoles, companyId, 'useRolesWithGeoSettings'],
    async () => {
      try {
        return (
          await postGraphql<{ roles: Role[] }>(
            gql`
              query ($companyId: Int!) {
                roles(condition: { companyId: $companyId }) {
                  id
                  name
                  geolocationUserSettings {
                    id
                    enabled
                  }
                }
              }
            `,
            {
              companyId
            }
          )
        ).roles;
      } catch (e) {
        throw apiErrorHandler('Error fetching roles with geo settings', e);
      }
    },
    {
      enabled: !!companyId,
      initialData: [],
      keepPreviousData: true
    }
  );
};
