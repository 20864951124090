import styled from 'styled-components';

export const Body = styled.div`
  padding: 24px;
  min-width: 800px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px;
  background-color: #fff;
`;

export const Previews = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 24px;

  > * {
    flex: 1;
    align-self: stretch;

    display: flex;
    flex-direction: column;
  }
`;

export const PreviewLabel = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
`;

export const PreviewContainer = styled.div`
  border-radius: 16px;
  border: 1px solid #dfdfe8;
  background-color: #fff;
  padding: 12px;
  max-width: 372px;
  height: 100%;

  font-size: 14px;
  font-weight: 400;

  line-height: 20px;

  p {
    margin: 4px 0;
  }

  .variable {
    background-color: #cceae7;
    border-radius: 4px;
    color: #009688;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    padding: 1px 3px;
    white-space: nowrap;
  }
`;

export const Subject = styled.div`
  border-bottom: 1px solid #dfdfe8;
  padding: 0 12px 8px;
  margin: 0 -12px;
  font-weight: 500;
`;
