import { Button, List, ListItemText, Checkbox as MuiCheckbox } from '@material-ui/core';
import styled from 'styled-components';
import { colors, scroll, tag } from '@styles';
import { X } from 'react-feather';

export const FilterButton = styled(Button)`
  && {
    font: 400 12px/14px Rubik, sans;
    white-space: nowrap;
    color: #009688;
    text-transform: none;
    border: none;
    min-width: auto;
    margin: 4px;
  }
`;

export const SelectedCount = styled.span`
  margin-left: 2px;
`;

export const Menu = styled.div`
  && {
    .MuiTypography-body2 {
      font: 500 12px/14px Rubik, sans;
    }

    padding: 8px;
    width: 240px;

    .MuiListItemIcon-root {
      min-width: 15px;
    }
  }
`;

export const GroupItemText = styled(ListItemText)`
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 8px;
`;

export const Checkbox = styled(MuiCheckbox)`
  && {
    margin-left: -8px;
    margin-top: -8px;
  }
`;

export const GroupContainer = styled.div`
  border-bottom: 1px solid #e4e8ec;
  padding-bottom: 16px;
  padding-top: 8px;

  .MuiListItemSecondaryAction-root {
    right: 0;
  }
`;

export const GroupItemsList = styled(List)`
  max-height: 160px;
  overflow: auto;
  ${scroll};

  .MuiListItem-dense {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
export const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-top: 5px;
`;

export const GroupSelectedCount = styled.div`
  padding: 2px 4px 2px 8px;
  border-radius: 20px;
  background-color: ${colors.greenBg};
  color: ${colors.green};
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const Remove = styled(X)`
  ${tag.remove};
`;

export const Heading = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding: 9px 0;
  border-bottom: 1px solid #e8ecef;
`;
