import React from 'react';
import TextField from '@material-ui/core/TextField';
import { fieldToTextField, TextFieldProps } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
import { inputMui } from '@styles';

const styles = inputMui({ height: '40px' });
const useStyles = makeStyles(styles);

/**
 * @deprecated Ancient shit, do not use, make a field wrapper around text field yourself (or use ui/TextField if you
 * live a life of pain)
 */
const ModalTextField = (props: TextFieldProps) => {
  const {
    form: { setFieldValue },
    field: { name }
  } = props;

  const onChange = React.useCallback(
    (event) => {
      const { value } = event.target;
      setFieldValue(name, value);
    },
    [setFieldValue, name]
  );

  const classes = useStyles();

  return (
    <TextField
      classes={classes}
      {...fieldToTextField(props)}
      onChange={onChange}
    />
  );
};

export default ModalTextField;
