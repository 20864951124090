import styled from 'styled-components';
import { colors } from '@styles';

export const Container = styled.div`
  width: 60vw;
  max-width: 800px;

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
`;

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: ${colors.green};
    text-decoration: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const Logo = styled.div`
  max-width: 132px;

  * {
    width: 100%;
    height: 100%;
  }
`;