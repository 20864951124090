import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Select } from 'formik-material-ui';
import { ChevronDown } from 'react-feather';
import * as Yup from 'yup';

import { Modal, CancelButton, SubmitButton } from '@common/ModalMui';
import ModalTextField from '@common/ProjectEditor/FieldComponents/ModalTextField';
import { CoperniqColorsScheme as colorsScheme } from '@styles';
import { isInvalidString } from '@utils/validations';

import { Label as LabelType } from '../LabelsList/types';

import {
  Label,
  Fields,
  Item,
  MenuItem,
  Dot,
  useStylesSelect,
  useStylesInput
} from './styled';

interface LabelModalProps {
  open: boolean;
  onClose: () => unknown;
  onSubmit: (values: any) => void;
  type: string;
  selectedLabel: LabelType;
  checkIsUnique: (value?: string) => boolean;
}

const getValidationSchema = (isUniq: LabelModalProps['checkIsUnique'], selectedName?: string) =>
  Yup.object({
    name: Yup.string()
      .required('Label name is required!')
      .test(
        'empty-check',
        "Form name can't be blank",
        (value) => !isInvalidString(value)
      )
      .test('unique', 'Label already exists', (value) => selectedName === value || isUniq(value))
  });

export const LabelModal: React.FC<LabelModalProps> = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    type,
    selectedLabel,
    checkIsUnique,
  } = props;

  const classesSelect = useStylesSelect();
  const classesInput = useStylesInput();
  let initialValues = {
    id: -1,
    name: '',
    color: colorsScheme[0].color
  };

  if (type === 'edit') {
    initialValues = {
      id: selectedLabel.id,
      name: selectedLabel.name,
      color: selectedLabel.color
    };
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema(checkIsUnique, selectedLabel.name)}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => {
        return (
          <Modal
            open={open}
            onClose={onClose}
            title={type === 'create' ? 'Create new label' : 'Edit label'}
            footerButtons={[
              <CancelButton key="cancelButton" onClick={onClose} />,
              <SubmitButton
                key="submitButton"
                onClick={submitForm}
                text={type === 'create' ? 'Create' : 'Save'}
              />
            ]}
          >
            <Form>
              <Fields>
                <Item>
                  <Label htmlFor="name">Name</Label>
                  <Field
                    classes={classesInput}
                    component={ModalTextField}
                    name="name"
                    type="text"
                    placeholder="Name"
                    variant="outlined"
                    disabled={false}
                  />
                </Item>
                <Item>
                  <Label>Colors</Label>
                  <Field
                    classes={classesSelect}
                    component={Select}
                    name="color"
                    variant="outlined"
                    disabled={false}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }
                    }}
                    IconComponent={ChevronDown}
                  >
                    {colorsScheme.map((option) => (
                      <MenuItem value={option.color} key={option.id}>
                        <Dot color={option.color} />
                        {option.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Item>
              </Fields>
            </Form>
          </Modal>
        )
      }}
    </Formik>
  );
};
