import { useQuery } from 'react-query';
import {
  PortalNotificationEntityDecisionTree,
  PortalNotificationEventDecisionTree,
  CompanyNotificationSetting
} from '@generated/types/graphql';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks/store';

export type PortalNotificationEventSettings = Pick<
  CompanyNotificationSetting,
  'eventType' | 'entityType' | 'smsEnabled' | 'emailEnabled' | 'enabled'
>;

export type PortalNotificationEvent = {
  eventSchema: PortalNotificationEventDecisionTree;
  settings: PortalNotificationEventSettings;
};

export type PortalNoficationSettingsTreeItem = Omit<PortalNotificationEntityDecisionTree, 'events'> & {
  events: PortalNotificationEvent[];
};

export const usePortalNotifications = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery([ReactQueryKey.WorkspacePortalNotificationsConfig, companyId], async () => {
    try {
      const { portalNotificationDecisionTree, companyNotificationSettings } = await postGraphql<{
        portalNotificationDecisionTree: PortalNotificationEntityDecisionTree[];
        companyNotificationSettings: CompanyNotificationSetting[];
      }>(
        gql`
          query ($companyId: Int!) {
            portalNotificationDecisionTree {
              entityType
              title
              description
              events {
                eventType
                always
                title
                description
                smsTemplate
                emailTemplate {
                  subject
                  body
                }
              }
            }

            companyNotificationSettings(filter: { companyId: { equalTo: $companyId } }) {
              entityType
              eventType
              smsEnabled
              emailEnabled
              enabled
            }
          }
        `,
        { companyId }
      );

      const result: PortalNoficationSettingsTreeItem[] = portalNotificationDecisionTree
        .map((entity) => {
          const events = companyNotificationSettings
            .filter((setting) => setting.entityType === entity.entityType)
            .map((setting) => {
              const eventSchema = entity.events.find((event) => event.eventType === setting.eventType);

              if (!eventSchema) {
                return null;
              }

              const settings: PortalNotificationEventSettings = {
                eventType: setting.eventType,
                entityType: setting.entityType,
                smsEnabled: setting.smsEnabled,
                emailEnabled: setting.emailEnabled,
                enabled: setting.enabled
              };

              return {
                eventSchema,
                settings
              };
            })
            .filter(Boolean);

          return {
            ...entity,
            events
          };
        })
        .filter((entity) => entity.events.length > 0);

      return result;
    } catch (e) {
      throw apiErrorHandler('Error fetching Portal Notification settings', e);
    }
  });
};
