import styled from 'styled-components';
import { scroll } from '@styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  border: solid 1px #dfdfe8;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #1d1d35;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const PointTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #ffffff;
  border: #f7f7fc solid 1px;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
  width: 100%;
`;

export const TooltipLabel = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;

export const TooltipValue = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
`;

export const MiniPanels = styled.div`
  display: flex;
  gap: 24px;
`;

export const MiniPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: #ffffff;
  border: 1px #dfdfe8 solid;
  border-radius: 8px;
  padding: 16px;
`;

export const MiniPanelTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
`;

export const MiniPanelBody = styled.div`
  font-size: 18px;
  font-weight: 400;
`;

export const ChartLegend = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const SelectBlocks = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

export const SelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const SelectLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #1d1d35;
`;

export const SelectOptions = styled.div`
  max-height: 106px; // supposed to be 4 lines
  overflow: auto;
  ${scroll};
`;
