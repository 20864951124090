import { taskUpdate } from '@services/KanbanServices/validations';
import React, { useState } from 'react';
import { Formik } from 'formik';
import { taskToFormDataAdapter, formToTaskDTO } from '@adapters/TaskModalAdapter';
import { Form } from '@common/index';
import { XIcon } from '@kit/ui/icons/X';
import { useDrawerOpenState } from '@components/LayoutNew/MainMenu/useDrawerOpenState';
import { FormBuilderContainer } from '@features/Platform/Templates/FormBuilder/FormBuilder';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { convertFromTemplateMemberIdToPropertyId } from '@utils/properties';
import { debounce } from 'lodash';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { TitleField } from './Fields/Title';
import {
  Header,
  Title,
  Body,
  Footer,
  Container,
  FieldBlock,
  FieldLabel,
  GeneralInfo,
  OuterButtons,
  CloseButton,
  FormSlidePanel
} from './styled';
import { PriorityField } from './Fields/Priority';
import { CollaboratorsField } from './Fields/Collaborators';
import { LabelsField } from './Fields/Labels';
import { DescriptionField } from './Fields/Description';
import { ChecklistField } from './Fields/Checklist';
import { FormsField } from './Fields/Forms';
import { TypeField } from './Fields/Type';
import { AssigneeField } from './Fields/Assignee';
import { useTaskTemplates } from '../hooks';

interface Props {
  onCreated: (values: any) => void;
  onClose: () => void;
  initialValues?: any;
}

const normalizeApiPayload = (values: any) => {
  const template = formToTaskDTO(values);

  const payload = {
    ...template,
    ...(template.templateUsers
      ? { templateUsers: template.templateUsers.map((user) => (user?.id ? user?.id : user)) }
      : {})
  };

  return payload;
};

const schema = taskUpdate();

export const TaskTemplateForm = ({ initialValues, onCreated, onClose }: Props) => {
  const isEdit = initialValues?.id > 0;
  const [selectedForm, setSelectedForm] = useState<any | null>(null);
  const [formIdToReplace, setFormIdToReplace] = useState(null);
  const queryClient = useQueryClient();

  const { create: createTemplate, update: updateTemplate } = useTaskTemplates();

  const onUpdated = debounce(async (values: any) => {
    if (!isEdit) {
      return;
    }

    const payload = normalizeApiPayload(values);

    const { id } = payload;

    queryClient.setQueriesData([ReactQueryKey.WorkOrderTemplateList, id], (data) => {
      if (!data) {
        return data;
      }

      const updates = {
        ...values
      };

      if (values.assignee) {
        if (values.assignee.id < 0) {
          updates.assignee = null;
          updates.templateAssigneeProperty = {
            ...values.assignee,
            id: convertFromTemplateMemberIdToPropertyId(values.assignee.id)
          };
        }
      } else {
        updates.assignee = null;
        updates.templateAssigneeProperty = null;
      }

      if (values.assignees?.length) {
        updates.templateUsers = values.assignees
          .filter((assignee: any) => assignee.id > 0)
          .map((assignee: any) => {
            return {
              ...assignee,
              id: convertFromTemplateMemberIdToPropertyId(assignee.id)
            };
          });

        updates.templateProperties = values.assignees
          .filter((assignee: any) => assignee.id < 0)
          .map((assignee: any) => {
            return {
              ...assignee,
              id: convertFromTemplateMemberIdToPropertyId(assignee.id)
            };
          });
      } else {
        updates.templateUsers = [];
        updates.templateProperties = [];
      }

      return {
        ...data,
        ...updates,
        isField: updates.type === 'field'
      };
    });
    await updateTemplate.mutateAsync({
      id,
      req: payload
    });

    queryClient.invalidateQueries([ReactQueryKey.WorkOrderTemplateList]);
    queryClient.invalidateQueries([ReactQueryKey.WorkspaceBlueprints]);
  }, 300);

  const handleCreate = async (values: any) => {
    if (isEdit) {
      onUpdated(values);

      return;
    }
    const payload = normalizeApiPayload(values);

    const result = await createTemplate.mutateAsync(payload);

    queryClient.invalidateQueries([ReactQueryKey.WorkOrderTemplateList]);
    queryClient.invalidateQueries([ReactQueryKey.WorkspaceBlueprints]);

    onCreated?.(result);
    onClose?.();
  };

  const handleCreateNewForm = (formIdToReplace?: number) => {
    if (formIdToReplace) {
      setFormIdToReplace(formIdToReplace);
    }
    setSelectedForm({});
  };

  const [isDrawerOpen] = useDrawerOpenState();

  return (
    <Formik
      initialValues={taskToFormDataAdapter(initialValues as any)}
      validationSchema={schema}
      onSubmit={handleCreate}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Container>
          <Form>
            <Header>
              <Title>{initialValues?.id > 0 ? 'Edit' : 'Create'} work order template</Title>
            </Header>
            <Body isEdit={isEdit}>
              <TitleField onUpdated={onUpdated} />
              <GeneralInfo>
                <FieldBlock centered>
                  <FieldLabel>Type</FieldLabel>
                  <TypeField onUpdated={onUpdated} />
                </FieldBlock>
                <FieldBlock centered>
                  <FieldLabel>Priority</FieldLabel>
                  <PriorityField onUpdated={onUpdated} />
                </FieldBlock>

                <FieldBlock>
                  <FieldLabel fixPadding>Assignee</FieldLabel>
                  <AssigneeField onUpdated={onUpdated} />
                </FieldBlock>

                <FieldBlock>
                  <FieldLabel fixPadding>Collaborators</FieldLabel>
                  <CollaboratorsField onUpdated={onUpdated} />
                </FieldBlock>

                <FieldBlock>
                  <FieldLabel fixPadding>Labels</FieldLabel>
                  <LabelsField onUpdated={onUpdated} />
                </FieldBlock>

                <FieldBlock block>
                  <FieldLabel>Instructions</FieldLabel>
                  <DescriptionField onUpdated={onUpdated} />

                  <ChecklistField onUpdated={onUpdated} />
                </FieldBlock>

                <FieldBlock block>
                  <FieldLabel>Forms</FieldLabel>
                  <FormsField
                    onUpdated={onUpdated}
                    onFormClick={setSelectedForm}
                    onCreateNewForm={handleCreateNewForm}
                  />
                </FieldBlock>
              </GeneralInfo>
            </Body>
            {!isEdit && (
              <Footer>
                <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
                  Save
                </Button>
                <Button variant={ButtonVariant.Secondary} onClick={onClose}>
                  Cancel
                </Button>
              </Footer>
            )}
          </Form>

          {selectedForm && (
            <FormSlidePanel isMainMenuExpanded={isDrawerOpen}>
              <OuterButtons>
                <CloseButton onClick={() => setSelectedForm(null)}>
                  <XIcon size="24px" />
                </CloseButton>
              </OuterButtons>

              <FormBuilderContainer
                formId={selectedForm?.id ?? 'new'}
                onSave={(newForm?: any) => {
                  // if new form was created we need to add it to the template form list
                  if (newForm) {
                    let forms;

                    if (formIdToReplace) {
                      forms = values.forms.map((form: any) => {
                        if (form.id === formIdToReplace) {
                          return newForm;
                        }

                        return form;
                      });
                    } else {
                      forms = [...values.forms, newForm];
                    }

                    if (isEdit) {
                      onUpdated({
                        ...values,
                        forms
                      });
                    }

                    setFormIdToReplace(null);

                    setFieldValue('forms', forms);
                  }
                  setSelectedForm(null);
                }}
              />
            </FormSlidePanel>
          )}
        </Container>
      )}
    </Formik>
  );
};
