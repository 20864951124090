import 'mapbox-gl/dist/mapbox-gl.css';
import React, { PropsWithChildren } from 'react';
import { Map, MapProps, MapRef } from 'react-map-gl';
import { merge } from 'lodash';
import { defaultTheme } from '@themes';
import { config } from '../../../config';

type Props = PropsWithChildren<MapProps>;

export const MapboxMap = React.forwardRef<MapRef, Props>(({ children, ...props }, ref) => (
  <Map
    ref={ref}
    mapboxAccessToken={config.mapboxAccessToken}
    reuseMaps
    mapStyle="mapbox://styles/mapbox/light-v10"
    attributionControl={false}
    {...props}
    style={merge({
      fontFamily: defaultTheme.fonts.sans
    } as React.CSSProperties, props.style)}
  >
    {children}
  </Map>
));
