import React from 'react';
import { Checkbox } from '@kit/ui/Checkbox';
import { Select } from '@kit/ui/Select';
import { PER_PAGE_OPTIONS } from '@features/ClientPortfolio/constants';
import { PerPage, SelectionAndPerPage, Table, TitleCell } from './styled';
import { useColumns } from '../useColumns';
import { SelectionControl } from './SelectionControl';
import { useClientFilterState } from '../../useClientFilterState';

interface Props {
  isAllSelected: boolean;
  onToggleAllSelected: () => void;
  selectedIds: number[];
  clearSelection: () => void;
  isAllSelectedArchived: boolean;
  isGroupedData?: boolean;
}

export const TableHeader = ({
  selectedIds,
  clearSelection,
  isAllSelectedArchived,
  isAllSelected,
  onToggleAllSelected,
  isGroupedData = false
}: Props) => {
  const columns = useColumns();

  const { clientFilters, updateFilters } = useClientFilterState();

  return (
    <>
      <SelectionAndPerPage>
        <div>
          {selectedIds.length > 0 && (
            <SelectionControl
              selectedIds={selectedIds}
              clearSelection={clearSelection}
              isAllSelectedArchived={isAllSelectedArchived}
            />
          )}
        </div>
        <PerPage>
          <div>Show</div>
          <Select
            options={PER_PAGE_OPTIONS}
            value={clientFilters.perPage}
            onChange={(_, value) => updateFilters({ perPage: value })}
            isClearable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
          <div>requests per {isGroupedData ? 'group' : 'page'}</div>
        </PerPage>
      </SelectionAndPerPage>
      <Table>
        <thead>
          <tr>
            <TitleCell>
              <Checkbox isChecked={isAllSelected} onChange={onToggleAllSelected} />
              Title
            </TitleCell>
            {columns.map((column) => (
              <th key={column.id}>{column.name}</th>
            ))}
          </tr>
        </thead>
      </Table>
    </>
  );
};
