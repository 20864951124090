import { Integration } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';

export const useIntegrations = (args: { companyId: number }) =>
  useQuery<Integration[]>(
    [ReactQueryKey.Integration, 'useIntegrations', args],
    async () => {
      try {
        return (
          await postGraphql<{ integrations: Integration[] }>(
            gql`
              query ($companyId: Int!) {
                integrations(condition: { companyId: $companyId }) {
                  id
                  companyId
                  provider
                  frequency
                }
              }
            `,
            args
          )
        ).integrations;
      } catch (e) {
        throw apiErrorHandler('Error fetching integration', e);
      }
    },
    {
      initialData: []
    }
  );
