import React, { useCallback, useEffect, useState } from 'react';
import { Upload, UploadProps } from 'antd';

import { Loader } from '@kit/ui/Loader';
import { Edit2, File, Upload as UploadIcon } from 'react-feather';
import { colors } from '@styles';
import { useUpdateCompany } from '@hooks/useUpdateCompany';
import { selectCompanyById, selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks/store';
import { uploadSingleFile } from '@services/UploadServices/uploadService';
import { useToast } from '@hooks/useToast';
import { H2, Description } from '../styled';
import {
  CompanyLogoImg,
  DropZoneContainer,
  DropZoneContent,
  IconInsideImage,
  LoaderContainer,
  LogoContainer
} from './styled';

const { Dragger } = Upload;

const MAX_FILE_SIZE_MB = 10;

export const CompanyLogo = () => {
  const { showError } = useToast();

  const companyId = useAppSelector(selectWorkspaceId);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));

  const [isUploading, setIsUploading] = useState(false);
  const { mutateAsync: updateCompany } = useUpdateCompany();

  const [logoUrl, setLogoUrl] = useState<string | null>(company?.logoUrl);

  useEffect(() => {
    if (company?.logoUrl) {
      setLogoUrl((prev) => prev || company.logoUrl);
    }
  }, [company?.logoUrl]);

  const uploadFile = useCallback(
    async (file: File) => {
      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', file);

      const result = await uploadSingleFile(formData, 'company', companyId);

      const newLogoUrl = result?.data?.downloadUrl;

      await updateCompany({ logoUrl: newLogoUrl });

      setLogoUrl(newLogoUrl);
      setIsUploading(false);
    },
    [companyId, updateCompany]
  );

  const uploadProps: UploadProps = {
    multiple: false,
    showUploadList: false,
    beforeUpload: async (file) => {
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        // 350mb
        showError(`Maximum file size is ${MAX_FILE_SIZE_MB} MB`);

        return false;
      }

      uploadFile(file);

      return true;
    }
  };

  /* const handleDeleteLogo = useCallback(async () => {
          await updateCompany({ logoUrl: null })
          }, [updateCompany]) */

  const hasLogo = !!logoUrl;

  return (
    <div>
      <H2>Company logo</H2>
      <Description>Your logo will be displayed in the upper left corner of the Platform and in the Portal.</Description>

      {isUploading && (
        <LoaderContainer>
          <Loader size={64} />
        </LoaderContainer>
      )}
      {!isUploading && hasLogo && (
        <Dragger {...uploadProps}>
          <LogoContainer>
            <CompanyLogoImg src={logoUrl} />
            <IconInsideImage>
              <Edit2 size="20px" color="#fff" />
            </IconInsideImage>
          </LogoContainer>
        </Dragger>
      )}

      {!isUploading && !hasLogo && (
        <DropZoneContainer>
          <Dragger {...uploadProps}>
            <DropZoneContent>
              <UploadIcon size="16px" color={colors.green} />
              <span>
                drag & drop a file here or <strong>browse</strong>
              </span>
            </DropZoneContent>
          </Dragger>
        </DropZoneContainer>
      )}
    </div>
  );
};
