import React from 'react';
import { Label } from '@components/common/Tags/Label';
import Props from './types';
import { Wrapper, Item } from './styled';
import { Actions } from '../../common/Actions';

export const LabelsList: React.FC<Props> = (props: Props) => {
  const {
    data,
    handleEditLabel,
    handleRemoveLabel
  } = props;

  return (
    <Wrapper>
      {data.map((item) => {
        return (
          <Item
            key={item.id}
          >
            <Label type={item.type} color={item.color}>
              {item.name}
            </Label>
            <Actions
              name="label"
              onEdit={() => handleEditLabel(item.id)}
              onRemove={() => handleRemoveLabel(item.id)}
            />
          </Item>
        );
      })}
    </Wrapper>
  );
};
