import React, { useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { CheckboxGroupField, Form, FormValidationRules, useForm } from '@kit/components/Form';
import { useToast } from '@hooks/useToast';
import { useUpdateCompanyNotification } from '@hooks/workspace/notifications/useUpdateCompanyNofification';
import { PortalNotificationEvent } from '@hooks/workspace/notifications/usePortalNotifications';
import { useAppSelector } from '@hooks/store';
import { selectCompanyById, selectWorkspaceId } from '@state/selectors';
import { Body, Footer, PreviewContainer, PreviewLabel, Previews, Subject } from './styled';
import { config } from '../../../../../../config';

const wrapVariablesWithSpan = (text: string, portalUrl: string) => {
  return text
    .replace('"<<Access URL>>"', `"${portalUrl}"`)
    .replace(/<</g, '<span class="variable">')
    .replace(/>>/g, '</span>');
};

interface FormValues {
  deliveryMethod: string[];
}

const DELIVERY_METHODS = [
  { label: 'Email', value: 'email', settingsKey: 'emailEnabled' },
  { label: 'SMS', value: 'sms', settingsKey: 'smsEnabled' }
];

const getDefaultValues = (event: PortalNotificationEvent) => {
  return {
    deliveryMethod: DELIVERY_METHODS.filter((method) => event.settings[method.settingsKey]).map(
      (method) => method.value
    )
  };
};
interface Props {
  event: PortalNotificationEvent;
  onClose: () => void;
}

export const EventForm = ({ event, onClose }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const portalUrl = `${config.portalUrl}/${company?.subdomainName ?? ''}`;

  const { mutateAsync: update } = useUpdateCompanyNotification();

  const { showError } = useToast();

  const postForm = async (values: FormValues) => {
    if (!values.deliveryMethod.length) {
      showError('At least one delivery method should be selected');

      return;
    }

    await update({
      entityType: event.settings.entityType,
      eventType: event.settings.eventType,
      emailEnabled: values.deliveryMethod.includes('email'),
      smsEnabled: values.deliveryMethod.includes('sms')
    });

    onClose();
  };

  const { form, handleSubmit } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: getDefaultValues(event)
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { control, watch } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(() => {
    return {
      deliveryMethod: {
        isRequired: true
      }
    };
  }, []);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <CheckboxGroupField
          layout="row"
          name="deliveryMethod"
          label="Delivery methods"
          options={DELIVERY_METHODS}
          control={control}
        />

        <Previews>
          <div>
            <PreviewLabel>Email preview</PreviewLabel>
            <PreviewContainer>
              <Subject>
                <div
                  dangerouslySetInnerHTML={{
                    __html: wrapVariablesWithSpan(event.eventSchema.emailTemplate.subject, portalUrl)
                  }}
                />
              </Subject>
              <div
                dangerouslySetInnerHTML={{
                  __html: wrapVariablesWithSpan(event.eventSchema.emailTemplate.body, portalUrl)
                }}
              />
            </PreviewContainer>
          </div>
          <div>
            <PreviewLabel>SMS preview</PreviewLabel>
            <PreviewContainer>
              <div
                dangerouslySetInnerHTML={{ __html: wrapVariablesWithSpan(event.eventSchema.smsTemplate, portalUrl) }}
              />
            </PreviewContainer>
          </div>
        </Previews>
      </Body>
      <Footer>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button type="submit" variant={ButtonVariant.Primary}>
          Update
        </Button>
      </Footer>
    </Form>
  );
};
