import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils';
import { PowerProductionChartsConnection } from '@generated/types/graphql';

export const useSystemsPeakPower = (uuids: string[]) =>
  useQuery({
    queryKey: [ReactQueryKey.System, 'useSystemsPeakPower', uuids],
    queryFn: async () => {
      try {
        const data = await postGraphql<{ powerProductionChartsConnection: PowerProductionChartsConnection }>(
          gql`
            query ($uuids: [UUID!]!) {
              powerProductionChartsConnection(filter: { system: { in: $uuids } }) {
                groupedAggregates(groupBy: [TIME]) {
                  max {
                    value
                  }
                }
              }
            }
          `,
          { uuids }
        );

        return data.powerProductionChartsConnection.groupedAggregates.reduce((acc, agg) => acc + agg.max.value, 0);
      } catch (e) {
        throw apiErrorHandler('Error fetching systems status', e);
      }
    },
    keepPreviousData: true,
    initialData: 0
  });
