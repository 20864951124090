import { System } from '@generated/types/graphql';
import { toNumber } from 'lodash';
import { useCallback, useMemo } from 'react';
import { createStateContext } from 'react-use';

type Selection = Record<string, Record<number, System>>;
type GroupsData = Record<string, System[]>;

const [useGroupsState, GroupsStateProvider] = createStateContext<(string | null)[]>([]);
const [useSelectionState, SelectionStateProvider] = createStateContext<Selection>({});
const [useGroupsDataState, GroupsDataStateProvider] = createStateContext<GroupsData>({});

// FIXME: make it one provider somehow
export {
  GroupsStateProvider,
  SelectionStateProvider,
  GroupsDataStateProvider
};

export const useSelectionWithGroups = () => {
  const [groups, setGroups] = useGroupsState();
  const [selection, setSelection] = useSelectionState();
  const [dataByGroup, setDataByGroup] = useGroupsDataState();

  const toggleItem = useCallback((group: string, value: System) => {
    setSelection((previous) => {
      const copy = { ...previous };

      if (copy[group] && copy[group][value.id]) {
        delete copy[group][value.id];
      } else {
        copy[group] = {
          ...copy[group],
          [value.id]: value
        };
      }

      return copy;
    });
  }, []);

  const isAllInGroupSelected = useCallback(
    (group: string) => {
      const availableIdsLength = dataByGroup[group]?.length || 0;

      return availableIdsLength > 0 && availableIdsLength === Object.keys(selection[group] || {}).length;
    },
    [selection, dataByGroup]
  );

  const isAllSelected = useMemo(() => {
    return groups.every((group) => isAllInGroupSelected(group));
  }, [groups, isAllInGroupSelected]);

  const toggleGroup = useCallback(
    (group: string) => {
      setSelection((previous) => {
        const copy = { ...previous };

        if (isAllInGroupSelected(group)) {
          delete copy[group];
        } else {
          const data = dataByGroup[group] || [];
          copy[group] = data.reduce((acc, item) => {
            acc[item.id] = item;

            return acc;
          }, {} as Record<number, System>);
        }

        return copy;
      });
    },
    [isAllInGroupSelected, dataByGroup]
  );

  const setDataForGroup = useCallback((group: string, data: System[]) => {
    setDataByGroup((previous) => ({
      ...previous,
      [group]: data
    }));
  }, []);

  const isItemSelected = useCallback(
    (group: string, value: System) => {
      return Boolean(selection[group]?.[value.id]);
    },
    [selection]
  );

  const toggleAllSelected = useCallback(() => {
    if (isAllSelected) {
      setSelection({});
    } else {
      setSelection(
        groups.reduce((acc, group) => {
          const data = dataByGroup[group] || [];

          acc[group] = data.reduce((acc, item) => {
            acc[item.id] = item;

            return acc;
          }, {} as Record<number, System>);

          return acc;
        }, {} as Record<string, Record<number, System>>)
      );
    }
  }, [groups, isAllSelected, dataByGroup]);

  const clearSelection = useCallback(() => {
    setSelection({});
  }, []);

  const selectedIds = useMemo(() => {
    return Object.keys(selection).reduce((acc, group) => {
      const ids = Object.keys(selection[group]).map(toNumber);

      return [...acc, ...ids];
    }, [] as number[]);
  }, [selection]);

  return {
    setGroups,
    setDataForGroup,
    selectedIds,
    toggleItem,
    toggleGroup,
    isItemSelected,
    isAllSelected,
    toggleAllSelected,
    isAllInGroupSelected,
    clearSelection
  };
};
